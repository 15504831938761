import React, { useState, useEffect } from "react";
import { auth, googleProvider } from "../auth";
import { Grid } from '@mui/material';
import {
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence,
    sendEmailVerification,

    signInWithPopup,
} from "firebase/auth";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { axiosWrapper, handleAuthError } from "..";
import { SiteGate, buttonStyle, lastButtonStyle } from "../sitegate";
import { useNavigate } from "react-router";
import { useErrorModal } from "../../contexts/error";
import { BASE_URL } from "../../constants/links";

function Registration() {

    const showError = useErrorModal();
    const navigate = useNavigate();
    const [reloading, setReloading] = useState(false)
    const [emailVerificationSent, setEmailVerificationSent] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    });

    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmitEmailVerification = (event = false) => {
        if (event) {
            event.preventDefault();
        }

        const email = localStorage.getItem('emailForSignIn')
        if (!email) {
            setEmailError('Please enter your email address.');
            return;
        }
        setEmailError('');

        const actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: `https://${BASE_URL}/auth/finishSignUp`,
            // This must be true.
            handleCodeInApp: false,
            // iOS: { bundleId: 'com.example.ios' },
            // android: {
            //     packageName: 'com.example.android',
            //     installApp: true,
            //     minimumVersion: '12'
            // },
            // dynamicLinkDomain: 'example.page.link' // TODO:
        };

        sendEmailVerification(auth.currentUser)
            .then((link) => { })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('[-] Error sending email verification link: ' + errorCode + " " + errorMessage)
                setEmailError(errorMessage)
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        setError('');
        console.log(formData);

        axiosWrapper('/ptntst/registerUser', formData)
            .then(apiResponse => {
                if (apiResponse.data.type === 'error') {
                    setError(apiResponse.data.message)
                } else {
                    // After registration, sign in the user
                    setPersistence(auth, browserLocalPersistence);
                    signInWithEmailAndPassword(auth, formData.email, formData.password)
                        .then((userCredential) => {
                            // Signed in 
                            const user = userCredential.user;

                            // Once signed in, send email verification.
                            localStorage.setItem('emailForSignIn', formData.email);
                            setEmailVerificationSent(true);

                            handleSubmitEmailVerification();
                        })
                        .catch((error) => {
                            console.log(error)
                            const errorCode = error.code;
                            const errorMessage = error.message;
                        });
                }
            })
    };

    const registerWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider)
                .then((result) => {
                    console.log("[+] Successfully signed in with Google.")
                    axiosWrapper('/ptntst/registerGoogleUser',
                        {
                            email: result.user.email,
                            displayName: result.user.displayName,
                            firebaseToken: result.user.accessToken,
                        })
                        .then(apiResponse => {
                            if (apiResponse.data.type === 'error') {
                                setError(apiResponse.data.message)
                            } else {
                                // User registered & signed in. Take to main.
                                navigate("/main")
                            }
                        })
                })
                .catch((error) => {
                    showError(handleAuthError(error))
                    // ...
                })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // if link has not yet been sent, or email is already verified, or we are already reloading, do nothing.
        if (!emailVerificationSent || emailVerified || reloading) { return }

        // otherwise, start reloading.
        setReloading(true)

        const seconds = 5;
        const interval = seconds * 1000;

        const handle = setInterval(
            async () => {

                const user = auth.currentUser;
                if (!user) { return }

                if (user?.emailVerified) {
                    setEmailVerified(true);
                    clearInterval(handle)
                    console.log("[+] Email verified.")
                    navigate("/auth/firmStatus")
                }
                else await user.reload();

            }, interval);
        return () => { clearInterval(handle) };

    }, [emailVerificationSent]);

    return (
        <SiteGate title={
            !emailVerificationSent
                ? "Sign up"
                : emailVerified
                    ? "Email verified!"
                    : "Check your email."}
            error={
                emailVerificationSent
                    ? emailError
                    : error}

            buttons={
                <div style={{ width: '100%', padding: '0 2em' }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={emailVerified}
                        onClick={emailVerificationSent
                            ? handleSubmitEmailVerification
                            : handleSubmit}
                        style={lastButtonStyle}>
                        {emailVerificationSent ? "Re-Send Verification Link" : "Sign up"}
                    </Button>
                    {/* {!emailVerificationSent &&
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={buttonStyle}
                            onClick={registerWithGoogle}>
                            Register with Google
                        </Button>
                    } */}

                    {/* <Button
                        onClick={() => {
                        }}
                        variant="contained"
                        color="primary"
                        style={buttonStyle}>
                        TESTING
                    </Button>


                    <Button
                        onClick={() => {
                            setEmailVerificationSent(!emailVerificationSent)
                        }}
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={lastButtonStyle}>
                        Flip
                    </Button> */}
                </div>
            }>
            {!emailVerificationSent
                ? <Grid container spacing={2}
                    style={{ width: '100%' }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="fname"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="lname"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                : emailVerified
                    ? null
                    : null

            }
        </SiteGate>
    );
}

export default Registration