import React, { useContext, useState } from 'react';
import APIContext from '../api/context.js';
import LandingContext from './context.js';
import { auth } from '../../auth/auth.js';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const LandingContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const { api } = useContext(APIContext)
    const [openPage, setOpenPage] = useState("home")
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
    const [sidebarUsable, setSidebarUsable] = useState(false);
    const [refreshSidebar, setRefreshSidebar] = useState(0)

    const toggleDrawer = (open) => (event) => {
        if (event && (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
            return;
        }
        setDrawerOpen(open);
    };

    const toggleRightDrawer = (open) => (event) => {
        if (event && (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
            return;
        }
        setRightDrawerOpen(open);
    };

    const setProfileOpen = (open) => {
        setOpenPage("profile")
    }
    const logOut = async () => {
        try {
            signOut(auth).then(() => {
                navigate('/');
            }).catch((error) => {
            });
        } catch (err) {
        }
    };
    return (
        <LandingContext.Provider value={{
            manager: {
                toggleDrawer, toggleRightDrawer, setProfileOpen, setOpenPage,
                setDrawerOpen, setRightDrawerOpen, rightDrawerOpen, drawerOpen,
                sidebarUsable, refreshSidebar, setSidebarUsable, setRefreshSidebar,
                logOut
            }, openPage
        }}>
            {children}
        </LandingContext.Provider>
    );
}

export default LandingContextProvider