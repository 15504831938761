import React,{useState} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LexExpandableAccordion from '../../Lex/LexExpandableAccordion';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { MarkdownTable } from '../../Lex/LexMarkdownTable';
const FlexTempAccordion = ({ details, summary}) => {
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);
    return (
        <div className="stepConfigContainer"
                style={{
                    '--total-padding': '20px',
                    '--header-padding': '5px',
                }}>
                <Accordion square disableGutters expanded={descriptionExpanded}
                    elevation={0} 
                    onChange={(e) => setDescriptionExpanded((pre) => !pre)}>
                    
                    <AccordionSummary
                        expandIcon={descriptionExpanded ? <DoDisturbOnOutlinedIcon fontSize="small" /> : <HelpOutlineOutlinedIcon fontSize="small" />}
                        sx={{
                            minHeight: 'unset', paddingBottom: 'var(--header-padding)',
                            ".MuiAccordionSummary-expandIconWrapper.Mui-expanded, .MuiAccordionSummary-expandIconWrapper":
                                { transform: 'none', '-webkit-transform': 'none' },
                            ".MuiAccordionSummary-expandIconWrapper":
                                { transition: 'none', '-webkit-transition': 'none' }
                        }}>
                        <Typography variant="h6">{summary}</Typography>
                    </AccordionSummary>
          <AccordionDetails>
          <MarkdownTable markdown={details ? details : ""} />
            {/* <Typography>{details}</Typography> */}
          </AccordionDetails>
        </Accordion>
        </div>
    )
}
const GenerativeSeqTestDisplay = ({ testState, stepData }) => {
  const messages = testState?.[stepData?.varName] || [];
  return (
    <div>
      {messages.map((message, index) => (
         <FlexTempAccordion details={message} summary={'Step '+(index+1)}/>
      ))}
    </div>
  );
};

export default GenerativeSeqTestDisplay;