import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import LexTypography from "../LexTypography";

const LexExpandableAccordion = ({ summary, details, Summary,...props }) => (
    <Accordion
        disableGutters={true}
        defaultExpanded={true}
        style={{
            height: '100%',
            borderTop: "1px solid rgb(219 220 223)",
            borderBottom: "1px solid rgb(219 220 223)",
            backgroundColor: "white",
            width: '100%',
            boxShadow: 'none',
        }}{...props}>
        <Summary />
        <AccordionDetails style={{ padding: "0px" }}>
            {details}
        </AccordionDetails>
    </Accordion>
);

export default LexExpandableAccordion;