import React, { useState } from 'react';
import Button from "@mui/material/Button";
import Atomics from "./atomicInputs";
import { Typography } from "@mui/material";
import DescriptionBlock from "./description";


const GPTPromptEditor = ({ userInputs, selectedNode, mutateStandardNodeData, gptPrompts, ...other }) => {
    //Functions for modifying the prompts
    const gptInputData = [
        {
            label: 'Context ID:',
            type: "textInput",
            varLabel: 'contextVar',
            details: "A context ID is required. At a later point in the process, you can continue this LLM conversation by referencing it.",
            hidePastZero: true,
            display: (store, datum) => store[datum.varLabel],
            defaultData: ''
        },
        {
            label: 'Use Existing Context ID:',
            type: "textInput",
            varLabel: 'useContextVar',
            details: "You can resume an LLM conversation started earlier in the process by putting the context ID here. If you wish to start a fresh conversation, leave it blank.",
            hidePastZero: true,
            display: (store, datum) => store[datum.varLabel],
            defaultData: ''
        },
        {
            label: 'Input Variables:',
            type: "textArea",
            varLabel: 'inputVars',
            details: "This should be a comma separated list of variables that you wish to inject into your prompt. This may be user input variables or LLM output variables.",
            display: (store, datum) => store[datum.varLabel],
            defaultData: ''
        },
        {
            label: 'Truncation:',
            type: "checkbox",
            varLabel: 'Truncate',
            details: "Truncate all but the last message in the context.",
            display: (store, datum) => store[datum.varLabel],
            defaultData: ''
        },
        {
            label: 'Output Variable:',
            type: "textInput",
            varLabel: 'outputVar',
            details: "The LLM output will be stored in this variable.",
            hidePastZero: true,
            display: (store, datum) => store[datum.varLabel],
            defaultData: ''
        },
        {
            label: 'GPT Prompt:',
            type: "textArea",
            varLabel: 'promptData',
            details: "You may write your LLM prompt here. You may inject any variable in the list of input variables above by writing $varName.",
            display: (store, datum) => store[datum.varLabel],
            defaultData: ''
        },
        {
            label: 'Parallel:',
            type: "textInput",
            varLabel: 'parallel',
            hidePastZero: true,
            display: (store, datum) => store[datum.varLabel],
            defaultData: '1'
        },
        {
            label: 'Shift:',
            type: "textInput",
            varLabel: 'shift',
            hidePastZero: true,
            display: (store, datum) => store[datum.varLabel],
            defaultData: '0'
        }

    ]


    const [prompts, setPrompts] = useState(gptPrompts);
    const [ver, setVer] = useState(0)
    const [selectedSequenceIndex, setSelectedSequenceIndex] = useState(null);

    const handleSequenceSelect = (index) => {
        setSelectedSequenceIndex(index);
    };

    const handleAddSequence = () => {
        setPrompts([...prompts, { sequenceLabel: "", promptSeq: [] }]);
    };

    const handleDeleteSequence = (index) => {
        const newPrompts = prompts.filter((_, i) => i !== index);
        setPrompts(newPrompts);
        if (index === selectedSequenceIndex) {
            setSelectedSequenceIndex(null);
        }
        mutateStandardNodeData(selectedNode, "gptPrompts", newPrompts)
    };

    const handleAddPrompt = () => {
        if (selectedSequenceIndex !== null) {
            const newPrompts = [...prompts];
            let newObject = {}
            gptInputData.forEach((iData) => {
                newObject[iData.varLabel] = iData.defaultData
            })
            newPrompts[selectedSequenceIndex].promptSeq.push(newObject);
            setPrompts(newPrompts);
            mutateStandardNodeData(selectedNode, "gptPrompts", newPrompts)
        }
    };

    const handleDeletePrompt = (promptIndex) => {
        if (selectedSequenceIndex !== null) {
            const newSequence = prompts[selectedSequenceIndex]
            console.log(promptIndex)
            console.log(newSequence.promptSeq)
            newSequence.promptSeq = prompts[selectedSequenceIndex].promptSeq.filter((_, i) => i !== promptIndex);
            console.log(newSequence.promptSeq)
            const newPrompts = [...prompts];
            newPrompts[selectedSequenceIndex] = newSequence;
            setPrompts(newPrompts);
            setVer((ver) => (ver + 1))
            mutateStandardNodeData(selectedNode, "gptPrompts", newPrompts)
        }
    };

    const handlePromptChange = (promptIndex, key, value) => {
        if (selectedSequenceIndex !== null) {
            const newPrompts = [...prompts];
            newPrompts[selectedSequenceIndex].promptSeq[promptIndex][key] = value;
            setPrompts(newPrompts);
            mutateStandardNodeData(selectedNode, "gptPrompts", newPrompts)
        }
    };
    return (
        <div key={"gpteditor" + ver}>
            <DescriptionBlock title={"All Sequences"}>
                A sequence is a series of ChatGPT Prompts run in succession, producing a single output variable
                and repeatedly using a single context.
            </DescriptionBlock>
            <div style={{
                backgroundColor: "#1a1f22",
                marginTop: "20px",
                padding: "30px",
                borderRadius: "15px",
            }}>
                {prompts.map((sequence, index) => (
                    <div style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "15px",
                        backgroundColor: "#282b30",
                    }} key={index}>
                        <Typography
                            style={{ width: "60%", marginLeft: "30px", paddingTop: "6px" }}>
                            Seq. {index + 1}
                        </Typography>
                        <Button
                            style={{ marginRight: "5px" }}
                            variant="outlined"
                            color="primary" onClick={() => handleSequenceSelect(index)}>Edit</Button>
                        <Button variant="outlined"
                            // class={'fontDesignInterface'}
                            color="primary" onClick={() => handleDeleteSequence(index)}>Delete</Button>
                    </div>
                ))}
                <div style={{ marginTop: "10px" }}>
                    <Button variant="outlined"

                        color="primary" style={{ marginTop: "5px" }} onClick={handleAddSequence}>Add New
                        Sequence</Button>
                </div>
            </div>
            {selectedSequenceIndex !== null && (
                <div>
                    <div style={{ marginTop: "20px", paddingTop: "0px" }}>
                        <div style={{ paddingBottom: "20px" }}>
                            <DescriptionBlock title={"Editing Sequence " + (selectedSequenceIndex + 1)}>
                                A sequence is a series of ChatGPT Prompts run in succession, producing a single output
                                variable and repeatedly using a single context.
                                <br />
                                <br />
                                Every GPT Context is a seperate variable, you may
                                not use these contexts as prompt variables, but you can instruct the sequence to resume
                                an existing context by using an existing context ID.
                                <br />
                                <br />
                                Either way, the sequence will require it's own context ID to store the resulting chat
                                context.
                                <Button variant="outlined"
                                    style={{ marginTop: "10px" }}
                                    color="primary" onClick={handleAddPrompt}>Add Prompt To Sequence</Button>
                            </DescriptionBlock>
                        </div>
                        <div>
                            {prompts[selectedSequenceIndex].promptSeq.map((prompt, promptIndex) => (
                                <div key={promptIndex} style={{ marginTop: "15px" }}>
                                    <div style={{ backgroundColor: "#1a1f22", padding: "15px", borderRadius: "15px" }}>
                                        {gptInputData.map((datum, index) => {
                                            if (promptIndex === 0) {
                                                return <Atomics
                                                    handleChange={handlePromptChange}
                                                    datum={datum}
                                                    type={datum.type}
                                                    store={prompt}
                                                    idx={promptIndex}
                                                />
                                            } else {
                                                if (!!!datum.hidePastZero) {
                                                    return <Atomics
                                                        handleChange={handlePromptChange}
                                                        datum={datum}
                                                        type={datum.type}
                                                        store={prompt}
                                                        idx={promptIndex}
                                                    />
                                                }
                                            }
                                        })}
                                        <Button variant="outlined"
                                            style={{ marginTop: "10px" }}
                                            color="primary"
                                            onClick={() => handleDeletePrompt(promptIndex)}>Delete
                                            Prompt</Button>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default GPTPromptEditor;
