
import React,{useEffect, useState} from 'react';
import { MarkdownTable } from '../../../components/Lex/LexMarkdownTable';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import { Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import APIStepCollector from '../../../components/Automata/APIStepCollector';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import LexTypography from '../../../components/Lex/LexTypography';

const APICheckboxDisplay = ({ configureStep, stepData, errorDisplay, testState, stepId }) => {
    const possibleOptions = testState?.['__'+stepId +'__searchResults'] ? testState?.['__'+stepId +'__searchResults'] : [];
    const [selectedOptions, setSelectedOptions] = useState(testState?.['__'+stepId +'__incomingChecked'] ? testState?.['__'+stepId +'__incomingChecked'] : []);
    const metadataKeys = testState?.['__'+stepId+'__metadataKey'] ? testState?.['__'+stepId+'__metadataKey'] : [];
    const [mode,setMode] = useState('search')
    useEffect(() => {
        const filteredOptions = selectedOptions.filter(option => 
            possibleOptions.some(possibleOption => possibleOption.name === option)
        );
        
        // Check if the sets of strings are different
        const setsAreDifferent = new Set(filteredOptions).size !== new Set(selectedOptions).size ||
            filteredOptions.some(option => !selectedOptions.includes(option));
    
        if (setsAreDifferent) {
            setSelectedOptions(filteredOptions);
        }
    }, [testState?.['__'+stepId +'__searchResults']]);
    
    useEffect(() => {
        const targetKey = '__'+stepId +'__incomingChecked'
        configureStep.setTest({
           [targetKey]:selectedOptions
        })
    },[selectedOptions])

    const handleCheckboxChange = (event, optionName) => {
        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, optionName]);
        } else {
            setSelectedOptions(selectedOptions.filter((item) => item !== optionName));
        }
    };

    return (
        <Box display="flex" style={{width:"100%"}}>
        {/* Sidebar Drawer */}
        <div style={{width:"150px",marginRight:"10px"}}>
        <List>
                
                    <ListItem 
                        button 
                        key={"searchKey"} 
                        selected={mode === 'search'}
                        onClick={() => setMode('search')}
                    >
                        <ListItemText primary={'Search Results'} />
                    </ListItem>
                    <ListItem 
                        button 
                        key={"viewKey"} 
                        selected={mode === 'view'}
                        onClick={() => setMode('view')}
                    >
                        <ListItemText primary={'View Output'} />
                    </ListItem>
            </List>
            </div>
        {mode==='search'? <div style={{ overflowX: 'auto' }}><LexTypography variant='h6' style={{paddingBottom:"10px"}}>Search Results</LexTypography>
            {possibleOptions.length === 0 ? <LexTypography variant='body1'>No search results found/Step has not been executed.</LexTypography> : null}
            {possibleOptions.length > 0 && metadataKeys.length > 0 ? <LexTypography variant='body1'>After any changes to your selections, you must re-run this step.</LexTypography>:null}
            {possibleOptions.length > 0 && metadataKeys.length > 0 ? (
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell> {/* Checkbox column header */}
                                {metadataKeys.map((key) => (
                                    <TableCell key={key}>{key}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {possibleOptions.map((option) => (
                                <TableRow key={option['name']}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedOptions.includes(option['name'])}
                                            onChange={(event) => handleCheckboxChange(event, option['name'])}
                                            name={option['name']}
                                        />
                                    </TableCell>
                                    {metadataKeys.map((key) => (
                                        <TableCell key={key}>{option[key]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                   
                </TableContainer>
            ) : null}</div>:null}
            {mode==='view'? <div style={{ maxWidth:'550px',
                overflowX:'hidden', overflowY: 'scroll', maxHeight:"500px", 
                whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>
                    <MarkdownTable markdown={testState?.[stepData?.varName] ? testState?.[stepData?.varName] : ""} />
                    </div> : null}
        </Box>
    );
};

export const apiStep = {
    overview: {
        name: 'apiStep',
        label: 'Pull data from an external API.',
        sublabel: '(Add me)',
        icon: (<div style={{ position: 'relative', width: '100%', height: '100%', marginTop: '10px', marginLeft: '4px' }}>
            <AutoAwesomeSharpIcon style={{
                position: 'absolute', height: '16px', width: '16px',
                top: '4px', left: '16px',
                clipPath: 'polygon(0 0, 50% 0, 100% 100%, 0 100%)',
                transform: 'rotate(180deg)',
            }} />
            <TextFieldsOutlinedIcon style={{
                position: 'absolute',
                top: '5px',
                left: '0px',
            }} />
        </div>),
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'apiStep',
                labelDisplay: "API",
                display: 'apiSelect',
                initialized: true
            })
        }
    },
    outputDisplay: {
        enabled: true,
        collectorComponent: props=><APICheckboxDisplay {...props} />

    },
    apiSelect: {
        header: 'API Selection',
        description: 'Please select the API you wish to use and enter your query.',
        collectorComponent: (props) => <APIStepCollector {...props} />,
    },
    default: {

    }


};
