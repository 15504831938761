import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";
import { Logo } from "../../../components/logoComponent";
import { useAuthState } from "react-firebase-hooks/auth";
import { authFb } from "../../../auth/auth";
import app_name from "../../../constants/name";
import ContactContext from "../../../contexts/contact/context";
import { useContext } from "react";

export default function Sidebar({ sidebarOpen, toggleSidebar, teamPage, setOnTeamPage }) {
  const [user, loading, error] = useAuthState(authFb);
  const {showModal,setShowModal} = useContext(ContactContext);
  return (
    <Wrapper className="animate navBackgroundAlt" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div>
          <Logo color="#e6e6e6" format="header" />
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} style={{ color: "black" }} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            // className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="home"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Home
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            // className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="Automatons"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Services
          </Link>
        </li>
        <li className="semiBold font15 pointer">
          <Link activeClass="active"
            style={{ padding: "10px 15px" }} to="team" onClick={() => {
              setOnTeamPage(x => !x);
              toggleSidebar(!sidebarOpen);
            }}
            spy={true} smooth={true} offset={-80}>
            Team
          </Link>
        </li>
        {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="blog"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Blog
          </Link>
        </li> */}

        {/* <li className="semiBold font15 pointer">
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass="active"
            className="whiteColor"
            style={{ padding: "10px 15px" }}
            to="contact"
            spy={true}
            smooth={true}
            offset={-60}
          >
            Contact
          </Link>
        </li> */}
      </UlStyle>
      {/* {!!!user ? <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <a href="/auth/login" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a href="/auth/signup" className="radius8 lightBg altText" style={{ padding: "10px 15px" }}>
                Get Started
              </a>
            </li>
          </UlWrapperRight> :
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font15 pointer flexCenter">
                <a href="/main" className="radius8 lightBg altText" style={{ padding: "10px 15px" }}>
                  View Drafts
                </a>
              </li>
            </UlWrapperRight> */}

      {!!!user ? (<UlStyle className="flexSpaceCenter">
        <li className="semiBold font15 pointer">
          <a href="/auth/login" style={{ padding: "10px 30px 10px 0" }}>
            Log in
          </a>
        </li>
        <li className="semiBold font15 pointer flexCenter">
          <a onClick={()=>{
            setShowModal(true);
            toggleSidebar(!sidebarOpen);
            }} className="radius8 lightBg" style={{ color: "purple", padding: "10px 15px" }}>
            Contact Us
          </a>
        </li>      </UlStyle>) : (
        <li className="semiBold font15 pointer flexCenter">
          <a href="/main" className="radius8 lightBg" style={{ color: "purple", padding: "10px 15px" }}>
            View Drafts
          </a>
        </li>)}

    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
