import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import { ALL_COPY } from "../../../constants/copy";
import app_name from "../../../constants/name";

const paymentMethod1Features = ['Your patent draft, which is covered by our 100% money back guarantee.', 'A comprehensive review and feedback notes from our team of veteran patent lawyers.', 'You draft your patent and sync up with one of our hand-picked legal partners for next steps.'];
const paymentMethod2Features = ['Your patent draft.', 'You will be charged $349 30 days from the date the draft is generated'];
const paymentMethod3Features = ['Select a legal partner from LexConnect, and an AI-assisted draft will be sent to them', 'Your legal partner will reach out to you and set an appointment'];



export default function Pricing() {
  // return null
  return (
    <Wrapper id="pricing" style={{
      marginBottom: '40px'
    }}>
      <div className="whiteBg">
        <div className="container">
          {/* <HeaderInfo>
            <br/>
            <br/>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
            <br/>
            <p className="font13">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
              <br />
              labore et dolore magna aliquyam erat, sed diam voluptua.
            </p>  
          </HeaderInfo> */}
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="$799.99"
                title="Patent+Review"
                text=""
                offers={ALL_COPY.patentistPrice1}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="$999.99/mo"
                flippedColors={true}
                title={app_name + " for Firms"}
                text=""
                offers={ALL_COPY.patentistPrice2}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="$0.00"
                title="Connect (Coming Soon)"

                greyed={true}
                text=""
                offers={ALL_COPY.patentistPrice3}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  padding: 30px 0px;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




