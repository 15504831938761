
import AddIcon from '@mui/icons-material/Add';
import SegmentIcon from '@mui/icons-material/Segment';
import FormDataCollector from '../../../components/Automata/FormDataCollector';
import FormDataTestCollector from '../../../components/Automata/FormDataTestCollector';

export const formDataStep = {
    overview: {
        name: 'formDataStep',
        label: 'Collect form data',
        sublabel: 'This will allow you collect data from the user for use in a later generative step.',
        icon: <SegmentIcon />,
        click: ({ configureStep, stepData, manager, errorDisplay }) => {
            configureStep.set({
                name: 'formDataStep',
                labelDisplay: "Form Data",
                collectedFields: [],
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    testDataDisplay: {
        enabled: true,
        header: 'Test Data Configuration',
        description: 'Please input some sample inputs to test the efficacy of your flow.',
        collectorComponent: (props) => <FormDataTestCollector {...props} />

    },
    primaryOptions: {
        header: 'Data Collection Step',
        description: 'Form components collect data directly from user input. A variety of formats are supported, including text fields, radio buttons, and checkboxes.'
            + '\n\nEach form component can collect up to ten input fields.',
        /*
        'You can collect data from the user for use in a later generative step.  Data can be collected from the user in a variety of ways, including text fields, radio buttons, and checkboxes. You can collect up to ten input fields per step.' +
            `\n\nAll user submitted data collected in this step will be available for your prompts in sequential steps. It can be added to a prompt by using the variable name you assign to each field and prefixing it with "$".` +
            `\n\nFor example, if you collect a user's name and assign it the variable name "name", you can use the variable "$name" in your prompt to reference the user's name.`,
            */
        collectorComponent: (props) => <FormDataCollector {...props} />,
        buttonOpts: [{
            label: 'Add a new form field',
            sublabel: '',
            icon: <AddIcon />,
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    collectedFields: [...stepData.collectedFields, {
                        type: 'text',
                        label: '',
                        varName: '',
                        selectOptions: "option1,option2",
                        required: false,
                        sample: ""
                    }],
                })
            }
        }],
        inputOpts: [],
    },
    default: {

    }


};