import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, MenuItem, Button, Select, Typography, Checkbox } from "@mui/material";


const AtomicBase = (props) => {
    return (<div style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        // paddingTop: "15px",
        border: "1px solid #302d44",
    }}>
        {/*<div>{datum.label}</div>*/}
        <div style={{
            width: "130%", marginTop: "15px", display: "flex"
        }}>
            <div style={{ width: "130%", marginTop: "auto" }}>
                <div></div>
                {props.children}
            </div>
        </div>
        <Typography
            variant="body2"
            style={{
                width: "80%",
                borderLeft: "1px solid #302d44",
                color: "#7b7794",
                paddingTop: "30px",
                paddingLeft: "10px",
                paddingBottom: "9px",
                whiteSpace: 'pre-line'
            }}
            sx={{ whiteSpace: 'pre-line' }}>
            {props.description}
        </Typography>
    </div>)
}

const AtomicCheckbox = ({ handleChange, display, store, datum, idx }) => {
    return (
        <AtomicBase description={datum.details}>
            <Checkbox
                color="primary"
                onChange={(e) => handleChange(idx, datum.varLabel, e.target.checked)}
                defaultChecked={!!datum.display(store, datum)}
            />
            {datum.label}
        </AtomicBase>
    );
};

const AtomicTextArea = ({ handleChange, display, store, datum, idx }) => {
    return (
        <AtomicBase description={datum.details}>
            <TextField
                label={datum.label}
                // required={props?.required}
                style={{ marginTop: "20px" }}
                onChange={(e) => handleChange(idx, datum.varLabel, e.target.value)}
                defaultValue={datum.display(store, datum)}
                multiline
                rows={6}
                fullWidth
                variant="filled"
                color="primary"
                type="text" />
        </AtomicBase>)
}

const AtomicTextInput = ({ handleChange, display, store, datum, idx }) => {
    return (<AtomicBase description={datum.details}>
        <TextField
            label={datum.label}
            // required={props?.required}
            onChange={(e) => handleChange(idx, datum.varLabel, e.target.value)}
            defaultValue={datum.display(store, datum)}
            variant="filled"
            fullWidth
            color="primary"
            type="text" />
    </AtomicBase>)
}

const AtomicSelect = ({ handleChange, store, datum, idx }) => {
    return (<AtomicBase description={datum.details}>
        <div style={{ margin: "20px" }}>
            <FormControl style={{ marginTop: "20px" }} fullWidth>
                <InputLabel id="demo-simple-select-label">{datum.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={datum.display(store, datum) ? datum.display(store, datum) : datum.array[0].value}
                    label={datum.label}
                    onChange={(e) => {
                        handleChange(idx, null, e.target.value)
                    }}
                >
                    {datum.array.map((element, id) => <MenuItem key={datum.label + "atomicSelect" + id} value={element.value}>{element.label}</MenuItem>)}
                </Select>
            </FormControl></div>
    </AtomicBase>)
}

const AtomicKeyValue = ({ handleChange, store, datum, idx }) => {
    const [keyValuePairs, setKeyValuePairs] = useState(datum.display(store, datum) ? datum.display(store, datum) : []);
    const [pair, setPair] = useState({ key: '', value: '' });
    const [selectedPair, setSelectedPair] = useState('');

    const handleAdd = () => {
        if (keyValuePairs.length < 5) {
            setKeyValuePairs([...keyValuePairs, pair]);
            handleChange(idx, datum.varLabel, [...keyValuePairs, pair])
            setPair({ key: '', value: '' });
        }
    };

    const handleRemove = () => {
        setKeyValuePairs(keyValuePairs.filter((p) => p.key !== selectedPair));
        handleChange(idx, keyValuePairs.filter((p) => p.key !== selectedPair))
    };

    return (
        <AtomicBase description={datum.details}>
            <TextField
                label={datum.keyLabel ? datum.keyLabel : "Key"}
                value={pair.key}
                onChange={(e) => setPair({ ...pair, key: e.target.value })}
            />
            <TextField
                label="Value"
                value={datum.valueLabel ? datum.valueLabel : "Value"}
                onChange={(e) => setPair({ ...pair, value: e.target.value })}
            />
            <Button onClick={handleAdd}>Add</Button>
            {keyValuePairs.length > 0 ? <FormControl>
                <InputLabel id="select-label">Pairs</InputLabel>
                <Select
                    labelId="select-label"
                    value={selectedPair}
                    onChange={(e) => setSelectedPair(e.target.value)}
                >
                    {keyValuePairs.map((p) => (
                        <MenuItem value={p.key}>{`${p.key}: ${p.value}`}</MenuItem>
                    ))}
                </Select>
            </FormControl> : null}
            {keyValuePairs.length > 0 ? <Button onClick={handleRemove}>Remove</Button> : null}
        </AtomicBase>
    );
};


const Atomics = (props) => {
    if (props.type === "textArea") {
        return <AtomicTextArea {...props} />
    }
    if (props.type === "textInput") {
        return <AtomicTextInput {...props} />
    }
    if (props.type === "select") {
        return <AtomicSelect {...props} />
    }
    if (props.type === "checkbox") {
        return <AtomicCheckbox {...props} />
    }
    if (props.type === "keyValue") {
        return <AtomicKeyValue {...props} />
    }
}

export default Atomics
