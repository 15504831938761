import SegmentIcon from '@mui/icons-material/Segment';
import DynamicFileCollector from '../../../components/Automata/DynamicFileCollector';
import UploadStepDataCollector from '../../../components/Automata/UploadStepDataCollector';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

export const uploadStep = {
    overview: {
        name: 'uploadStep',
        label: 'Allow the user to upload documents?',
        sublabel: 'Documents may be used to provide further context and refine generation.',
        icon: <UploadFileOutlinedIcon />,
        click: ({ configureStep, stepData, manager, errorDisplay }) => {
            configureStep.set({
                name: 'uploadStep',
                labelDisplay: "Upload Data",
                collectedFields: [],
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    testDataDisplay: {
        enabled: true,
        header: 'Test Data Configuration',
        description: 'Please input some sample inputs to test the efficacy of your flow.',
        collectorComponent: (props) => <DynamicFileCollector {...props} />

    },
    primaryOptions: {
        header: 'Upload Step',
        description: 'You may allow a user to upload a single file here.',
        collectorComponent: (props) => <UploadStepDataCollector {...props} />,
        buttonOpts: [],
        inputOpts: [],
    },
    default: {

    }


};