import { useCallback, useRef } from 'react';
import ReactFlow, {
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
    Background,
    Controls,
    getOutgoers,
    getIncomers
} from 'reactflow';


export function useFlowHandlers(setNodes, setEdges, setSelectedNode, nodes, edges) {
    const nodesRef = useRef({})
    nodesRef.current = nodes
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    const isValidConnection = useCallback(
        (connection) => {
            if (connection.source === connection.target) {
                return false
            }

            if (connection.source === 'start' && connection.target === 'end') {
                return false
            }
            const target = nodes.find((node) => node.id === connection.target);
            const hasCycle = (node, visited = new Set()) => {
                if (visited.has(node.id)) return false;

                visited.add(node.id);

                for (const outgoer of getOutgoers(node, nodes, edges)) {
                    if (outgoer.id === connection.source) return true;
                    if (hasCycle(outgoer, visited)) return true;
                }
            };

            return !hasCycle(target);
        },
        [nodes, edges],
    );

    const deleteAllConnections = (idx) => {
        setEdges((edges) => {
            let newEdges = []
            const node = nodesRef.current[idx]
            newEdges = edges.filter((edge) => edge.source !== node.id && edge.target !== node.id)
            return newEdges
        })
    }

    const changeSelectedNode = (selection) => {
        setSelectedNode(selection)
    }

    return { onNodesChange, onEdgesChange, onConnect, isValidConnection, deleteAllConnections, changeSelectedNode };
}