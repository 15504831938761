import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';

const LexFormSelect = ({
    onChange,
    selectValue,
    inputLabel,
    menuText,
    selectionOptions,
    formatMenuItem,
    defaultStyle = {
        outerForm: { variant: "filled", style: { width: "150px" } },
        innerSelect: { width: "300px" }
    },

}) => {
    return (<FormControl {...defaultStyle.outerForm}>
        <InputLabel id="demo-simple-select-filled-label">{inputLabel}</InputLabel>
        <Select
            value={selectValue}
            onChange={onChange}
            displayEmpty
            style={defaultStyle.innerSelect}
            labelId="demo-simple-select-filled-label"
            id="demo-simple-select-filled"
        >
            <MenuItem value="" disabled>
                {menuText}
            </MenuItem>
            {selectionOptions.map(([selStr, selIdx]) => {
                return <MenuItem key={selStr} value={selStr}>
                    {formatMenuItem([selStr, selIdx])}
                </MenuItem>
})}
        </Select>
    </FormControl>)
};

export default LexFormSelect;