import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { SiteGate} from "../../../auth/sitegate";
import { ALL_COPY } from "../../../constants/copy";
import app_name from "../../../constants/name";
import APIContext from "../../../contexts/api/context";

const pk = ['production'].includes(process.env.NODE_ENV)
  ? "pk_live_51GXKg8Bwq0cR1CQY7eSuGU7BCPwjUshGgJfC05stdpAGMBAI6RQjTPnAO5Hv8wRivP6aAIKz54PWFWbWqjg0VJzK00U86YhDdC" : "pk_test_51PUrXuDfMfNR2jqIgx25nPbJQqxraU3TofbEEmM6XfrIt8Uts9boZHk2dNaXQiU5T8IccJVt5aVwIbjwn4YF0Idn00FaFH9H7V"
const stripePromise = loadStripe(pk);

export const CheckoutReturn = () => {
  const {api} = useContext(APIContext)
  const [error, setError] = useState(null)
  const [clickedOnce, setClickedOnce] = useState(false)
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [paid, setPaid] = useState(false);
  const [sub, setSub] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let count = 0;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const patentId = urlParams.get('patent_id');
    const intervalId = setInterval(async () => {
      try {
        api('/ptntst/patentPaid',
          { patentId: patentId, sub: urlParams.get('sub') })
          .then(res => {
            if (res.data.paid) {
              setPaid(true)
              let subQuery = urlParams.get('sub')
              if (subQuery === "true") {
                setSub(true)

              }
              clearInterval(intervalId);
            }
            count += 1;
            if (count >= 20) {
              clearInterval(intervalId);
            }
          })

      } catch (error) {
        console.error(error);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    api('/ptntst/session-status',
      { sessionId: sessionId })
      .then(res => {
        setStatus(res.data.status);
        setCustomerEmail(res.data.customer_email);
      })
  }, []);
  return (
    <div style={{ height: "98vh" }}>
      <SiteGate
        title="Payment Processing"
        error={error}
        buttons={
          <>
            <Typography variant="body2" style={{ margin: "15px" }}> We appreciate your business! A confirmation email will be sent to {customerEmail}. You may click the button below to return to {app_name}. </Typography>

            <Typography variant="body2" style={{ margin: "15px" }}> If you have any questions, please contact <a href="mailto:orders@example.com" style={{ color: "black" }}>orders@example.com</a>.</Typography>

            {sub && paid ? <Typography variant="body2" style={{ margin: "15px" }}> You may now access the firm control panel from the main menu.</Typography> : null}
            <div style={{ marginTop: "30px", marginBottom: "50px" }}>
              {!paid ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </div> : <div>

                {!!!sub ? <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const patentId = urlParams.get('patent_id');
                    navigate(`/main?patent_id=${patentId}`);
                  }}>Return to patent generation</Button> : <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      navigate(`/main`);
                    }}>Return</Button>}
              </div>}
            </div>
          </>


        }>
      </SiteGate>
    </div>
  )
}
