import React, { useContext } from "react";
import { useTheme, Button } from '@mui/material';
import { IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LandingContext from "../../../../contexts/landing/context.js";


const MobileMenu = (props) => {
    const theme = useTheme();
    const { manager, openPage } = useContext(LandingContext);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <div style={{
            flex: '0 1 fit-content',
        }}>
            {isMobile && (
                <IconButton onClick={manager.toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
            )}
            {isMobile && (openPage === "client") && manager.sidebarUsable && (
                <Button variant="contained"
                    color="primary"
                    onClick={manager.toggleRightDrawer(true)}
                    style={{
                        position: "absolute",
                        height: "24px",
                        marginTop: "6px",
                        marginRight: "6px",
                        right: "0px"
                    }}>
                    Edit Draft
                </Button>
            )}
        </div>
    );
}

export default MobileMenu;