import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useEffect, useRef } from 'react';
import { Typography } from "@mui/material";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { authFb } from "../../../auth/auth";
import { ALL_COPY } from "../../../constants/copy";
import BoringSection from "./BoringSection";

export default function USA() {
  const [user, loading, error] = useAuthState(authFb);
  const navigate = useNavigate();
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  const headerBackgroundColor = '#313338';
  const textFlairColor = '#25A1B0';
  const textContrastColor = '#dddddd';

  if (true) {
    return (
      <div>
        <BoringSection
          imageSide="left"
          title=""
          imgsrc="./made-in-usa-1.svg">
          <p className="font20">
          We are an American-owned and operated company headquartered in Minneapolis, MN. We guarantee that your data does not get stored outside of our servers or used to train AI models. Our users may delete their submitted data at any point in time.</p>
          </BoringSection>
      </div>
    )
  }
  else {

    return (
      <div style={{ backgroundColor: '#ebebeb' }}>
        {/* contain */} <Wrapper id="Automatons" className="container flexCenter">

          <LeftSide>
            <ImgWrapper>
              {/* <img className="radius8" style={{ border: "1px solid white",opacity:".5" }} src={'./gen_opts.png'} alt="add" /> */}
            </ImgWrapper>

          </LeftSide>
          <RightSide>
            <h2 className="font40 extraBold"
              style={{
                color: "rgb(53 53 53)",
                marginTop: "10px",
                marginBottom: "10px"
              }}>Security and Privacy</h2>
            <p className="font20">
              Your data is sacred to us. Your data is not used by either us or our partners to train AI model. Integrity is our asset.                </p>

          </RightSide>
        </Wrapper>
      </div>
    );
  }
}

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const TitleText = styled.h1`

  @media (max-width: 600px) {
            h2 {
              font-size: 1.5em !important;
            }
          }
`;
const Wrapper = styled.section`
  width: 100%;
  min-height: 70dvh;
  height: max-content;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 40%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    // text-align: center;
  }
  @media (max-width: 560px) {
    margin: 10px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 60%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: -100px;
  }
  @media (max-width: 560px) {
    margin-top: 0px;
  }
`;
// const HeaderP = styled.div`
//   max-width: 470px;
//   padding: 15px 0 50px 0;
//   line-height: 1.5rem;
//   @media (max-width: 960px) {
//     padding: 15px 0 50px 0;
//     text-align: center;
//     max-width: 100%;
//   }
// `;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 30%;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
// const Img = styled.img`
//   @media (max-width: 560px) {
//     width: 80%;
//     height: auto;
//   }
// `;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
// const DotsWrapper = styled.div`
//   position: absolute;
//   right: -100px;
//   bottom: 100px;
//   z-index: 2;
//   @media (max-width: 960px) {
//     right: 100px;
//   }
//   @media (max-width: 560px) {
//     display: none;
//   }
// `;


