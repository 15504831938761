import React, { useState, useEffect,useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography,Box,CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import mammoth from 'mammoth';
import { Document, Page, pdfjs } from 'react-pdf';
// import {WorkerMessageHandler} from 'pdfjs-dist/build/pdf.worker.min.mjs'

pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.min.mjs';

function highlightTextInHtml(htmlString, searchText) {
  if (!searchText) return htmlString;
  const regex = new RegExp(`(${searchText})`, 'gi');
  return htmlString.replace(regex, '<span style="background-color: yellow;">$1</span>');
}


const LexDocumentDisplay = ({ open, onClose, link, pageNumber, highlightText }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageSet, setPageSet] = useState(false); // Spinner depends on this
    const [v, setV] = useState(1);
    const [init, setInit] = useState(false);
    const [docxHtml, setDocxHtml] = useState(null);
    const [loadingDocx, setLoadingDocx] = useState(false);
    const pageRefs = useRef([]); // Array of refs for all pages
    const fileExtension = 'pdf';
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
      if (init === false) {
        setInit(true);
      }
      setTimeout(() => {
        setV((v) => v + 1);
      }, 1000);
    };
  
    useEffect(() => {
      if (fileExtension === 'docx' && open) {
        setLoadingDocx(true);
        fetch(link)
          .then((response) => response.arrayBuffer())
          .then((arrayBuffer) => {
            mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
              .then((result) => {
                let html = result.value;
                html = highlightTextInHtml(html, highlightText);
                setDocxHtml(html);
                setLoadingDocx(false);
              })
              .catch((error) => {
                console.error('Error converting DOCX to HTML:', error);
                setLoadingDocx(false);
              });
          })
          .catch((error) => {
            console.error('Error fetching DOCX file:', error);
            setLoadingDocx(false);
          });
      }
    }, [link, open, highlightText]);
  
    // Scroll to the page passed as a prop
    useEffect(() => {
      if (pageRefs.current[pageNumber - 1]) {
        setPageSet(true); // When page is set, spinner can hide
        pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth' });
      }
    }, [numPages, pageNumber, v]);
  
    return (
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          Document Viewer
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ height: '80vh', overflowY: 'auto' }}>
          {fileExtension === 'pdf' ? (
            <>
              {!pageSet && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
              <Document
                key={link + init}
                file={link}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <div
                    key={`page_${index + 1}`}
                    ref={(el) => (pageRefs.current[index] = el)}
                  >
                    <Page
                      pageNumber={index + 1}
                      renderTextLayer={false} // Disable the text layer
                      renderAnnotationLayer={false} // (Optional) Disable annotations
                    />
                  </div>
                ))}
              </Document>
            </>
          ) : fileExtension === 'docx' ? (
            loadingDocx ? (
              <Typography>Loading document...</Typography>
            ) : docxHtml ? (
              <div dangerouslySetInnerHTML={{ __html: docxHtml }} />
            ) : (
              <Typography>Error loading document.</Typography>
            )
          ) : (
            <Typography>Unsupported file type.</Typography>
          )}
        </DialogContent>
      </Dialog>
    );
  };
  
  export default LexDocumentDisplay;