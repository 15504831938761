import Collector from '../Collector';
import { quickCheck, quickInput } from '../utilities';

const APIStepCollector = ({ configureStep, stepData, errorDisplay,stepId,testState,...other}) => {
    return <Collector
        inputFields={[
            quickInput(stepData, configureStep, "Target API", "targetAPI", "This is the variable the output will be stored in.",false,1),
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in.",false,1),
            quickInput(stepData, configureStep, "API Query", "prompt", "This is the queryt that will be sent to the API.",true,8,true,testState)
        ]}
    />
};

export default APIStepCollector;