import React from 'react';
import { Typography, Button, Grid, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import app_name from '../../../constants/name';

function FirmPaymentPage(props) {
    const navigate = useNavigate();
    const theme = useTheme()
    const patentId = props.patentId
    return (
        <PaymentWrapper>
            <PaymentText>
                <Typography style={{ marginBottom: "10px" }} variant="h5" >{app_name} for Legal Practitioners</Typography>
                <Typography variant="body1" >(Add copy)</Typography>
            </PaymentText>
            <Grid container spacing={2} style={{ paddingBottom: "30px" }}>
                <Grid item xs={12} sm={8}>
                    <PaymentOptionBox style={{ backgroundColor: theme.palette.backgroundSecondary.sidebar_two }}>
                        <div style={{ flex: '1 0 auto' }}>
                            <Typography variant="h5" >Firm Subscription</Typography>
                            <Typography variant="h6" >$999.99 USD/month</Typography>
                            <List>
                                {paymentMethod1Features.map((feature, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={`${index + 1}. ${feature}`} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        <Button variant="contained"
                            style={{ flex: '0 1 fit-content' }}
                            color="primary"
                            onClick={() => { navigate(`/checkout?onetime=0&review=0`) }}>
                            Select
                        </Button>
                    </PaymentOptionBox>
                </Grid>
            </Grid>
            <PaymentText>
                <Typography variant="body2" >* (add disclaimer)  </Typography>
            </PaymentText>
        </PaymentWrapper>)
}

const paymentMethod1Features = ['Best in class AI-assisted patent draft and figure generation tool', 'Access to LexConnect where we connect your business with inventors. Inventor information and claims are delivered right to your inbox', 'Soon-to-launch automated Office Action response suite','No yearly contracts, cancel anytime!'];


const PaymentWrapper = styled.div`
    width: 100%;
    max-width: 1000px;
    overflow: hidden scroll;
    scrollbar-gutter: stable both-edges;
`;

const PaymentText = styled.div`
    padding: 20px; 
    justify-content: space-between;
`;

const PaymentOptionBox = styled.div`
    border-radius: 5;
    max-width: 1000px;
    height: 100%;
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
`;


export default FirmPaymentPage;