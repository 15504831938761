import React, { useContext } from 'react';
import { Typography, Button, Grid, useTheme } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { REMOTE_SERVER } from '../constants/links';
import { ALL_COPY } from '../constants/copy';
import APIContext from '../contexts/api/context';
function PaymentPage(props) {
    const {api} = useContext(APIContext)
    const navigate = useNavigate();
    const theme = useTheme()
    const patentId = props.patentId
    return (
        <PaymentWrapper>
            <div style={{ maxWidth: '1000px' }}>
                <PaymentText>
                    <Typography style={{ marginBottom: "10px" }} variant="h5" >Our Pricing</Typography>
                    <Typography variant="body1" >{ALL_COPY.paymentPageCopy1}</Typography>
                </PaymentText>
                <Grid container spacing={2} style={{ paddingBottom: "30px", maxHeight: '35em' }}>
                    <Grid item xs={12} sm={6}>
                        <PaymentOptionBox style={{ maxHeight: '32em', backgroundColor: theme.palette.backgroundSecondary.sidebar_two }}>
                            <div style={{ flex: '1 0 auto' }}>
                                <Typography variant="h5" >{ALL_COPY.paymentPageCopy2}</Typography>
                                <Typography variant="h6" >$799.99 USD</Typography>
                                <List>
                                    {paymentMethod1Features.map((feature, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={`${index + 1}. ${feature}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            <Button variant="contained"
                                style={{ flex: '0 1 fit-content' }}
                                color="primary"
                                onClick={() => { navigate(`/checkout?patent_id=${patentId}&onetime=1&review=1`) }}>
                                Select
                            </Button>
                        </PaymentOptionBox>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                        <PaymentOptionBox style={{ maxHeight: '32em', backgroundColor: theme.palette.backgroundSecondary.sidebar_two }}>
                            <div style={{ flex: '1 0 auto' }}>
                                <Typography variant="h5" >Just the draft</Typography>
                                <Typography variant="h6" >$299.99 USD</Typography>
                                <List variant="h4" >
                                    {paymentMethod2Features.map((feature, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={`${index + 1}. ${feature}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            <Button variant="contained"
                                style={{ flex: '0 1 fit-content' }}
                                color="primary"
                                onClick={() => {
                                    navigate(`/checkout?patent_id=${patentId}&onetime=1&review=0`)
                                }}>
                                Select
                            </Button>
                        </PaymentOptionBox>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <PaymentOptionBox style={{ maxHeight: '32em', backgroundColor: theme.palette.backgroundSecondary.sidebar_two }}>
                            <div style={{ flex: '1 0 auto' }}>
                                <Typography variant="h5" >Connect</Typography>
                                <Typography variant="h6" >$0.00 USD</Typography>
                                <List variant="h4" >
                                    {paymentMethod3Features.map((feature, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={`${index + 1}. ${feature}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </div>
                            {!REMOTE_SERVER ? <Button variant="contained"
                                style={{ flex: '0 1 fit-content' }}
                                color="primary"
                                onClick={() => {
                                    api('/ptntst/skip', { patentId: props.patentId }).then(res => {
                                    })
                                }}>
                                TEMP: SKIP PAYMENT
                            </Button> : null}
                            <Button variant="contained"
                                style={{ flex: '0 1 fit-content' }}
                                disabled={true}
                                color="primary"
                                onClick={() => {
                                    // props.setOpenPage("marketplace");
                                    // navigate(`/checkout?patent_id=${patentId}`)
                                }}>
                                Coming Soon
                            </Button>
                        </PaymentOptionBox>
                    </Grid>
                </Grid>
                <PaymentText>
                    <Typography variant="body2">{ALL_COPY.paymentPageCopyFootnote1}</Typography>
                    <Typography variant="body2">{ALL_COPY.paymentPageCopyFootnote2}</Typography>
                </PaymentText>
            </div>
        </PaymentWrapper >)
}

const paymentMethod1Features = ALL_COPY.paymentPageCopyArray1
const paymentMethod2Features = ['Your patent draft application.', 'You will be charged $349 30 days from the date the draft is generated.'];
const paymentMethod3Features = ALL_COPY.paymentPageCopyArray2

const PaymentWrapper = styled.div`
    width: 100%;
    overflow: hidden scroll;
    scrollbar-gutter: stable both-edges;
`;
// max-width: 1000px;

const PaymentText = styled.div`
    padding: 20px; 
    justify-content: space-between;
`;

const PaymentOptionBox = styled.div`
    border-radius: 5;
    max-width: 1000px;
    height: 100%;
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
`;


export default PaymentPage;