import React, { useEffect, useState, useRef } from 'react';
import ReactFlow, { Background, Controls } from 'reactflow';
import StandardNode from './StandardNode/StandardNode.js';
import './text-updater-node.css';
import { StandardNodeMenu } from "./StandardNode/Menu/StandardNodeMenu.js";
import axios from "axios";
import { BASE_URL } from "../../constants/links.js";
import { Toolbar } from './MenuButtons/index.js';
import NodeEditorWrapper from "./editorWrapper.js";
import PreviewModeWrapper from './previewWrapper.js';
import { useFileHandlers } from './Hooks/index.js';
import { useFlowHandlers } from './Hooks/flow.js';
import { isGood, exactlyOnePath, findNodesWithPathsToNode, extractIntegerUserInputs } from '../../SHARED/evaluateNode.mjs';
import { useErrorModal } from '../../contexts/error/index.js';
// we define the nodeTypes outside of the component to prevent re-renderings
// you could also use useMemo inside the component
const nodeTypes = { standardNode: StandardNode };

const defaultStartNode = (fns) => ({
    id: 'start',
    type: 'input',
    targetPosition: 'top',
    position: { x: 200, y: 200 },
    data: { active: "start", functions: fns, index: 0, label: 'Start' },
})
const defaultEndNode = (fns) => ({
    id: 'end',
    type: 'output',
    targetPosition: 'top',
    position: { x: 700, y: 700 },
    data: { active: "start", functions: fns, index: 1, label: 'End' },
})
const defaultStandardNode = (index, fns) => ({
    id: 'node-' + (index),
    type: 'standardNode',
    position: { x: 300, y: 300 },
    data: {
        functions: fns,
        index: index,
        value: 123,
        store: {
            general: {
                active: "start",
                nodeName: index === 2 ? "defaultNode" : "" + index,
                inputHeaderText: "",
                descriptionLabel: ""
            },
            userInputs: [],
            gptPrompts: [],
            completedStatus: false,
            state: {}
        }
    }
})

const mutateStandardNodeDataPre = (setNodes, edges) => (idx, type, array) => {
    setNodes((nds) =>
        nds.map((node) => {
            if (node.data.index !== idx || node.id === 'start' || node.id === 'end') {
                return node;
            }

            return {
                ...node,
                data: {
                    ...node.data,
                    store: {
                        ...node.data.store,
                        [type]: array
                    }
                },
            };
        })
    );
}

const configureSpecialPropertiesPre = (setNodes, edges) => (idx, arg, value) => {
    setNodes((nds) =>
        nds.map((node) => {
            if (node.data.index !== idx || node.id === 'start' || node.id === 'end') {
                return node;
            }
            return {
                ...node,
                [arg]: value
            };
        })
    );
}

function Design() {
    const [nodes, setNodes] = useState([]);
    const showError = useErrorModal();
    const [edges, setEdges] = useState([]);
    const edgesRef = useRef({ edgeData: edges });
    edgesRef.current.edgeData = edges;
    const nodesRef = useRef({ nodeData: nodes });
    nodesRef.current.nodeData = nodes;
    const [previewMode, setPreviewMode] = useState(false);
    const [notInFlight, setNotInFlight] = useState(true);
    const [evalIdx, setEvalIdx] = useState('start');
    const [alreadyExecuted, setAlreadyExecuted] = useState([]);
    const [evalState, setEvalState] = useState({ context: {}, variables: {} });
    const [selectedNode, setSelectedNode] = useState(2)
    const changeEvalState = (newEvalState) => {
        setEvalState(newEvalState)
    }
    const changeEvalIdx = (newEvalIdx) => {
        const newNodes = nodes.map((nd) => {
            return { ...nd, data: { ...nd.data, active: newEvalIdx } }
        })
        setNodes(newNodes)
        setEvalIdx(newEvalIdx)
    }
    const { onNodesChange, onEdgesChange, onConnect, isValidConnection, deleteAllConnections, changeSelectedNode } = useFlowHandlers(setNodes, setEdges, setSelectedNode, nodes, edges);
    const funs = { setSelectedNode: changeSelectedNode, deleteAllConnections }
    const { handleFileLoad, handleFileLoadWithState, downloadObjectAsJson } = useFileHandlers(funs, setNodes, setEdges, changeEvalIdx, setEvalState)

    useEffect(() => {
        const timer = setInterval(() => {
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === 'start' || node.id === 'end') {
                        return node;
                    }
                    if (node.loopNode) {
                        let targetNode = nodesRef.current.nodeData.filter(otherNode => otherNode.id === node.loopNode)[0]
                        let nodesBeforeTargetNode = findNodesWithPathsToNode(nodesRef.current.nodeData, edgesRef.current.edgeData, targetNode).filter(otherNode => otherNode.id !== 'start')
                        console.log(nodesBeforeTargetNode)
                        let usableVariables = extractIntegerUserInputs(nodesBeforeTargetNode)
                        console.log(usableVariables)
                        delete node.admissible
                        return {
                            ...node,
                            usableVariables: usableVariables,
                            admissible: nds.filter((otherNode) => exactlyOnePath(nds, edgesRef.current.edgeData, otherNode, node) && otherNode.id !== 'start' && otherNode.id !== 'end').map((otherNode) => {
                                let nodeCopy = { ...otherNode }
                                delete nodeCopy.admissible
                                return nodeCopy
                            })
                        };
                    } else {
                        delete node.admissible
                        return {
                            ...node,
                            admissible: nds.filter((otherNode) => exactlyOnePath(nds, edgesRef.current.edgeData, otherNode, node) && otherNode.id !== 'start' && otherNode.id !== 'end').map((otherNode) => {
                                let nodeCopy = { ...otherNode }
                                delete nodeCopy.admissible
                                return nodeCopy
                            })
                        };
                    }
                }))
        }, 5000);

        return () => clearInterval(timer); // cleanup on unmount
    }, [])

    useEffect(() => {
        setNodes([
            defaultStartNode(funs),
            defaultEndNode(funs),
            defaultStandardNode(2, funs),
        ]);

        setEdges([]);
    }, []);

    const addNewNode = (numberOfCurrentNodes) => () => {
        setNodes((nodes) => {
            return [...nodes, defaultStandardNode(numberOfCurrentNodes, funs)]
        })
    }



    function executeSequence() {
        let graphIsGood = isGood(nodes, edges)
        if (graphIsGood === true) {
            if (evalIdx === "end") {
                changeEvalIdx("start")
                setEvalState({ context: {}, variables: {} })
                return null
            }
            const edgesSearched = edges.filter(edge => edge.source === evalIdx)
            const next = edgesSearched[0].target
            const currentNode = nodes.filter(node => node.id === next)[0]
            setNotInFlight(false)
            try {
                console.log(BASE_URL)
                axios.post(BASE_URL + '/tempEvaluate', {
                    nodes: nodes,
                    edges: edges,
                    evalState: evalState,
                    evalIdx: evalIdx,
                    currentNode: currentNode,
                }
                ).then(apiResponse => {
                    console.log(apiResponse)
                    setNotInFlight(true)
                    setEvalState(apiResponse.data)
                    changeEvalIdx(next)
                    setAlreadyExecuted((previous) => [...previous, evalIdx])
                })
            } catch (e) {
                setNotInFlight(true)
            }
        } else {
            showError(graphIsGood,0)
        }
    }

    const currentlySelectedNode = nodes[selectedNode]
    const mutateStandardNodeData = mutateStandardNodeDataPre(setNodes, edgesRef.current)
    const configureSpecialProperties = configureSpecialPropertiesPre(setNodes, edgesRef.current)
    return (
        <div style={{ display: "flex", flexDirection: "row", height: '100vh', width: '100vw' }}>
            <div style={{ height: '100vh', width: '65vw' }}>
                <Toolbar
                    nodes={nodes}
                    edges={edges}
                    evalState={evalState}
                    evalIdx={evalIdx}
                    previewMode={previewMode}
                    notInFlight={notInFlight}
                    downloadObjectAsJson={downloadObjectAsJson}
                    handleFileLoad={handleFileLoad}
                    handleFileLoadWithState={handleFileLoadWithState}
                    setPreviewMode={setPreviewMode}
                    setEvalState={setEvalState}
                    changeEvalIdx={changeEvalIdx}
                    addNewNode={addNewNode}
                    executeSequence={executeSequence}
                />
                <StandardNodeMenu />
                {!previewMode ? <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    isValidConnection={isValidConnection}
                // fitView
                >
                    <Background />
                    <Controls />
                </ReactFlow> : null}
            </div>
            <NodeEditorWrapper
                previewMode={previewMode}
                nodes={nodes}
                edges={edges}
                selectedNode={selectedNode}
                currentlySelectedNode={currentlySelectedNode}
                evalIdx={evalIdx}
                evalState={evalState}
                changeEvalState={changeEvalState}
                mutateStandardNodeData={mutateStandardNodeData}
                configureSpecialProperties={configureSpecialProperties}
            />
            <PreviewModeWrapper
                previewMode={previewMode}
                currentlySelectedNode={currentlySelectedNode}
            />
        </div>
    );
}

export default Design;
