import React, { useContext } from "react";
import LexStandardList from "../../../Lex/LexStandardList/index.js";
import PatentProcessContext from "../../../../contexts/patent/context.js";
import LexExpandableAccordion from "../../../Lex/LexExpandableAccordion/index.js";
import { AccordionSummary } from "@mui/material";
import LexTypography from "../../../Lex/LexTypography/index.js";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiagonalSplitter from "../../../Design/DiagonalSplitter/index.js";

const PatentSidebar = (props) => {
    const { patentManager } = useContext(PatentProcessContext)
    const onPatentClick = (patent) => {
        patentManager.editPatent(patent.id)
    }
    return (
        <div style={{
            ...props.docstyle,
            display: 'flex',
            flexDirection: 'column',
        }}>
            <LexExpandableAccordion
                expanded={props.expanded === 'panel2'} onChange={()=>props.handleChange('panel2')}
                Summary={   
                    ()=>(<AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <DiagonalSplitter width={25} height={40} outer="rgba(0,0,0,.1)" middle="rgba(0,0,0,0)" inner="rgba(0,0,0,.3)"/>
                        <div style={{
                        paddingLeft:"50px",
                        width:"100%"}}>
                        <LexTypography sx={{ fontWeight: 500 }}>Patent Drafting</LexTypography>
                        </div>
                    </AccordionSummary>)
                }
                details={<LexStandardList
                    items={patentManager.patentList}
                    onItemClick={onPatentClick}
                    addNewItem={patentManager.addNewPatent}
                    newItemText={"New Draft"} />
                } />
        </div>
    );
};

export default PatentSidebar;