import React, { useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DesContext from '../../../../contexts/singleFlow/context';
import { useTheme } from '@mui/material';

const StylizedSummary = ({step,index}) => {
    const theme = useTheme();
    const { manager} = useContext(DesContext);
    return (
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            style={{ backgroundColor: "rgb(0,0,0,.15)" }}

        >
            <div style={{
                display: "flex",
                backgroundColor: manager.stepData[step]?.suppliedName ? "white": null,
                height: "60px",
                width: "550px",
                // borderRadius: "5px 20px 20px 5px"
                clipPath: "polygon(0 0, 535px  0, 100% 100%, 0 100%)"
            }}><div style={{
                display: "flex",
                backgroundColor: manager.stepData[step].labelDisplay ? theme.palette.primary.main : null,
                height: "60px",
                width: "300px",
                // borderRadius: "5px 20px 20px 5px"
                clipPath: "polygon(0 0, 285px  0, 100% 100%, 0 100%)"
            }}>
                    <div style={{
                        backgroundColor: theme.palette.backgroundSecondary.default, // "#2f3636",
                        color: theme.palette.backgroundSecondary.contrastText,
                        paddingLeft: "12px",
                        paddingTop: "14px", height: "60px",
                        width: "120px",
                        // borderRadius: "0px 20px 20px 0px"
                        clipPath: "polygon(0 0, 105px  0, 100% 100%, 0 100%)"
                        
                    }}>
                        
                        <Typography variant="h6">Step {index+1}</Typography>
                    </div>
                    <div style={{ paddingLeft: "20px", paddingTop: "14px", color: theme.palette.backgroundSecondary.contrastText,}}>
                        <Typography variant="h6">{manager.stepData[step].labelDisplay}</Typography>
                    </div>
                </div>
                <div style={{
                    paddingLeft: "15px",
                    paddingTop: "18px",
                    overflow: "hidden",
                    maxWidth: "200px",
                }}>
                    <Typography
                    sx={{fontWeight:"500"}}
                    style={{
                        fontWeight: '500',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: '1em',
                    }}>{manager.stepData[step].suppliedName}</Typography>
                </div>
            </div>

        </AccordionSummary>

    );
};

export default StylizedSummary;