import React, { useState, useEffect, useContext } from 'react';
import FirmSignUpContext from './context';
import { authFb } from '../../auth/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useErrorModal } from '../error';
import APIContext from '../api/context';

const FirmSignUpContextProvider = ({ children }) => {
    const {api} = useContext(APIContext)
    const [showFirmSignUp, setShowFirmSignUp] = useState(false);
    const [showInvitationModal, setShowInvitationModal] = useState(false);
    const [firmData, setFirmData] = useState(null);
    const [user, loading, error] = useAuthState(authFb);
    const [pendingFirmInviteBy, setPendingFirmInviteBy] = useState("")
    const [firmMem, setFirmMem] = useState(false)
    const showError = useErrorModal()

    const getData = () => {
        api('/ptntst/checkFirmMem', {}).then(response => {
            if (response.data.membershipStatus) {
                setFirmMem(response.data.membershipStatus)
            }
        })
        api('/ptntst/getFirmData', {}).then(response => {
            if (response.data.firmData) {
                setFirmData(response.data.firmData)
            }
        })
        api('/ptntst/getPendingFirmInviteBy', {}).then(response => {
            if (response.data.pendingFirmInviteBy) {
                setPendingFirmInviteBy(response.data.pendingFirmInviteBy)
            }
        })

    }

    const inviteUserToFirm = (email) => {
        api('/ptntst/inviteUserToFirm', { userEmail: email }).then(response => {
            if (response.complete) {
                getData()
            }
        }).catch(err => {
            showError(err.response.data)
        })
    }
    const reloadFirmData = () => {
        getData()
    }
    useEffect(() => {
        if (user) {
            getData()
        }
    }, [user])
    useEffect(() => {
        if (user) {
            getData()
        }
    }, [showFirmSignUp, user])
    return (
        <FirmSignUpContext.Provider value={{
            pendingFirmInviteBy, setPendingFirmInviteBy, inviteUserToFirm, firmMem, reloadFirmData, showFirmSignUp, firmData, setShowFirmSignUp, showInvitationModal, setShowInvitationModal
        }}>
            {children}
        </FirmSignUpContext.Provider>
    );
}

export default FirmSignUpContextProvider;