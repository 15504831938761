import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import {
    FormControlLabel,
    FormControl, InputLabel, Select, MenuItem,
    Typography,
    Radio, RadioGroup,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useEffect, useState } from "react";
import { FieldCard } from './cards';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

const options = [
    ['Computers, Software, Digital Communications', 'CE'],
    ['Electrical & Electronics', 'EE'],
    ['Mechanical', 'ME'],
    ['Chemical', 'C'],
    ['Bio/Pharma, Drugs, Medical', 'B'],
]
const FormInput = (props) => {
    const [value, setValue] = useState('1');
    const [mirror, setMirror] = useState(false);
    const [selectValue, setSelectValue] = useState(0);
    const theme = useTheme();
    const useSampleData = true

    useEffect(() => {
        if (props.label === "patentType") {
            props.onChange({ target: { value: options[0][0] } })
        } else {
            if (props.label === "mirrorClaim") {
                props.onChange({ target: { value: "0" } })
            } else {
                if (useSampleData) {
                    // props.onChange({ target: { value: props.sample } })
                    props.onChange({ target: { value: "" } })
                }
            }
        }
    }, []);

    const handleChange = (event) => {
        setValue(event.target.value);
        props.onChange({ target: { value: options[event.target.value][1] } })
    };

    if (props.label === "patentType") {
        return <FormControl>
            <InputLabel id="demo-simple-select-helper-label">Patent Type</InputLabel>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={'Patent Type'}
                // id="demo-simple-select"
                variant={'filled'}
                value={value}
                onChange={handleChange}
                defaultValue={options[0][0]}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={index}>{option[0]}</MenuItem>
                ))}
            </Select>
        </FormControl>
    }
    const handleChangeMirror = (event) => {
        setMirror(event.target.checked);
        if (event.target.checked = "") {
            props.onChange({ target: { value: "0" } })
        } else {
            props.onChange({ target: { value: "1" } })
        }
    };
    if (props.label === "mirrorClaim") {
        return <div style={{ color: "black" }}>
            <div style={{ marginBottom: "15px", color: "black" }}>The following setting is strongly recomended for software patent application drafts. </div><div style={{ marginBottom: "15px", color: "black" }}>If enabled: Note that we will only produce a detailed description for the first instance of the independent claim, and that editing the first instance will edit all three instances simultaneously.</div>
            <FormControlLabel
                control={<Checkbox checked={mirror} onChange={handleChangeMirror} />}
                label="Repeat your independent claim with distinct preambles? " />
        </div>
    }

    if (props.label === "indClaimCount") {
        return <RadioGroup aria-label="numbers" name="numbers" value={value} onChange={handleChange}>
            <FormControlLabel value="1" control={<Radio />} label="1" />
            <FormControlLabel value="2" control={<Radio />} label="2" />
            <FormControlLabel value="3" control={<Radio />} label="3" />
        </RadioGroup>
    }

    switch (props.type) {
        case 'textInput':
            return <TextField
                label={props?.description}
                required={props?.required}
                onChange={props.onChange}
                defaultValue={""}
                variant="filled"
                fullWidth
                color="primary"
                inputProps={{ maxLength: 20000 }}
                type="text" />
        case 'textArea':
            return <TextField
                label={props?.description}
                required={props?.required}
                onChange={props.onChange}
                defaultValue={""}
                inputProps={{ maxLength: 20000 }}
                multiline
                rows={6}
                fullWidth
                variant="filled"
                color="primary"
                type="text" />
        case 'checkbox':
            return <div style={props.useScroll ? { height: "30vh", overflowY: "scroll" } : null}><FormControlLabel

                label={props?.description}
                required={props?.required}
                onChange={(e) => props.onChange({ target: { value: e.target.checked } })}
                control={
                    <Checkbox
                        color="primary" />
                } />
            </div>
        default:
            return <div></div>

    }
}

export const FormPage = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    let pageColors

    if (props?.userInputs.length === 0) {
        pageColors = ["secondary", "secondary", "primary"]
    } else {
        pageColors = ["primary", "secondary", "primary"]
    }

    return (

        <div key={props.general.descriptionLabel} style={{
            display: 'flex',
            flexGrow: "1",
            height: '100%',
            width: 'auto',
            overflowY: "scroll",
            scrollbarGutter: 'stable both-edges',
            flexDirection: 'column',
            overflowX: "hidden",
            paddingRight: isMobile ? '20px' : '3em',
        }}>
            <div style={{
                width: isMobile ? "100%" : "40vw",
                display: 'flex', flexDirection: 'column',
                paddingLeft: '20px',
            }}>

                <div style={{
                    width: 'auto',
                    display: 'flex',
                    padding: '3em 0px 2em',
                    flexDirection: 'column',
                }}>
                    <div style={{
                        flex: '1 1 auto',
                        height: '100%', width: '100%',
                        paddingTop: '0px',
                        display: 'flex', flexDirection: 'column',
                    }}>
                        <Typography variant="h5" style={{ paddingBottom: '1em' }} gutterBottom>
                            {props.general.inputHeaderText}
                        </Typography>
                        {props.general.descriptionLabel.split("\\n").map((i, key) => {
                            return <Typography variant="body1" gutterBottom
                                style={{
                                    margin: key > 0 ? '20px 0px 0px' : '0px'
                                }}>{i}<br /></Typography>
                        })}
                    </div>
                </div>

                <div>
                    {props?.userInputs.map(
                        inputData =>
                            <FieldCard
                                color="field"
                                style={{
                                    margin: '5px 0px 20px',
                                    width: isMobile ? "auto" : 'auto',
                                }}>
                                <FormInput
                                    onChange={e => {
                                        props.updateState(inputData.label, e.target.value)
                                    }}
                                    updateState={props.updateState}
                                    {...inputData} />
                            </FieldCard>
                    )}
                </div>

            </div>
            <div style={{
                position: 'relative',
                height: '100%',
                width: isMobile ? "100%" : "40vw",
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}>
                    <div style={{
                        marginBottom: '1em'
                    }}>
                        {!props.startPage ? <Button
                            onClick={props.backButtonHit}
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<ArrowLeftIcon />}
                        >
                            Back
                        </Button> : null}
                        <Button
                            style={{ marginLeft: "15px" }}
                            onClick={props.continueSequence}
                            variant="contained"
                            color="primary"
                            type="submit"
                            endIcon={<ArrowRightIcon />}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}