import React, { useState } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { SiteGate, buttonStyle, lastButtonStyle } from "../sitegate";
import { Alert } from "@mui/material";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../auth";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [error, setError] = useState('');
    const [sentEmail, setSentEmail] = useState(false);

    const forgot = async () => {
        sendPasswordResetEmail(auth, email)
            .then(function () {
                setSentEmail(true);
                setError('');
            })
            .catch(function (error) {
                console.error(error)
                setSentEmail(false);
                setError(error.message);
            });
    };

    var tryfirst = true;
    tryfirst = false;

    return (
        <SiteGate
            title="Password Reset"
            error={error}
            buttons={
                <div style={{ width: '100%', padding: '0 2em' }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={tryfirst ? buttonStyle : lastButtonStyle}
                        onClick={forgot}>
                        Reset
                    </Button>
                    {tryfirst &&
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled
                            style={{
                                ...lastButtonStyle,
                                background: 'transparent',
                                color: '#0000',
                            }}>
                            .
                        </Button>
                    }
                </div>
            }>
            <Grid container spacing={2}>
                <Grid item xs={12}>

                    {sentEmail
                        && <Alert style={{ marginBottom: "20px" }} severity="success">
                            Email sent!</Alert>}

                    <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                </Grid>
            </Grid>
        </SiteGate>
    );
}

export default ForgotPassword