// export const BASE_URL = ['production'].includes(process.env.NODE_ENV)
//     ? 'https://patentist-backend.azurewebsites.net' : 'http://localhost:8080'
export const BASE_URL = ['production'].includes(process.env.NODE_ENV)
    ? 'https://lexautomata-be-main.azurewebsites.net/' : 'http://localhost:8080'


export const REMOTE_SERVER = ['production'].includes(process.env.NODE_ENV)



// console.log(process.env)