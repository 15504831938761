import React, { useContext} from 'react';
import DesContext from '../../../../contexts/singleFlow/context';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTheme } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';

const FastForwardControl = ({ index }) => {
    const theme = useTheme();
    const { manager } = useContext(DesContext);
    // const status = manager.fastForwardStepStatus(index);
    return (
        <div style={{
            // backgroundColor: theme.palette.backgroundSecondary.paper,
            display: "flex",
            margin: "3px",
            // color: theme.palette.backgroundSecondary.contrastText,
            borderRadius: "5px",
            border: "1px solid white"
        }}>
            <IconButton onClick={(e) => { manager.playStep(index) }}
            // style={{ color: theme.palette.backgroundSecondary.contrastText }}
            >
                <PlayArrowIcon />
            </IconButton>
            <IconButton onClick={(e) => { manager.fastForwardStep(index) }}
            // style={{ color: theme.palette.backgroundSecondary.contrastText }}
            >
                <FastForwardIcon />
            </IconButton>
            {/* <StatusButton status={status} /> */}
        </div>
    );
}

export default FastForwardControl;