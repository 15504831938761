
import React,{useEffect, useState} from 'react';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CheckboxCollector from './common/checkboxCollector';
import { MarkdownTable } from '../../../components/Lex/LexMarkdownTable';
import GenerativeStepCollector from '../../../components/Automata/GenerativeStepCollector';
import ContextCollector from '../../../components/Automata/ContextCollector';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import { Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import GenerativeStepVerificationCollector from '../../../components/Automata/GenerativeStepVerificationCollector';

const generateOutputs = (testState, stepId) => {
    const state = testState?.[stepId] || {};
    return Object.keys(state).map(key => {
        let label = key.replace(/__/g, '').replace(/_/g, ' ').replace('response', '');
        label = label.charAt(0).toUpperCase() + label.slice(1);
        return { key, label, value: state[key] };
    });
};


const TestMultiparsingDisplay = ({ configureStep, stepData, errorDisplay, testState, stepId }) => {
    // State to manage which output is currently selected
    const outputs = generateOutputs(testState, stepId);
    const [selectedOutput, setSelectedOutput] = useState(outputs[0]?.key);
    const handleSelectOutput = (key) => {
        setSelectedOutput(key);
    };

    return (
        <Box display="flex">
            {/* Sidebar Drawer */}
   
            {stepData?.useMultiParse ? <List>
                    {outputs.map((output) => (
                        <ListItem 
                            button 
                            key={output.key} 
                            selected={selectedOutput === output.key}
                            onClick={() => handleSelectOutput(output.key)}
                        >
                            <ListItemText primary={output.label} />
                        </ListItem>
                    ))}
                </List>:null}

            {/* Main Content Area */}
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                {stepData?.useMultiParse ? (
                    <>
                            <MarkdownTable markdown={outputs.find(output => output.key === selectedOutput)?.value || ""} />
                    </>
                ) : (
                    <MarkdownTable markdown={testState?.[stepData?.varName] ? testState?.[stepData?.varName] : ""} />
                )}
            </Box>
        </Box>
    );
};

export const generativeStep = {
    overview: {
        name: 'generate',
        label: 'Produce LLM generated text?',
        sublabel: 'Utilize ChatGPT, Gemini, Mistral or another LLM to generate legal text.',
        icon: (<div style={{ position: 'relative', width: '100%', height: '100%', marginTop: '10px', marginLeft: '4px' }}>
            <AutoAwesomeSharpIcon style={{
                position: 'absolute', height: '16px', width: '16px',
                top: '4px', left: '16px',
                clipPath: 'polygon(0 0, 50% 0, 100% 100%, 0 100%)',
                transform: 'rotate(180deg)',
            }} />
            <TextFieldsOutlinedIcon style={{
                position: 'absolute',
                top: '5px',
                left: '0px',
            }} />
        </div>),
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'generativeStep',
                labelDisplay: "Generation",
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    outputDisplay: {
        enabled: true,
        collectorComponent: props=><TestMultiparsingDisplay {...props} />

    },
    // Please analyze the diffences between the responses offered by the two different models.
    primaryOptions: {
        header: 'Generative Step',
        description: 'A generative step creates a conservation with the LLM. You may either create a fresh conversation or continue an existing one.  \n\n Under certain cirmcumstances, continuing an existing conversation allows the LLM to utilize the existing context to generate more accurate responses.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    'verification_critera': 'Please analyze the diffences between the responses offered by the two different models.',
                })
                configureStep.set({
                    useExistingContext: false,
                    display: 'tableCheck'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    'verification_critera': 'Please analyze the diffences between the responses offered by the two different models.',
                })
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        header: 'Generative Step',
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollector nextStep={'tableCheck'} {...props} />,
    },
    optionalOne: {
        label: 'Validation',
        show:(props) => {return props.stepData?.useMultiParse},
        collectorComponent: (props) => <GenerativeStepVerificationCollector {...props} />,
    },
    tableCheck: {
        header: 'Are you generating a table?',
        description: 'We can help you generate a table in a variety of formats.  Please select the checkbox below.',
        collectorComponent: (props) => <CheckboxCollector
            paramName={'isTable'}
            checkboxLabel={'Generate a table?'}
            nextStep={'writePrompt'}
            {...props} />
    },

    writePrompt: {
        header: 'Generative Step',
        description: 'Please write your LLM prompt and assign an output variable. You may use any text variables previously set at a preceeding step.',
        collectorComponent: (props) => <GenerativeStepCollector {...props} />,
    },
    default: {

    }


};