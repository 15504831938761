import {Menu, Item, Separator, Submenu, useContextMenu} from 'react-contexify';
import 'react-contexify/ReactContexify.css';

const MENU_ID = 'StandardNodeMenu';

export function StandardNodeMenu() {
    const {show} = useContextMenu({
        id: MENU_ID,
    });

    function handleContextMenu(event) {
        console.log(show)
        console.log(event)
        show({
            event,
            props: {
                key: 'value'
            }
        })
    }

    // I'm using a single event handler for all items
    // but you don't have too :)
    const handleItemClick = ({id, event, props}) => {
        switch (id) {
            case "copy":
                console.log(event, props)
                break;
            case "cut":
                console.log(event, props);
                break;
            //etc...
        }
    }

    return (
        <Menu id={MENU_ID}>
            <Item id="copy" onClick={handleItemClick}>Add (User Input)</Item>
            <Item id="cut" onClick={handleItemClick}>Add (Store Input)</Item>
            <Item id="cut" onClick={handleItemClick}>Add GPT Prompt</Item>
            <Separator/>
            <Item disabled>Disabled</Item>
            <Separator/>
            <Submenu label="Foobar">
                <Item id="reload" onClick={handleItemClick}>Reload</Item>
                <Item id="something" onClick={handleItemClick}>Do something else</Item>
            </Submenu>
        </Menu>
    );
}