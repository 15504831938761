import React, { useState, useEffect, useContext } from 'react';
import { Container, List, ListItem, ListItemText, Button, TextField, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router';
import FirmPaymentPage from '../FirmPayment';
import FirmSignUpContext from '../../../contexts/firm/context';
import { ALL_COPY } from '../../../constants/copy';
import APIContext from '../../../contexts/api/context';

function UserManagement() {
  const { api } = useContext(APIContext);
  const { firmData, reloadFirmData, inviteUserToFirm } = useContext(FirmSignUpContext);
  const users = firmData.permissionedUsers
  const [firm, setFirm] = useState(null);
  const [email, setEmail] = useState('');
  const [isSuperUser, setIsSuperUser] = useState(false); // You need to determine this based on your auth logic
  const [patentsRemaining, setPatentsRemaining] = useState(0);
  const navigate = useNavigate();
  console.log(firmData);
  useEffect(() => {
    // Fetch users and firm information. You might need to adjust these API endpoints based on your backend.
    // const fetchUsers = async () => {
    //   const usersResponse = await axios.get('/api/users');
    //   setUsers(usersResponse.data);
    // };

    // const fetchFirmInfo = async () => {
    //   const firmResponse = await axios.get('/api/firm');
    //   setFirm(firmResponse.data);
    //   setIsSuperUser(firmResponse.data.isSuperUser);
    // };

    // fetchUsers();
    // fetchFirmInfo();
    // Add your logic to calculate patents remaining for the monthly period
  }, []);

  const handleDeleteUser = async (userId) => {
    // await axios.delete(`/api/users/${userId}`);
    // setUsers(users.filter(user => user.id !== userId));
  };

  const handleUnsub = async () => {
    api('/ptntst/cancelSub',
      {})
      .then(res => {
        if (res.data.cancelled) {
          navigate(`/main`);
          reloadFirmData();
        }
      })
  };

  const handleSendInvite = async () => {
    inviteUserToFirm(email);
    console.log('Sending invite to', email);
    setEmail('');
  };

  if (!!!firmData.subscription) {
    return <FirmPaymentPage patentId={1} />;
  }

  if (!isSuperUser && firm) {
    return (
      <Container>
        <Typography variant="h4">Firm: {firm.name}</Typography>
        {/* List patents paid for by the superuser */}
        <List>
          {firm.patents.map((patent) => (
            <ListItem key={patent.id}>
              <ListItemText primary={patent.title} />
            </ListItem>
          ))}
        </List>
      </Container>
    );
  }

  return (
    <div style={{ marginLeft: "50px", marginTop: "30px" }}>
      <Typography variant="h4" style={{ marginTop: "30px", marginBottom: "30px" }}>Firm Management</Typography>

      <Typography variant="body1" style={{ marginTop: "30px", marginBottom: "30px",maxWidth:"800px"}}>
        {ALL_COPY.firmPageCopy1} <Link href="mailto:info@patentist.ai">info@patentist.ai</Link> {ALL_COPY.firmPageCopy2}</Typography>
      <Typography variant="h5" style={{ marginTop: "30px", marginBottom: "30px" }}>User Management (Maximum of 3)</Typography>
      <List style={{ width: "400px" }}>
        {users.map((user) => (
          <ListItem key={user.id} style={{ border: "solid 1px black" }} secondaryAction={
            <Button variant="contained" color="error" onClick={() => handleDeleteUser(user.id)}>Delete</Button>
          }>
            <ListItemText
              primary={user}
            />
          </ListItem>
        ))}
      </List>
      {/* <Typography variant="h6">Patents Remaining: {patentsRemaining}</Typography> */}
      <Typography variant="h5" style={{ marginTop: "30px", marginBottom: "30px" }}>Invite Users</Typography>
      <div style={{
        display: "flex", gap: "30px", width: "250px", flexDirection: "column"
      }}>
        <TextField label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" onClick={handleSendInvite}>Send Invite</Button>


      </div>
      <Typography variant="body2" style={{ marginTop: "30px", marginBottom: "30px",maxWidth:"800px"}}>{ALL_COPY.firmPageFootnoteCopy1} <Link href="mailto:info@patentist.ai">info@patentist.ai</Link> {ALL_COPY.firmPageFootnoteCopy2}</Typography>
      
      {/* <Typography variant="h5" style={{ marginTop: "30px", marginBottom: "30px" }}>Other Settings</Typography> */}
      {/* <Button variant="contained" color="error" onClick={handleUnsub}>Unsubscribe </Button> */}
    </div>
  );
}

export default UserManagement;
