import React, { useContext, useState } from 'react';
import app_name from '../constants/name.js';
import {
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
} from '@mui/material';
import APIContext from '../contexts/api/context.js';

function StartPage(props) {
    const {api} = useContext(APIContext)
    const [tosConfirmed, setTosConfirmed] = useState(false)
    const [patentName, setPatentName] = useState("Untitled")
    const isMobile = props.isMobile
    const setShowPolicy = props.setShowPolicy
    const editPatentName = () => {
        api('/ptntst/editPatentName', { patentId: props.patentId, name: patentName }).then(res => {
            console.log(patentName)
            props.setRefreshSidebar(r => (r + 1))
            props.getPatent()
        });
    }
    const handleCheckboxChange = (event) => {
        setTosConfirmed(event.target.checked);
    };
    return (<div style={{
        width: "100%",
        height: '100%',
        overflow: "hidden scroll",
        scrollbarGutter: 'stable both-edges',
    }}>
        <div style={{
            width: isMobile ? 'auto' : '60vw',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" gutterBottom style={{
                    marginLeft: "20px",
                    marginTop: "50px",
                    marginBottom: "20px"
                }}>
                    {app_name} Notices and Disclaimers
                </Typography>
                <Typography variant="body2" gutterBottom style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "20px"
                }}>
                    American Made: {app_name} is 100% American made. Our services are exclusively conducted within the United States, and our team fully resides there as well.
                </Typography>
                <Typography variant="body2" gutterBottom style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "20px"
                }}>
                    Confidentiality and Privacy: We do not store any of the data you submit, nor do we sell it to any third parties. The Legal Language Models (LLMs) we employ are third-party in nature, and we submit your data as prompts to these LLMs. While these third parties have stated that they do not use this data for training purposes, we cannot guarantee the privacy or confidentiality of your submitted data on behalf of any such third parties. Therefore, we recommend that you use your best judgment and exercise caution when submitting sensitive information.
                </Typography>
                <Typography variant="body2" gutterBottom style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "20px"
                }}>
                    Not a Law Firm: Although we hire and collaborate with patent attorneys to regularly review and improve the quality of our services and output, {app_name} is not a law firm. We do not establish a client-attorney relationship with users of our service. We are not your legal representatives, and you are not our clients. Any use of our service does not create an attorney-client relationship.
                </Typography>
                <Typography variant="body2" gutterBottom style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "20px"
                }}>
                    Quality and Nature of Output: While we strive to provide the highest quality in generating patent application drafts, it's important to acknowledge the dynamic nature of generative AI and Legal Language Models (LLM). We do not guarantee specific results or outcomes. Users are encouraged to seek the services of an attorney or law firm to review and revise application drafts for the best possible outcomes. By using {app_name}, you agree to indemnify us against any liability arising from the quality of output or the results obtained through our service. This includes, but is not limited to, any issues related to the generative AI and LLM technology employed in the drafting process.
                </Typography>
                <Typography variant="body2" gutterBottom style={{
                    marginLeft: "20px",
                    marginTop: "5px",
                    marginBottom: "20px"
                }}>
                    Payment Data: We do not store any of your payment data. Your financial information is processed securely through a third-party provider, Stripe, and we do not retain any record of such data. {app_name} holds no responsibility for any issues encountered during the payment process via Stripe. Any concerns or disputes related to payments should be addressed directly with Stripe.
                </Typography>
                <Typography
                    onClick={() => { setShowPolicy(true) }}
                    variant="body1"
                    gutterBottom
                    style={{
                        marginLeft: "20px",
                        marginTop: "5px",
                        marginBottom: "20px",
                        cursor: 'pointer'
                    }}>
                    Click here to view the Terms of Service.
                </Typography>

                <FormControlLabel
                    style={{ marginLeft: "15px", marginTop: "5px", }}
                    control={
                        <Checkbox
                            checked={tosConfirmed}
                            onChange={handleCheckboxChange}
                            name="tosConfirmed"
                        />
                    }
                    label="By continuing, I agree to the Terms of Service"
                />
                <Typography variant="h5" gutterBottom
                    style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}>
                    Now, let’s give your invention a nickname.
                </Typography>


                <Typography variant="body2" gutterBottom
                    style={{ marginLeft: "20px", marginTop: "5px", marginBottom: "20px" }}>
                    This will not be the final title of your draft, but it will help us keep track of your patent application draft, so you may refer back to it or redownload at a later time. You may delete this patent application draft at any time.
                </Typography>
                <div style={{
                    margin: '10px',
                }}>
                    <TextField
                        label={"Name"}
                        onChange={(e) => { setPatentName(e.target.value) }}
                        defaultValue={'Untilted'}
                        variant="filled"
                        fullWidth
                        color="primary"
                        type="text" />

                </div>
            </div>

            <div style={{
                position: "relative",
                height: "100%",
                width: "100%"
            }}>
                {/* Submit Button */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                }}>
                    <div style={{
                        // position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        marginRight: "20px",
                        marginBottom: "5px"
                    }}>
                        <Button
                            onClick={editPatentName}
                            variant="contained"
                            disabled={!tosConfirmed}
                            color="primary"
                            type="submit">
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default StartPage;