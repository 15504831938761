import React, { useState} from 'react';
import ContactContext from '../../contexts/contact/context';

const ContactContextProvider = ({ children }) => {
    const [showModal,setShowModal] = useState(false);
    return (
        <ContactContext.Provider value={{showModal,setShowModal
        }}>
            {children}
        </ContactContext.Provider>
    );
}

export default ContactContextProvider;