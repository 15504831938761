import React from 'react';
import { Dialog, DialogContent, IconButton,Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LexVideoDialog = ({ open, onClose, videoId }) => {
    return (
        <Dialog open={open} maxWidth="lg"  fullWidth>
            <DialogContent sx={{bgcolor:'rgb(0,0,0,.9)',paddingLeft:'0px',paddingRight:'0px',paddingBottom:'0px'}}>
                <Typography sx={{
                        position: 'absolute',
                        left: 16,
                        top: 16,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    Lex Video Tutorial
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        zIndex: 10000000,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <div style={{ position: 'relative', width:"100%",paddingTop: '56.25%' }}>
                    <iframe
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube video player"
                        style={{
                            position: 'absolute',
                            top: 30,
                            left: 0,
                            width: '100%',
                            height:  'calc(100% - 30px)',
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default LexVideoDialog;