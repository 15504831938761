import React, { useContext,useLayoutEffect,useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import { Logo } from "../../../components/logoComponent";
import PolicyContext from "../../../contexts/policy/context";
import app_name from "../../../constants/name";
import ContactContext from "../../../contexts/contact/context";
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


export default function Contact({teamPage,setOnTeamPage}) {
  const { showPolicy, setShowPolicy } = useContext(PolicyContext);
  const { showModal, setShowModal } = useContext(ContactContext);
  const [width, height] = useWindowSize();
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }
  const mobile = width < 560;
  return (
    <Wrapper>
      <div className="navBackgroundAlt">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={0}>
              {/* <div style={{paddingLeft:"-20px"}}> */}
              <Logo color="#e6e6e6" format="textFooter"/>
              {/* </div> */}
            </Link>
            <StyleP className="font13">
              © {getCurrentYear()} - <span className="purpleColor font13">{app_name}</span> All Right Reserved
            </StyleP>
            <Link className="animate pointer font13" onClick={()=>{setOnTeamPage(x=>!x)}} smooth={true} offset={-80}>
              About Us
            </Link>
            <Link className="animate pointer font13" onClick={()=>{setShowModal(true)}} smooth={true} offset={-80}>
              Contact Us
            </Link>
            <Link className="animate pointer font13" onClick={()=>{setShowPolicy(true)}} smooth={true} offset={-80}>
              Terms of Service
            </Link>
            <Link className="animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;