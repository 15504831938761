import React, { useState } from 'react';
import Atomics from "./atomicInputs";
import DescriptionBlock from "./description";
import Button from "@mui/material/Button";

const generalVarDataFn = (nodes, edges, currentlySelectedNode) => {
    let loopArray = [{ none: "none" }];
    let usableVariables = [{ none: "none" }]
    let limitVariables = [{ none: "none" }]
    if (currentlySelectedNode.admissible && currentlySelectedNode.admissible.length > 0) {
        loopArray = currentlySelectedNode.admissible.map((admissible) => ({ value: admissible.id, label: admissible.data.store.general.nodeName }))
    }
    if (currentlySelectedNode.usableVariables && currentlySelectedNode.usableVariables.length > 0) {
        usableVariables = currentlySelectedNode.usableVariables.map((usable) => ({ value: usable.nodeId, label: usable.label }))
    }
    if (currentlySelectedNode.limitVariables && currentlySelectedNode.limitVariables.length > 0) {
        usableVariables = currentlySelectedNode.usableVariables.map((usable) => ({ value: usable.nodeId, label: usable.label }))
    }
    return [
        {
            label: 'Node Name:',
            varLabel: 'nodeName',
            type: "textInput",
            details: "Give each node a unique name.",
            display: (store, datum) => store[datum.varLabel],
        },
        {
            label: 'Input Header Text:',
            varLabel: 'inputHeaderText',
            type: "textInput",
            details: "This text will be displayed at the top of client-side form.",
            display: (store, datum) => store[datum.varLabel],
        },
        {
            label: 'Description Label:',
            varLabel: 'descriptionLabel',
            type: "textInput",
            details: "This text will be displayed below the header text.",
            display: (store, datum) => store[datum.varLabel],
        },
        {
            label: 'Loop',
            varLabel: 'loop',
            special: true,
            type: "checkbox",
            details: "Would you like your process to return to an earlier state a fixed number of times? ",
            display: (store, datum) => store[datum.varLabel],
        },
        {
            label: 'Loop Node:',
            varLabel: 'loopNode',
            special: true,
            type: "select",
            details: "Loop back to this node:",
            display: (store, datum) => currentlySelectedNode.loopNode ? currentlySelectedNode.loopNode : loopArray[0].value,
            array: loopArray
        },
        {
            label: 'Loop Variable:',
            varLabel: 'loopVariable',
            special: true,
            type: "select",
            details: "Use loop variable:",
            display: (store, datum) => currentlySelectedNode.loopVariable ? currentlySelectedNode.loopVariable : usableVariables[0].value,
            array: usableVariables
        },
        {
            label: 'Returnable',
            varLabel: 'loop',
            special: true,
            type: "checkbox",
            details: "This process may be returned to later.",
            display: (store, datum) => store[datum.varLabel],
        },
        {
            label: 'Directional Switch',
            varLabel: 'switch',
            special: true,
            type: "checkbox",
            details: "Do you need an input containing a directional switch? ",
            display: (store, datum) => store[datum.varLabel],
        },
        {
            label: 'KEYVALUETEST',
            varLabel: 'switch',
            special: true,
            type: "keyValue",
            details: "KEYVALUE TEST ",
            display: (store, datum) => store[datum.varLabel],
        }
    ]
}

const GeneralEditor = ({ userInputs, general, selectedNode, mutateStandardNodeData, currentlySelectedNode, configureSpecialProperties, nodes, edges, gptPrompts, ...other }) => {
    //Functions for modifying the standard fields
    const generalVarData = generalVarDataFn(nodes, edges, currentlySelectedNode);
    const [generals, setGenerals] = useState(general);
    const handleSettingChange = (arg) => (index, key, newData) => {
        const newGenerals = { ...generals };
        const associatedDatum = generalVarData.filter((datum) => datum.varLabel === arg)[0]
        if (associatedDatum.special) {
            configureSpecialProperties(selectedNode, arg, newData)
        }
        newGenerals[key] = newData;
        setGenerals(newGenerals);
        mutateStandardNodeData(selectedNode, "general", newGenerals)
    };
    return (
        <div>
            <DescriptionBlock title={"General"}>
                These are general paremeters that control the page displayed to the user. At a future stage of
                development, you will be able to preview the form exposed to the user.
                <br />
            </DescriptionBlock>
            <div style={{ marginTop: "15px" }}>
                <div style={{ backgroundColor: "#1a1f22", padding: "10px" }}>
                    {generalVarData.map((datum) => <Atomics
                        handleChange={handleSettingChange(datum.varLabel)}
                        datum={datum}
                        type={datum.type}
                        store={generals}
                        idx={0}
                    />)}
                </div>
            </div>
        </div>
    );
};

export default GeneralEditor;
