import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useTheme, Box, Checkbox, Button, FormControlLabel, Select, Tab, Tabs, TextField, Typography, MenuItem, FormControl } from '@mui/material';
import { Logo } from '../../logoComponent';
import FirmSignUpContext from '../../../contexts/firm/context';
import APIContext from '../../../contexts/api/context';

function ApplicationForm({ onSubmit, setShowFirmSignUp }) {
    const {api} = useContext(APIContext)
    const [submitted, setSubmitted] = useState(false);
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        image: null, // Assuming image as a file
        usptoRegistrationNumber: '',
        experienceSummary: '',
        field: '',
        yearsOfExperience: '',
        collectiveExperience: '',
        samplePatent: null
    });
    const [checked, setChecked] = React.useState({});

    const handleCheckboxChange = (event) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };

    const submitFormData = () => {
        const formData2 = new FormData();
        formData2.append('file', file);
        formData2.append('data', JSON.stringify({ ...formData, ...checked }));


        api('/ptntst/sendPatentLawyerData', formData2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setSubmitted(true);
        })
    }
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'image' ? files[0] : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitFormData();
        onSubmit(formData); // Call the passed onSubmit function with the form data
        // setSubmitted(true);
    };
    const inputRef = useRef();
    if (submitted) {
        return (
            <Typography variant="h5">Thank you for your interest in Patentist services and the Patentist Network. We are in the process of evaluating your application and you will hear back from our team within 24 hours. Stay tuned!</Typography>
        );
    }


    const handleClick = () => {
        inputRef.current.click();
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    const options = [
        'Computers, Software, Digital Communications',
        'Electrical & Electronics',
        'Mechanical',
        'Chemical',
        'Bio/Pharma, Drugs, Medical',
    ]
    return (
        <>
            <Typography variant="h5">Law firms and practitioners, join the Patentist Network!</Typography>
            <Typography variant="body1" style={{ marginTop: "20px" }} >Inventors realize that a patent draft is a first step toward designing a holistic IP strategy. Our smart matching algorithm partners legal professionals from the Patentist Network with inventors and innovators according to criteria such as field of patent litigation, patent type etc.</Typography>
            <Typography variant="body1" style={{ marginTop: "20px" }} >
            Of course, our network members also get access to Patentist services such as AI-assisted patent draft and figure generation, automated Office Action module, and our soon-to-launch Litigation Assistant.</Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal">
                    <TextField label="Name" name="name" variant="outlined" required onChange={handleChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="USPTO Registration Number" name="usptoRegistrationNumber" inputProps={{ min: "0", max: "100000000000000000", step: "1" }} type="number" variant="outlined" required onChange={handleChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Summarize Your Experience" name="experienceSummary" variant="outlined" multiline rows={4} required onChange={handleChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Years of Experience (You)" name="yearsOfExperience" inputProps={{ min: "0", max: "70", step: "1" }} type="number" variant="outlined" required onChange={handleChange} />
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <TextField label="Years of Experience (Collective)" inputProps={{ min: "0", max: "999", step: "1" }} name="collectiveExperience" type="number" variant="outlined" required onChange={handleChange} />
                </FormControl>
                <Typography variant="h6" style={{ marginTop: "5px", marginBottom: "5px" }} >Select all IP fields handled:</Typography>
                <FormControl fullWidth margin="normal">
                    {options.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={checked[option] || false}
                                    onChange={handleCheckboxChange}
                                    name={option}
                                />
                            }
                            label={option}
                        />
                    ))}
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <input
                        type="file"
                        required
                        name="samplePatent"
                        ref={inputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <Button variant="contained" color="primary" onClick={handleClick}>
                        Upload Sample Patent
                    </Button>
                    {file && <p style={{ marginTop: "10px" }}>File uploaded: {file.name}</p>}
                </FormControl>
                <Box mt={2} style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-evenly", }}>
                    <Button type="submit" maxWidth variant="contained" color="primary">Submit</Button>
                    <Button onClick={() => setShowFirmSignUp(false)} maxWidth variant="contained" color="primary">Close</Button>
                </Box>
            </form>
        </>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FirmSignUpModal = ({ open }) => {
    const {api} = useContext(APIContext)
    const theme = useTheme();
    const { showFirmSignUp, setShowFirmSignUp, showInvitationModal, setShowInvitationModal, reloadFirmData,pendingFirmInviteBy } = useContext(FirmSignUpContext);
    const [value, setValue] = useState(0);
    const [termsOfService, setTermsOfService] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [cookiePolicy, setCookiePolicy] = useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        axios.get('/tos.md')
            .then(response => {
                setTermsOfService(response.data.replaceAll("[Patentist]", "Patentist"))
            })
            .catch(error => console.error(error));
        axios.get('/cookie.md')
            .then(response => setCookiePolicy(response.data.replaceAll("[Patentist]", "Patentist")))
            .catch(error => console.error(error));
        axios.get('/privacy.md')
            .then(response => setPrivacyPolicy(response.data.replaceAll("[Patentist]", "Patentist")))
            .catch(error => console.error(error));
    }, []);

    const handleInvitation = (status) => {
        api('/ptntst/verifyFirmInvite', { firmEmail: pendingFirmInviteBy, status: status }).then(response => {
            setShowInvitationModal(false)
            reloadFirmData()
        })
    }
    return (
        <>
            <Modal
                open={showFirmSignUp}
                onClose={() => setShowFirmSignUp(false)}
                aria-labelledby="terms-of-service-modal-title"
                aria-describedby="terms-of-service-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    maxWidth: "101%",
                    maxHeight: "101%",
                    height: "700px",
                    width: "700px",
                    overflowY: 'scroll',
                    bgcolor: 'background.paper',
                    borderRadius: "25px",
                    boxShadow: 24,
                }}>
                    <div style={{ position: 'sticky', top: '0', zIndex: 100000, borderBottomRightRadius: "25px", height: "60px", display: "flex", padding: "10px", width: "350px", backgroundColor: theme.palette.backgroundSecondary.default }}>
                        <div style={{ color: theme.palette.backgroundSecondary.contrastText }}>
                            <Logo color="#e6e6e6" format="header" />
                        </div>
                        <Box sx={{ color: 'primary.children', }} style={{ marginLeft: "10px" }}>
                            <Tabs textColor="inherit" value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="For Firms" style={{ color: "white" }} {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                    </div>
                    {/* <div style={{ display: 'flex', padding: "20px", flexDirection: 'column', backgroundColor: theme.palette.background.default, height: '80vh', maxWidth: "1000px", overflowY: "scroll" }}> */}
                    <Box sx={{ p: 4 }}>
                        <ApplicationForm setShowFirmSignUp={setShowFirmSignUp} onSubmit={(data) => console.log(data)} />
                    </Box>
                    {/* </div> */}
                </Box>
            </Modal>

            <Modal
                open={showInvitationModal}
                onClose={() => { setShowInvitationModal(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Confirm firm invitation?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {pendingFirmInviteBy} would like to add you to their firm account. Do you wish to accept?
                    </Typography>
                    <Button variant="outlined" sx={{ mt: 3 }} onClick={() => { handleInvitation(true) }}>Confirm</Button>

                    <Button variant="outlined" sx={{ mt: 3 }} onClick={() => { handleInvitation(false) }}>Deny</Button>
                </Box>
            </Modal>
        </>
    );
};

export default FirmSignUpModal;