import React, { useState, createContext, useContext } from "react";
import {
    Modal, Button, Typography, Box,
} from '@mui/material';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ErrorModalContext = createContext();

const useErrorModal = () => {
    const useErrorModal = useContext(ErrorModalContext);
    return useErrorModal

}

function ErrorModal(props) {
    const [errorString, setErrorString] = useState("");
    const [errorCode, setErrorCode] = useState(0);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const handleClose = () => {
        setErrorString("")
        setErrorCode(0)
        setErrorModalOpen(false)
    }
    const showError = (string, code = 0) => {
        setErrorString(string)
        setErrorCode(code)
        setErrorModalOpen(true)
    }
    return (
        <ErrorModalContext.Provider value={showError}>
            <Modal
                open={errorModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Error
                    </Typography>
                    {errorCode !== 0 ? <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Code: {errorCode}
                    </Typography> : null}
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {errorString}
                    </Typography>
                    <Button variant="outlined" sx={{ mt: 3 }} onClick={handleClose}>Close</Button>
                </Box>
            </Modal>
            {props.children}
        </ErrorModalContext.Provider>
    )
}

export { ErrorModal, useErrorModal }