import React, { useEffect, useRef, useState, useContext } from 'react';
import SequenceHandler from '.';
import {
    useMediaQuery,
    useTheme,
    Box,
    CircularProgress
} from '@mui/material';
import PaymentPage from './payment';
import PolicyContext from '../contexts/policy/context';
import StartPage from './startpage';
import APIContext from '../contexts/api/context';

function ClientWrapper(props) {
    const {api} = useContext(APIContext)
    const [node, setNode] = useState(null)
    const [paid, setPaid] = useState(false)
    const { setShowPolicy } = useContext(PolicyContext);
    const [editMode, setEditMode] = useState(false)
    const [initMode, setInitMode] = useState(false)
    const [ver, setVer] = useState(0)
    const [initVars, setInitVars] = useState(null)
    const [nodeId, setNodeId] = useState(null)
    const [activePatent, setActivePatent] = useState(false)
    const [patentName, setPatentName] = useState("Untitled")
    const activePatentRef = useRef(null)
    const nodeIdRef = useRef(null)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    activePatentRef.current = activePatent
    nodeIdRef.current = nodeId

    const downloadFiguresWord = () => {
        api('/ptntst/getCurrentNode', { patentId: props.patentId }).then(res => { })
        api('/ptntst/downloadFiguresWord', { patentId: props.patentId },
            { responseType: 'blob', headers: {} }).then(res => {
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'figures.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }

    const downloadPatentWord = () => {
        api('/ptntst/getCurrentNode', { patentId: props.patentId }).then(res => { })
        api('/ptntst/downloadPatentWord', { patentId: props.patentId },
            { responseType: 'blob', headers: {} }).then(res => {
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);
                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'patent.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            });
    }

    const advanceProcess = (paid, pVars, node) => {
        setPaid(paid)
        setInitVars(pVars)
        setNode(node)
        setNodeId(node.id)
    }

    const forceVerUpdate = (followUp, node, variables) => {
        api('/ptntst/getCurrentNode', { patentId: props.patentId }).then(res => {
            api('/ptntst/getPatent', { patentId: props.patentId }).then(res => {
                const patent = res.data.patent
                setPaid(!!patent.paid)
            })
            setVer((old) => old + 1)
            setNode(node)
            setNodeId(node.id)
            followUp()

        })
    }
    const getPatent = () => {
        api('/ptntst/getPatent', { patentId: props.patentId }).then(res => {
            const patent = res.data.patent
            setActivePatent(patent);
            setPatentName(patent.name);

            if (patent.name === "Untitled") {
                setInitMode(true)
            } else {
                setInitMode(false)
                setEditMode(true)
                api('/ptntst/getCurrentNode', { patentId: props.patentId }).then(res => {
                    // setInitVars(res.data.variables)
                    // setNode(res.data.currentNode)
                    setVer((old) => old + 1)
                    advanceProcess(!!patent.paid, res.data.variables, res.data.currentNode)

                })
            }
        })
    }
    useEffect(() => {
        setTimeout(getPatent, 200)
        const intervalId = setInterval(() => {
            api('/ptntst/getPatentStage', { patentId: props.patentId }).then(response => {
                if (nodeIdRef.current !== response.data.nodeId) {
                    getPatent()
                }
            })
                .catch(error => {
                });
        }, 10000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [])


    const atDepClaims = node?.data?.store?.general?.nodeName === "depClaims"
    if (atDepClaims && !!!paid) {
        return <PaymentPage setOpenPage={props.setOpenPage} patentId={props.patentId} />
    }

    if (editMode && node) {
        return (
            <div style={{ width: "100%" }}>
                {/* <div style={{ transform: 'scale(.9)', border: "solid 1px white" }}> */}
                <SequenceHandler
                    drawerOpen={props.drawerOpen}
                    parentVer={ver}
                    paid={paid}
                    downloadPatentWord={downloadPatentWord}
                    downloadFiguresWord={downloadFiguresWord}
                    setSidebarUsable={props.setSidebarUsable}
                    patentId={props.patentId}
                    toggleDrawer={props.toggleDrawer}
                    variables={initVars}
                    getPatent={getPatent}
                    forceVerUpdate={forceVerUpdate}
                    currentlySelectedNode={node}
                    setNode={setNode}
                    freshSequence={true}
                    directive={"patentCreation"} />
                {/* </div> */}
            </div>
        );
    } else {
        if (initMode) {
            return (<StartPage
                getPatent={getPatent}
                patentName={patentName}
                patentId={props.patentId}
                setRefreshSidebar={props.setRefreshSidebar}
                setShowPolicy={setShowPolicy}
                isMobile={isMobile}
                setPatentName={setPatentName} />)
        } else {
            if (activePatent.completed) {

            } else {
                return <div style={{ height: "100%", width: "100%" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "rgb(0,0,0,0)", alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                </div>
            }
        }
    }
}

export default ClientWrapper;