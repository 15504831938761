import React, { useState, useEffect, useContext } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { SiteGate, lastButtonStyle } from "../sitegate";
import { useNavigate } from "react-router-dom";
import FirmSignUpContext from "../../contexts/firm/context";
import { ALL_COPY } from "../../constants/copy";
import APIContext from "../../contexts/api/context";

export const FirmStatus = () => {
    const [error, setError] = useState(null)
    const [clickedOnce, setClickedOnce] = useState(false)
    const { showFirmSignUp, setShowFirmSignUp } = useContext(FirmSignUpContext)
    const navigate = useNavigate();
    
    useEffect(() => {
        if ((!showFirmSignUp && clickedOnce)) {
            navigate("/main")
        }
    }, [clickedOnce, showFirmSignUp])

    const handleSubmit = (event) => {
        setShowFirmSignUp(true)
        setClickedOnce(true)
    };

    return (
        <SiteGate
            title="Onboarding"
            error={error}
            buttons={
                <>
                    <Typography variant="h6" style={{ marginBottom: "15px" }}> {ALL_COPY.firmStatusHeader}</Typography>

                    <Typography variant="body2" style={{ margin: "15px" }}> {ALL_COPY.firmStatus1}</Typography>
                    
                    <Typography variant="body2" style={{ margin: "15px" }}> {ALL_COPY.firmStatus2}</Typography>
                 
                    
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={lastButtonStyle}>
                        Yes
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => { navigate("/main") }}
                        style={lastButtonStyle}>
                        No
                    </Button>
                </>


            }>
        </SiteGate>
    )
}
