import React, { useContext, useState, useEffect } from 'react';
import APIContext from '../api/context.js';
import PatentProcessContext from './context.js';
import LandingContext from '../landing/context.js';
import ClearContext from '../figureDesignClearContext/context.js';
import { auth } from '../../auth/auth.js';
import { useAPIDialog } from '../api/hooks.js';

const PatentProcessContextProvider = ({ children }) => {
    const { api } = useContext(APIContext)
    const { manager } = useContext(LandingContext)
    const { clearVer, setClearVer } = useContext(ClearContext);
    const [activePatent, setActivePatent] = useState(null);
    const [refresh, setRefresh] = useState(0)
    const [patentList, setPatentList] = useState([])

    const editPatent = (patentId) => {
        setActivePatent(patentId)
        setClearVer(v => v + 1);
        manager.setOpenPage("client")
    }

    const addNewPatent = useAPIDialog(
        "Would you like to create a new patent draft appplication?",
        'Create Patent Draft',
        '/ptntst/createPatent',
        {},
        (res) => {
            setRefresh(refresh => (refresh + 1))
            editPatent(res.data.patentId)
        }, 'patent', 'addNewPatent',true)



    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const patentId = urlParams.get('patent_id');
        if (patentId) {
            editPatent(patentId);
        }
    }, []);

    useEffect(() => {
        if (refresh > 0
            && auth?.currentUser
        ) {
            api('/ptntst/getPatents', {}).then(res => {
                setPatentList(res.data.patentList)
            })
        }
        else {
            setTimeout(() => { setRefresh(refresh => (refresh + 1)) }, 1500)
        }
    }, [refresh, manager.refreshSidebar])

    useEffect(() => {
        setRefresh(refresh => (refresh + 1))
    }, [])


    return (
        <PatentProcessContext.Provider value={{
            patentManager: {
                activePatent,
                setActivePatent,
                editPatent,
                addNewPatent,
                patentList,
                setPatentList,
                addNewPatent,
            }
        }}>
            {children}
        </PatentProcessContext.Provider>
    );
}

export default PatentProcessContextProvider