import '../App.css';
import React from "react";
import {
    Typography,
} from '@mui/material';
import { SvgLogoComponent } from './svgLogoComponent';
import app_name from '../constants/name.js';

export const Logo = (props) => {

    switch (props.format) {
        case "logo":
            return (
                <div onClick={() => { }}
                    style={props.style}>
                    <div style={{ width: "100%" }}>
                        <SvgLogoComponent color={props.color} />
                    </div>
                </div>
            )
        case 'tagline':
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    <div style={{ width: "100%" }}>
                        <SvgLogoComponent color={props.color} />
                    </div>
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom
                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            // marginLeft: "62px",
                            // width: "60%",
                            marginTop: "-37px"
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                    <Typography variant="p"
                        gutterBottom
                        style={{
                            fontFamily: 'Mono',
                            // letterSpacing: "2.1px",
                            fontSize: "20px",
                            // marginLeft: "62px",
                            // width: "60%",
                            // marginTop: "-37px"
                        }}>
                        AI assisted, human perfected.
                    </Typography>
                </div >
            )
        case 'policy':
            return (
                <div onClick={() => { }}
                    style={{
                        position: 'static',
                        width: '60px',
                        height: '45px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    <div style={{ position: 'absolute', top: '7px', width: "60px", height: "60px" }}>
                        <SvgLogoComponent color={"#FF0000"} noSpace={true} />
                    </div>
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                </div >
            )
        case 'header':
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    <div style={{ width: "80px", height: "80px" }} onClick={() => { window.location = "./" }}>
                        <SvgLogoComponent color={"#FF0000"} noSpace={true} />
                    </div>
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom

                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            margin: '0px',
                            marginLeft: "25px",
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                </div >
            )
        case 'main_logo':
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    <div style={{ width: "800px", height: "800px" }}>
                        <SvgLogoComponent color={"#FF0000"} noSpace={true} />
                    </div>
                </div >
            )
        case 'text':
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    {/* <div style={{ width: "25px" }}>
                            <SvgLogoComponent color={props.color} noSpace={true} />
                        </div> */}
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom
                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            margin: '0px',
                            marginLeft: "25px",
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                </div >
            )
        case 'text-alt':
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    {/* <div style={{ width: "25px" }}>
                                <SvgLogoComponent color={props.color} noSpace={true} />
                            </div> */}
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom
                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            margin: '0px',
                            marginTop: "25px",
                            marginLeft: "15px",
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                </div >
            )
        case 'text-alt2':
            return (
                <div
                    style={{
                        // display: 'flex',
                        // flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        height: "100%",
                        ...props.style
                    }}
                >
                    <div style={{ height: "70%" }}>
                        <SvgLogoComponent color={props.color} noSpace={true} />
                    </div>
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <div>
                        <Typography variant="h4"
                            gutterBottom
                            style={{
                                fontFamily: 'Martel Sans',
                                letterSpacing: "2.1px",
                                fontSize: "16px",
                                margin: '0px',
                                // marginTop: "25px",
                                // marginLeft: "15px",
                            }}>
                            {app_name.toUpperCase()}
                        </Typography>
                    </div>
                </div >
            )
        case 'textFooter':
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    {/* <div style={{ width: "25px" }}>
                                <SvgLogoComponent color={props.color} noSpace={true} />
                            </div> */}
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom
                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            margin: '0px',
                            // marginLeft: "25px",
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                </div >
            )
        case "temp":
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    {/* <div style={{ width: "100%" }}>
                        <SvgLogoComponent color={props.color} />
                    </div> */}
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom
                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            // marginLeft: "62px",
                            // width: "60%",
                            // marginTop: "-37px"
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                </div >
            )
        default:
            return (
                <div onClick={() => { }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...props.style
                    }}>
                    <div style={{ width: "100%" }}>
                        <SvgLogoComponent color={props.color} />
                    </div>
                    {/* <img style={{ width: "100%", height: "100%" }} src={"logo/print_transparent.svg"} alt="" /> */}
                    <Typography variant="h4"
                        gutterBottom
                        style={{
                            fontFamily: 'Martel Sans',
                            letterSpacing: "2.1px",
                            fontSize: "22px",
                            // marginLeft: "62px",
                            // width: "60%",
                            marginTop: "-37px"
                        }}>
                        {app_name.toUpperCase()}
                    </Typography>
                </div >
            )
    }

};
