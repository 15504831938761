import ReactMarkdown from 'react-markdown';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@mui/material';


export const MarkdownTable = ({ markdown }) => {
    const renderers = {
        p: ({node, ...props}) => <p style={{ marginBottom: '1em',marginTop: '1em' }} {...props} />,
        pre: ({ ...other }) => {return <div>{other['children']}</div>},
        code: ({ ...other }) => {
            let language = other['className']
            let value = other['children']

            if (language === 'language-table') {
                const rows = value.trim().split('\n');
                const headers = rows[0].split('|').map(header => header.trim()).filter(Boolean);
                const data = rows.slice(1)
                    .filter(row => !/^[-\s|]+$/g.test(row)) // Filter out delimiter rows
                    .map(row => row.split('|').map(cell => cell.trim()).filter(Boolean));
                return (
                    <div style={{maxWidth:'600px'}}><br></br>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header, index) => (
                                            <TableCell key={index} align="left" style={{ fontWeight: 'bold' }}>
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <TableCell key={cellIndex} align="left">
                                                    {cell}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br></br>
                    </div>
                );
            }
            return <div className={language} style={{}}>{value}</div>;
        },

    };
    return <ReactMarkdown components={renderers}>{markdown}</ReactMarkdown>;
}