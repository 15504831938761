import React, { useEffect, useContext, useState } from 'react';
import 'reactflow/dist/style.css';
import {
    Button,
    TextField,
    FormControlLabel,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    Typography,
    Tabs,
    Tab, Modal, CircularProgress,
    Box,
    FormControl,
    Select,
    Accordion, AccordionSummary, AccordionDetails,
    MenuItem,
    InputLabel,
    useTheme
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const Confirmation = ({ text, onClickFn, children }) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleClose = () => {
        setOpenConfirmation(false);
    };
    const childrenWithProps = React.Children.map(children, child => {
        // Check if the child is a valid React element before cloning
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { onClick: ()=>setOpenConfirmation(true) });
        }
        return child;
      });
    return (
        <>
            {childrenWithProps}
            {/* <Button
                variant="contained" onClick={() => setOpenConfirmation(true)}>
                New Figure
            </Button> */}
            <Dialog
                open={openConfirmation}
                onClose={handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{"Confirm Selection"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onClickFn} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Confirmation;