import React, { useState, useEffect, useContext } from 'react';
import WorkflowContext from './context';
import DesContextProvider from '../singleFlow';
import APIContext from '../api/context';
import { useAPIDialog } from '../api/hooks';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const WorkflowContextProvider = ({ children }) => {
    const { api, apiWithDialog, apiForcedRefresh, completeDialogueResponse, dialogResponseActions } = useContext(APIContext)
    const [refresh, setRefresh] = useState(0);
    const [disableNameChange, setDisableNameChange] = useState(false);
    const [activeWorkflow, setActiveWorkflow] = useState(null);
    const [activeWorkflowName, setActiveWorkflowName] = useState(null);
    const debouncedName = useDebounce(activeWorkflowName, 500);
    const [workflows, setWorkflows] = useState([]);

    useEffect(() => { setRefresh(refresh + 1) }, [])
    useEffect(() => {
        api('/ptntst/getWorkflows', {}).then(res => {
            setWorkflows(res.data.flows)
        })
    }, [refresh, apiForcedRefresh]);

    useEffect(() => {
        if (debouncedName && !disableNameChange) {
            // Make your API call here with the debouncedValue
            api('/ptntst/renameWorkflow', { flowId: activeWorkflow, name: debouncedName }).then(res => {
                setRefresh(refresh => refresh + 1)
            })
        }
    }, [debouncedName]);

    const createWorkflow = () => {
        apiWithDialog("Would you like to create a new automation?", "New Automation", {}, '/ptntst/createWorkflow', {}).then(res => {
            setRefresh(refresh => refresh + 1)
        })
    }

    const deleteWorkflow = useAPIDialog(
        "Are you sure you wish to delete this workflow? After you take this action, it cannot be recovered.",
        'Delete Workflow',
        '/ptntst/deleteWorkflow',
        { flowId: activeWorkflow },
        () => {
            setActiveWorkflow(null);
            completeDialogueResponse();
        }, 'workflow', 'deleteFlow',true)

    const experiment = () => {
        api('/ptntst/brokenButton', {}).then(res => { })
    }

    const activateWorkflow = (workflow) => {
        setDisableNameChange(true)
        const handler = setTimeout(() => {
            setDisableNameChange(false)
        }, 1000);
        setActiveWorkflow(workflow)
    }
    const renameWorkflow = (newName) => {
        api('/ptntst/renameWorkflow', { flowId: activeWorkflow, name: newName }).then(res => {
            setRefresh(refresh => refresh + 1)
        })
    }
    const updateWorkflow = (steps,
        stepData,
        testProfiles,
        activeTestProfileId,
        activeTestProfile,
        activeTestProfileMeta,
        workflowVersion,
        model,) => {
        api('/ptntst/updateWorkflow', {
            flowId: activeWorkflow, steps,
            stepData,
            testProfiles,
            activeTestProfileId,
            activeTestProfile,
            activeTestProfileMeta,
            workflowVersion, model
        }).then(res => {
            setRefresh(refresh => refresh + 1)
        })
    }
    return (
        <WorkflowContext.Provider value={{
            manager: { activateWorkflow, deleteWorkflow, createWorkflow, updateWorkflow, renameWorkflow, setActiveWorkflowName, experiment },
            workflows: workflows,
            activeWorkflowName: activeWorkflowName,
            activeWorkflow: activeWorkflow
        }}>
            <DesContextProvider activeWorkflow={activeWorkflow}>
                {children}
            </DesContextProvider>
        </WorkflowContext.Provider>
    );
}

export default WorkflowContextProvider;