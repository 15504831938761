import React, { useState } from 'react';
import Button from "@mui/material/Button";
import Atomics from "./atomicInputs";
import DescriptionBlock from "./description";

const inputVarDataFn = (nodes, edges) => [
    {
        label: 'Variable Name:',
        varLabel: 'label',
        type: "textInput",
        details: "Give a variable name for the data collected by this input. You may inject this variable into a later LLM prompt.",
        display: (store, datum) => store[datum.varLabel],
    },
    {
        label: 'Client Label:',
        varLabel: 'description',
        type: "textArea",
        details: "Give a description for the data collected by this input. This text will be displayed in the client-side label. ",
        display: (store, datum) => store[datum.varLabel],
    },
    {
        label: 'Sample:',
        varLabel: 'sample',
        type: "textArea",
        details: "Provide sample data for process testing and refinement. ",
        display: (store, datum) => store[datum.varLabel],
    },
    {
        label: 'Input Type',
        varLabel: 'type',
        type: "select",
        details: "What type of input is this?. ",
        display: (store, datum) => store[datum.varLabel],
        array: [
            { value: "select", label: "Select" },
            { value: "textInput", label: "Text Input" },
            { value: "textArea", label: "Text Area" }
        ]
    },
    {
        label: 'Date Type',
        varLabel: 'dataType',
        type: "select",
        details: "What type of input is this?. ",
        display: (store, datum) => store[datum.varLabel],
        array: [
            { value: "text", label: "Text" },
            { value: "diagram", label: "Diagram" },
            { value: "integer", label: "Integer" }
        ]
    }
]

const defaultSettings = () => ({ label: '', description: '', sample: '', type: 'select' })
const InputEditor = ({ userInputs, selectedNode, mutateStandardNodeData, configureSpecialProperties, nodes, edges, gptPrompts, ...other }) => {
    //Functions for modifying the standard fields
    const inputVarData = inputVarDataFn(nodes, edges);
    const [inputs, setInputs] = useState(userInputs);

    const handleSettingChange = (arg) => (index, key, newData) => {
        const newInputs = [...inputs];
        newInputs[index][arg] = newData;
        setInputs(newInputs);
        mutateStandardNodeData(selectedNode, "userInputs", newInputs)
    };
    const handleDelete = (index) => {
        const newInputs = inputs.filter((_, i) => i !== index);
        setInputs(newInputs);
        mutateStandardNodeData(selectedNode, "userInputs", newInputs)
    };

    const handleAdd = () => {
        const newInputs = [...inputs, defaultSettings()]
        setInputs(newInputs);
        mutateStandardNodeData(selectedNode, "userInputs", newInputs)
    };
    return (
        <div>
            <div style={{ paddingBottom: "20px" }}>
                <DescriptionBlock title={"User Inputs"}>
                    During each stage of the GPT process, you have the option to collect several user inputs. These user
                    inputs may be used as variables in GPT prompts from this point forward in the process. Try to use
                    camel-case variable names (eg, 'topicDescription') with no spaces. You may also supply a sample of
                    the kind of data a user would supply for testing purposes.
                    <br />
                    <Button variant="contained"
                        style={{ marginTop: "10px" }}
                        color="primary"
                        onClick={handleAdd}>Add New Input</Button>
                </DescriptionBlock>
            </div>
            {inputs.map((input, index) => (
                <div key={"inputEditorOuterIdx_" + index} style={{ marginTop: "15px" }}>
                    <div style={{ backgroundColor: "#1a1f22", padding: "15px", borderRadius: "15px" }}>
                        {inputVarData.map((datum, innerIdx) =>

                            <div key={"inputEditorOuterInner_" + index + "_" + innerIdx}><Atomics
                                handleChange={handleSettingChange(datum.varLabel)}
                                datum={datum}
                                type={datum.type}
                                store={input}
                                idx={index}
                            /></div>)}
                        <Button variant="contained"
                            style={{ marginTop: "10px" }}
                            color="primary"
                            onClick={() => handleDelete(index)}>Delete</Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InputEditor;
