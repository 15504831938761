import React, { useEffect, useState } from "react";
import {
    Box,
    Modal,
    Typography,
    Button,
    TextField,
    useTheme
} from '@mui/material';

const EditableModal = ({ enabled, varText, submitEdits, editDisplayTxt }) => {
    const [text, setText] = useState(varText);
    const theme = useTheme();
    useEffect(() => { setText(varText) }, [varText]);

    const handleSubmit = () => {
        submitEdits(text);
        setTimeout(() => { setText('') }, 100)
        // setText('');
    };
    return (
        <Modal
            open={enabled}
            onClose={() => setText('')}
            aria-labelledby="editable-modal-title"
            aria-describedby="editable-modal-description"
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.background.default,
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                padding: '0px 15px',
            }}>
                <Typography variant="h5" gutterBottom style={{ marginLeft: "20px", marginTop: "50px", marginBottom: "20px" }}>
                    Editing - {editDisplayTxt}
                </Typography>

                <TextField
                    id="editable-modal-textfield"
                    label="Edit field"
                    style={{
                        width: "100%",
                        maxWidth: "700px",
                        backgroundColor: theme.palette.backgroundSecondary.sidebar_two,
                    }}
                    multiline
                    rows={15}
                    size="large"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <span><Button variant="outlined" style={{ margin: "10px" }} color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
                    <Button variant="outlined" style={{ margin: "10px" }} color="primary" onClick={handleSubmit}>
                        Cancel
                    </Button>
                </span>
            </Box>
        </Modal>
    );
};

export default EditableModal;