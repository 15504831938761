import React, { useEffect, useState,useContext } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import { Logo } from "../../../components/logoComponent";
import { useAuthState } from "react-firebase-hooks/auth";
import { authFb } from "../../../auth/auth";
import MenuIcon from '@mui/icons-material/Menu';
import app_name from "../../../constants/name";
import { useTheme } from "@mui/material";
import ContactContext from "../../../contexts/contact/context";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from "@mui/material";

export default function TopNavbar({ teamPage,setOnTeamPage }) {
  const user = useAuthState(authFb)[0];
  const [y, setY] = useState(window.scrollY);
  const {showModal,setShowModal} = useContext(ContactContext);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  if(teamPage){
    return(
      <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate navBackgroundAlt" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <Logo color={theme.palette.text.primary} format="header" />
          </Link>
          <span className="semiBold font15 pointer">
              <IconButton edge="end" aria-label="delete" onClick={()=>{setOnTeamPage(false)}}>
                                <ArrowBackIcon />
                            </IconButton>
            </span>
           
          </NavInner>
      </Wrapper>
    </>
    )
  }
  
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} teamPage={teamPage} setOnTeamPage={setOnTeamPage} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper className="flexCenter animate navBackgroundAlt" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <Logo color={theme.palette.text.primary} format="header" />
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
          <MenuIcon />
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                Home
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="Automatons" spy={true} smooth={true} offset={-80}>
                Services
              </Link>
            </li>
            <li className="semiBold font15 pointer">
              <Link activeClass="active" style={{ padding: "10px 15px" }} to="team"  onClick={()=>setOnTeamPage(x=>!x)} spy={true} smooth={true} offset={-80}>
                Team
              </Link>
            </li>
          </UlWrapper>
          {!!!user ? <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer">
              <a href="/auth/login" style={{ padding: "10px 30px 10px 0" }}>
                Log in
              </a>
            </li>
            <li className="semiBold font15 pointer flexCenter">
              <a onClick={()=>{setShowModal(true)}} className="radius8 lightBg altText" style={{ padding: "10px 15px" }}>
                Contact Us
              </a>
            </li>
          </UlWrapperRight> :
            <UlWrapperRight className="flexNullCenter">
              <li className="semiBold font15 pointer flexCenter">
                <a href="/main" className="radius8 lightBg altText" style={{ padding: "10px 15px" }}>
                  View Drafts
                </a>
              </li>
            </UlWrapperRight>
          }
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  color: #25A1B0;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;


