import { useState } from "react";
import LexFormSelect from "../../Lex/LexFormSelect";
import LexTextField from "../../Lex/LexTextField";
import LexTypography from "../../Lex/LexTypography";
import { InputAdornment } from "@mui/material";
import { Divider } from "@mui/material";

const outerStyle = { marginTop: "0px" /*"30px 0px" */ }

const SubcollectorField = ({ selectionField, fieldData }) => {
    switch (selectionField.targetName) {
        case "inputTypeSelector":
            return <LexFormSelect
                onChange={selectionField.onChange}
                selectValue={selectionField.selectValue}
                inputLabel={selectionField.inputLabel}
                menuText={selectionField.menuText}
                selectionOptions={selectionField.selectionOptions}
                formatMenuItem={selectionField.formatMenuItem}
                defaultStyle={{
                    outerForm: { variant: "filled", style: { width: "300px" } },
                    innerSelect: { width: "100%" }
                }} />
        case "varName":
            return <LexTextField
                id="filled-basic"
                label={selectionField.inputLabel}
                value={selectionField.value}
                onChange={selectionField.onChange}
                variant="filled"
                InputProps={{
                    startAdornment: <InputAdornment position="start" sx={{ marginRight: '0' }}>$</InputAdornment>
                }}
                helperText={selectionField.helperText}
                fullWidth />
        case "selectOptions":
            if (fieldData.type === "Select") {
                return <LexTextField
                    id="filled-basic"
                    label={selectionField.inputLabel}
                    value={fieldData.selectOptions}
                    onChange={selectionField.onChange}
                    variant="filled"
                    helperText={selectionField.helperText}
                    fullWidth />
            } else break;
        case "uncheckedOutputText":
        case "checkedOutputText":
            if (fieldData.type !== "Checkbox") break;
        default:
            return <LexTextField
                id="filled-basic"
                label={selectionField.inputLabel}
                value={selectionField.value}
                onChange={selectionField.onChange}
                variant="filled"
                helperText={selectionField.helperText}
                fullWidth />
    }
}

const SubcollectorMutator = ({ fieldData, selectionFields, rowPattern }) => {
    return rowPattern.map(row =>
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
        }}>
            {row.map((e, i) =>
                <div style={{
                    width: selectionFields[e].isTypeSelector ? 'fit-content' : '100%',
                    paddingLeft: (i > 0) ? '10px' : '0'
                }}>
                    <SubcollectorField
                        selectionField={selectionFields[e]}
                        fieldData={fieldData} />
                </div>
            )}
        </div>)
}

const insertAtIndex = (addme, i, rows) => rows[i].unshift(addme) && rows;

const Subcollector = ({
    selector,
    selectionFields,
    titleText,
    fieldData,
    descriptionText,
    rowPattern = [[0], [1], [2], [3, 4]] }) => {

    return (
        <div style={outerStyle}>

            {/* <div style={{ paddingBottom: "20px" }}>
                <LexTypography variant="h6">{titleText}</LexTypography>
            </div>

            <div style={{ paddingBottom: "20px" }}>
                <LexTypography variant="body1">{descriptionText}</LexTypography>
            </div> */}

            <SubcollectorMutator
                fieldData={fieldData}
                selectionFields={[...selectionFields, { ...selector, targetName: "inputTypeSelector" }]}
                rowPattern={insertAtIndex(selectionFields.length, 0, structuredClone(rowPattern))} />
        </div>
    )
};

export default Subcollector;