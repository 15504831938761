export const quickInput = (
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
    multiline = null,
    rows = null,
    useHighlights = false,
    keyObject= {}
) => {
    return {
        targetName: target,
        inputLabel: inputLabel,
        value: stepData?.[target],
        multiline: multiline,
        rows: rows,
        onChange: (e) => {
            configureStep.set({
                [target]: e.target.value,
            })
        },
        helperText: helperText,
        useHighlights:useHighlights,
        keyObject: keyObject
    }
}

export const quickTestInput = (
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
    multiline = null,
    rows = null,
    useHighlights = false,
    keyObject= {},
) => {
    return {
        targetName: target,
        inputLabel: inputLabel,
        value: keyObject?.[target],
        multiline: multiline,
        rows: rows,
        onChange: (e) => {
            configureStep.setTest({
                [target]: e.target.value,
            })
        },
        helperText: helperText,
        useHighlights:useHighlights,
        keyObject: keyObject
    }
}


export const quickCheck = (
    stepData,
    configureStep,
    inputLabel,
    target,
    helperText,
) => {
    return {
        targetName: target,
        label: inputLabel,
        value: stepData?.[target],
        onChange: (e) => {
            configureStep.set({
                [target]: e.target.checked,
            })
        },
        helperText: helperText
    }
}

export const subcollectorQuickInput = (
    stepData, configureStep, conf,
    index, fieldData,
    inputLabel, target, helperText
) => ({
    inputLabel: inputLabel,
    targetName: target,
    onChange: conf(configureStep, stepData, index, target),
    value: fieldData?.[target],
    helperText: helperText
})