import React, { useState} from 'react';
import ClearContext from './context';   

const ClearProvider = ({ children }) => {
    const [clearVer, setClearVer] = useState(0);
    
    return (
        <ClearContext.Provider value={{
            clearVer, setClearVer
        }}>
            {children}
        </ClearContext.Provider>
    );
}

export default ClearProvider;