const DiagonalSplitter = ({ width, height, outer="black",middle='#25A1B0',inner="white" }) => {
    return (
        <div style={{
            position: "absolute",
            top:0,
            left:0,
            width: `${width}px`, // Adjust the size as needed
            height: `${height}px`, // Adjust the size as needed
            overflow: "hidden"
        }}>
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: outer,
                clipPath: "polygon(0 0, 100% 0, 0 100%)"
            }}>

<div style={{
                width: "65%",
                height: "65%",
                backgroundColor: middle,
                clipPath: "polygon(0 0, 100% 0, 0 100%)"
            }}>


<div style={{
                width: "50%",
                height: "50%",
                backgroundColor: inner,
                clipPath: "polygon(0 0, 100% 0, 0 100%)"
            }}></div>
            </div>

            </div>
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                clipPath: "polygon(100% 0, 100% 100%, 0 100%)"
            }}></div>
        </div>
    );
};

export default DiagonalSplitter;