import React, { useState } from "react";
import { useTheme } from '@mui/material';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import { Logo } from '../../logoComponent.js';
import AutomationSidebar from './AutomationSidebar/index.js';
import PatentSidebar from "./PatentSidebar/index.js";
import LandingSidebarMenu from "./LandingSidebarMenu/index.js";
import OASidebar from "./OASidebar/index.js";

const LandingSidebar = (props) => {
    const drawerOpen = props.drawerOpen;
    const toggleDrawer = props.toggleDrawer;
    const theme = useTheme();
    const [sidebarType, setSidebarType] = useState(0)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const changeHandler = (e, newValue) => {
        setSidebarType(newValue)
        if (newValue === 1) {
            props.setOpenPage("flow")
        }
    }
    const [expanded, setExpanded] = useState(null)
    const handleChange = (panel) => { 
        console.log(panel)
        setExpanded(old=> old===panel?null:panel); };
    const sidebarContent =( <div style={{
        height: "100vh",
        width: isMobile ? '280px' : "280px",
        minWidth: isMobile ? '280px' : "280px",
        borderRight: "1px solid rgb(127 129 133)",
        backgroundColor: "white",
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.backgroundSecondary.contrastText,
    }}>
        <div style={{
            height: "150px",
            width: "100%",
            backgroundColor: "white",
            color: "black",
            paddingTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>

            {/* <DiagonalSplitter width={50} height={80} /> */}
            <Logo
                setOpenPage={() => { }}
                format="text-alt2"
                style={{ flex: '0 1 fit-content' }} /></div>
        <div style={{ height: "80%", width: "100%", backgroundColor: "white", color: "black", overflowY: "overlay" }}>
            <AutomationSidebar expanded={expanded} handleChange={handleChange} docstyle={{ flex: '1 1 auto', overflow: 'hidden' }} {...props} />
            <PatentSidebar expanded={expanded} handleChange={handleChange} docstyle={{ flex: '1 1 auto', overflow: 'hidden' }} {...props} />
            <OASidebar expanded={expanded} handleChange={handleChange} docstyle={{ flex: '1 1 auto', overflow: 'hidden' }} {...props} />
        </div>
        <LandingSidebarMenu toggleColorMode={props.toggleColorMode} />
        {/* <div style={{ height: "10%", width: "100%", backgroundColor: "#F6F7F5", color: "black" }}>asde</div> */}
    </div>)

    return isMobile ? (
        <SwipeableDrawer
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            {sidebarContent}
        </SwipeableDrawer>
    ) : sidebarContent
}

export default LandingSidebar;