import React from 'react';
import { Typography } from '@mui/material';
import NodeEditor from './NodeEditor'; // Assuming NodeEditor is in the same directory

function NodeEditorWrapper({ previewMode, nodes, edges, selectedNode, currentlySelectedNode, evalIdx, evalState, changeEvalState, configureSpecialProperties, mutateStandardNodeData }) {
    if (previewMode) {
        return null;
    }

    return (
        <div style={{
            height: '100vh', display: "flex",
            flexFlow: "column", width: '35vw', backgroundColor: "#293c45"
        }}>
            <div style={{ overflow: "hidden", height: "30px" }}>
                <Typography
                    variant='body2'
                    style={{ width: "100%", color: "#adadad", paddingLeft: "20px", paddingTop: "5px" }}>
                    Note Editor ({nodes[selectedNode]?.data?.store?.general?.nodeName})
                </Typography>
            </div>
            <div
                style={{
                    width: '35vw',
                    flexGrow: "1", backgroundColor: "#242f36"
                }}>
                {nodes.length > 0 ? <NodeEditor
                    key={selectedNode}
                    evalIdx={evalIdx}
                    nodes={nodes}
                    edges={edges}
                    changeEvalState={changeEvalState}
                    selectedNode={selectedNode}
                    currentlySelectedNode={currentlySelectedNode}
                    evalState={evalState}
                    general={nodes[selectedNode].data.store.general}
                    userInputs={nodes[selectedNode].data.store.userInputs}
                    gptPrompts={nodes[selectedNode].data.store.gptPrompts}
                    mutateStandardNodeData={mutateStandardNodeData}
                    configureSpecialProperties={configureSpecialProperties}
                /> : null}
            </div>
        </div>
    );
}

export default NodeEditorWrapper;