import React, { useRef, useState, useEffect } from 'react';
import { useFrame, Canvas } from '@react-three/fiber';
import { Text } from "@react-three/drei";
function Player({ position, playerRef, velocity, speed }) {
    useFrame((state, delta) => {
        playerRef.current.position.x += velocity.x * delta * speed;
        playerRef.current.position.y += velocity.y * delta * speed;
    });
    return (
        <mesh position={position} ref={playerRef} scale={1}>
            <boxGeometry args={[30, 30, 30]} />
            <meshStandardMaterial color={'orange'} />
        </mesh>
    );
}
function Projectile({ position, velocityData, idData, useSpiral, originPos, timeData, extra }) {
    return (
        <mesh position={position} velocityData={velocityData} idData={idData} useSpiral={useSpiral} originPos={originPos} timeData={timeData} extra={extra}>
            <sphereGeometry args={[10, 10, 10]} /> {/* Adjust size as needed */}
            <meshStandardMaterial color="red" />
        </mesh>
    );
}

function ProjectileManager({ playerRef }) {
    const [projectiles, setProjectiles] = useState([]);
    const projectileRef = useRef(null);
    const cleanRef = useRef(0);
    // Spawn projectiles at intervals
    useEffect(() => {
        const spawnProjectile = () => {
            if (document.visibilityState === 'hidden') { } else {
                let x = Math.random() * 640 - 320
                const newProjectile = {
                    id: Math.random(), // Unique identifier for key prop
                    position: [x, 420, 0], // Random x position, top of the screen
                    velocity: [0, -2, 0],
                    useSpiral: 0,
                    time: 0
                };
                setProjectiles(prev => [...prev, newProjectile]);
            }
        };

        const radialProjectile = (speed, spiral, num) => () => {
            if (document.visibilityState === 'hidden') { } else {
                let newProjectiles = []
                for (let i = 0; i < num; i += 1) {
                    newProjectiles = [...newProjectiles, {
                        id: Math.random(), // Unique identifier for key prop
                        position: [100, 100, 0], // Random x position, top of the screen
                        velocity: [speed * Math.cos(2 * Math.PI / 20 * i), speed * Math.sin(2 * Math.PI / 20 * i), 0],
                        useSpiral: spiral,
                        originPos: [100, 100, 0],
                        extra: {},
                        time: 0
                    }]
                }
                setProjectiles(prev => [...prev, ...newProjectiles]);
            }
        };
        const spawnInterval2 = setInterval(radialProjectile(1, .002, 20), 2000);
        const spawnInterval = setInterval(spawnProjectile, 1000); // Adjust spawn rate as needed
        return () => {
            clearInterval(spawnInterval)
            clearInterval(spawnInterval2)
        };
    }, []);

    // Move projectiles
    useFrame(() => {
        if (document.visibilityState === 'hidden') {

        } else {
            projectileRef.current.children.forEach(proj => {
                proj.position.x += proj.velocityData[0];
                proj.position.y += proj.velocityData[1];
                proj.position.z += proj.velocityData[2]; // Adjust speed as needed
                proj.timeData += 1
                if (proj.useSpiral > 0) {
                    console.log(proj.useSpiral)
                    let xDelta = proj.position.x - proj.originPos[0]
                    let yDelta = proj.position.y - proj.originPos[1]
                    let dist = Math.sqrt(xDelta * xDelta + yDelta * yDelta)
                    xDelta /= dist
                    yDelta /= dist
                    proj.position.x += proj.useSpiral * proj.timeData * yDelta
                    proj.position.y -= proj.useSpiral * proj.timeData * xDelta
                }
                if (playerRef?.current) {
                    if (Math.abs(proj.position.x - playerRef.current.position.x) < 20 && Math.abs(proj.position.y - playerRef.current.position.y) < 20 && playerRef.current.safe <= 0) {
                        playerRef.current.safe = 60
                        playerRef.current.health = playerRef.current.health - 1
                        setProjectiles(prev => prev.filter(otherProj => otherProj.id !== proj.idData));
                    }
                }
            });
            playerRef.current.safe = playerRef.current.safe ? playerRef.current.safe - 1 : -1
            playerRef.current.health = playerRef.current.health ? playerRef.current.health : 10
            if (cleanRef.current > 200) {
                let disposalKeys = []
                projectileRef.current.children.forEach(proj => {
                    if (Math.abs(proj.position.y) > 400 || Math.abs(proj.position.x) > 300) {
                        disposalKeys = [...disposalKeys, proj.idData]
                    }
                })
                setProjectiles(prev => {
                    return prev.filter(proj => !disposalKeys.includes(proj.id))
                });
                cleanRef.current = 0;
            }
            cleanRef.current += 1
        }

    });
    return <group ref={projectileRef}>{projectiles.map(proj => (
        <Projectile key={proj.id} position={proj.position} velocityData={proj.velocity} idData={proj.id} useSpiral={proj.useSpiral} originPos={proj.originPos} timeData={proj.time} extra={proj.extra} />
    ))}</group>
}

function GameInner() {
    const scale = 800; // This controls the zoom
    const aspect = 640 / 800;
    const left = -scale * aspect / 2;
    const right = scale * aspect / 2;
    const top = scale / 2;
    const bottom = -scale / 2;
    const ref = useRef();
    const [velocity, setVelocity] = useState({ x: 0, y: 0 });
    const speed = 230;

    // Update the player's position based on velocity


    // Handle key down and key up events
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                case 'w':
                    setVelocity(v => ({ ...v, y: 1 }));
                    break;
                case 'ArrowDown':
                case 's':
                    setVelocity(v => ({ ...v, y: -1 }));
                    break;
                case 'ArrowLeft':
                case 'a':
                    setVelocity(v => ({ ...v, x: -1 }));
                    break;
                case 'ArrowRight':
                case 'd':
                    setVelocity(v => ({ ...v, x: 1 }));
                    break;
                default:
                    break;
            }
        };

        const handleKeyUp = (event) => {
            switch (event.key) {
                case 'ArrowUp':
                case 'w':
                case 'ArrowDown':
                case 's':
                    setVelocity(v => ({ ...v, y: 0 }));
                    break;
                case 'ArrowLeft':
                case 'a':
                case 'ArrowRight':
                case 'd':
                    setVelocity(v => ({ ...v, x: 0 }));
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return (
        <div style={{ width: '640px', height: '800px', margin: 'auto' }}>
            <Canvas
                orthographic
                camera={{ left, right, top, bottom, near: -100, far: 100, position: [0, 0, 10] }}>
                <ambientLight intensity={0.5} />
                <Text
                    scale={[30, 30, 30]}
                    color="blue" // default
                    anchorX="center" // default
                    anchorY="middle" // default
                >
                    Health: {ref?.current?.health}
                </Text>
                <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                <pointLight position={[-10, -10, -10]} />
                <Player position={[0, 0, 0]} playerRef={ref} velocity={velocity} speed={speed} />
                <ProjectileManager playerRef={ref} />
                {/* Here you can add more game elements like enemies, projectiles, etc. */}
            </Canvas>
        </div>
    );
}

function Game() {

    return <GameInner />
}
export default Game;