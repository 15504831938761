import { useState, useRef, useEffect } from 'react';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import React, { useCallback } from 'react';
import { TextField, styled } from '@mui/material';
import { throttle } from 'lodash';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import zIndex from '@mui/material/styles/zIndex';

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
        zIndex:1000000
    },
});


const StyledDiv = styled('div')(({ theme, rows }) => ({
    width: '500px',
    '& .DraftEditor-root': {
        //   fontFamily: theme.typography.fontFamily,
        //   fontSize: theme.typography.fontSize,
        //   lineHeight: theme.typography.lineHeight,
        //   border: 'none',
        //   background: 'transparent', // Set minimum height based on number of rows
        //   outline: 'none',
        overflow: 'scroll',
        overflowX: 'hidden',
        width: '100%',
        // height: `calc(${rows} * 1.5em)`,
        //   minHeight: `calc(${4} * 1.5em)`, // Set minimum height based on number of rows
        //   maxHeight: '300px', // Optional: Limit max height
    },
    '& .valid-highlight': {
    },
    '& .invalid-highlight': {
        backgroundColor: '#ffd2d2',
    },
}));

const ToolTipPre = ({outerProps,...props}) => {

    const {localValue,showAutocomplete,filteredKeys,setLocalValue,setShowAutocomplete,setFilteredKeys}=outerProps;
    const autocompleteRef = useRef(null);

    const handleSuggestionClick = (suggestion) => {
        const newValue = localValue.replace(/\$[a-zA-Z0-9_]*$/, `$${suggestion} `);
        setLocalValue(newValue);
        setShowAutocomplete(false);
    };

    if(outerProps.key==="step"){
        return (<mark style={{ background:'linear-gradient(#d3ffea, yellow)',position: 'relative', border: '1px solid rgb(88, 142, 173)', borderRadius: "7px", color: 'transparent',zIndex:100 }} className={props.className}>
            {props.children}
            <span style={{ position: 'absolute', paddingLeft: "6px", paddingBottom: "20px", top: -2, left: 0, color: "black", zIndex: 20000, fontSize: ".9em " }}>{outerProps.key}</span>
        </mark> )
    }
    return (
        <CustomWidthTooltip
            title={
                outerProps.keysObject[outerProps.key]
                    ? outerProps.keysObject[outerProps.key].length > 400
                        ? `${outerProps.keysObject[outerProps.key].substring(0, 400)}... (${outerProps.keysObject[outerProps.key].length - 400} characters omitted)`
                        : outerProps.keysObject[outerProps.key]
                    : 'Nothing here. This variable may not be defined above, or you may need to run the prior steps.'
            }
        >
            {outerProps.keysObject[outerProps.key] ? <mark style={{ background:'linear-gradient(#d3ffea, lightblue)',position: 'relative', border: '1px solid rgb(88, 142, 173)', borderRadius: "7px", color: 'transparent',zIndex:100 }} className={props.className}>
                {props.children}
                <span style={{ position: 'absolute', paddingLeft: "6px", paddingBottom: "20px", top: -2, left: 0, color: "black", zIndex: 20000, fontSize: ".9em " }}>{outerProps.key}
                {/* {showAutocomplete && outerProps.key && (
                <ul
                    style={{
                        position: 'relative',
                        top: 10,
                        left: 0,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        zIndex: 1000000,
                        maxHeight: '150px',
                        overflowY: 'auto',
                        listStyleType: 'none',
                        padding: '5px',
                        margin: 0,
                        width: '200px'
                    }}
                >
                    {filteredKeys.map((key) => (
                        <li
                            key={key}
                            onClick={() => handleSuggestionClick(key)}
                            style={{ padding: '5px', cursor: 'pointer' }}
                        >
                            {key}
                        </li>
                    ))}
                </ul>
            )} */}
                </span>
            </mark> : <mark className={props.className}>
                {props.children}
                {/* {showAutocomplete && (
                <ul
                    style={{
                        position: 'relative',
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        zIndex: 1000000,
                        maxHeight: '150px',
                        overflowY: 'auto',
                        listStyleType: 'none',
                        padding: '5px',
                        margin: 0,
                        width: '200px'
                    }}
                >
                    {filteredKeys.map((key) => (
                        <li
                            key={key}
                            onClick={() => handleSuggestionClick(key)}
                            style={{ padding: '5px', cursor: 'pointer' }}
                        >
                            {key}
                        </li>
                    ))}
                </ul>
            )} */}
            </mark>}
            
        </CustomWidthTooltip>
    );
}

const ToolTip = (outerProps)=>{
    return (props)=><ToolTipPre outerProps={outerProps} {...props}/>
}

function LexHighlightTextField(props) {
    const {
        rows = 3,
        label,
        value: propValue = '', // Controlled value from parent
        onChange,
        helperText,
        variant = 'outlined',
        keysObject = {},
        ...otherProps
    } = props;

    const [localValue, setLocalValue] = useState(propValue);
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [filteredKeys, setFilteredKeys] = useState([]);

    // Use a ref to store the latest onChange prop
    const onChangeRef = useRef(onChange);
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    // Throttled onChange handler stored in a ref
    const throttledOnChange = useRef(
        throttle((text) => {
            if (onChangeRef.current) {
                onChangeRef.current({
                    target: {
                        value: text,
                    },
                });
            }
        }, 5000)
    ).current;

    // Handle value changes locally
    const handleLocalChange = (val) => {
        setLocalValue(val); // Update local value
        throttledOnChange(val); // Throttle update to parent
        // Detect `$` symbol and set autocomplete suggestions
        const match = val.match(/\$([a-zA-Z0-9_]*)$/);
        if (match) {
            const query = match[1];
            const keys = Object.keys(keysObject).filter((key) =>
                key.startsWith(query)
            );
            setFilteredKeys(keys);
            setShowAutocomplete(keys.length > 0);
        } else {
            setShowAutocomplete(false);
        }

    };
console.log(showAutocomplete)
    // Prepare the highlight patterns with custom classes
    const highlight = [];
    const regex = /\$\w+/g;
    let match;

    while ((match = regex.exec(localValue)) !== null) {
        const key = match[0].substring(1); // Remove the '$' symbol
        highlight.push({
            highlight: match[0],
            component: ToolTip({ key, keysObject,localValue,showAutocomplete,filteredKeys,setLocalValue,setShowAutocomplete,setFilteredKeys}),
            className: keysObject[key] ? 'valid-highlight' : 'invalid-highlight',
        });
    }
    const editorRef = useRef(null);
    // useEffect(() => {
    //     if (editorRef.current) {
    //       const lineHeight = 1.5 * 16; // Assuming default line height of 1.5 and font-size of 16px
    //       const calculatedHeight = rows * lineHeight; // Dynamically calculate height
    //       editorRef.current.style.height = `${calculatedHeight}px`; // Apply height directly to the element
    //     }
    //   }, [rows]);

    return (
        <StyledDiv rows={rows}>
            <TextField
                label={label}
                variant={variant}
                multiline
                rows={rows}
                helperText={helperText}
                InputProps={{
                    inputComponent: HighlightWithinTextarea,
                    inputProps: {
                        value: localValue,
                        onChange: handleLocalChange,
                        highlight,
                        placeholder:"",
                        ref: editorRef,
                        ...otherProps,
                    },
                }}
                value="" // Prevent default value handling by TextField
            />
        </StyledDiv>
    );
}


export default LexHighlightTextField;