import React, { useState, useEffect, useContext } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { useTheme, Box, Button, IconButton,List,ListItem,Dialog,ListItemText, Tab, Tabs, MenuItem, Select } from '@mui/material';
import { Logo } from '../../logoComponent';
import QuickStartContext from '../../../contexts/quickstart/context';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import LexVideoDialog from '../../Lex/LexVideoDialog';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const QuickStartModal = ({ open }) => {
    const theme = useTheme();
    const { showQuickStart,setShowQuickStart} = useContext(QuickStartContext);
    const [value, setValue] = useState(0);
    const [loadedFile, setLoadedFile] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [activeVideo, setActiveVideo] = useState(true);
    const [videoId, setVideoId] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleListItemClick = (event, index) => {
        setActiveVideo(false);
        setSelectedIndex(index);
    };

    const items = [
        {title:'1. Introducton',file:'quickstart/intro.md', 
            // videoTutorial: 'dQw4w9WgXcQ'
        },
        {title:'2. Overview',file:'quickstart/overview.md'},  
        {title:'3. Building Automations',file:'quickstart/building.md'},
        {title:'4. Variables',file:'quickstart/var.md'}, 
        {title:'5. Form Components',file:'quickstart/form.md'},
        {title:'6. Generative Components',file:'quickstart/gen.md'},
        {title:'7. Upload Components',file:'quickstart/upload.md'},
        {title:'8. RAG Components',file:'quickstart/rag.md'},  
        {title:'8. Testing',file:'quickstart/testing.md'},  
    ];


    const handleChange = (event, newValue) => {
        // console.log(newValue)
        setValue(newValue);
    };
    const loadFile = (fileIndex) => {
        axios.get(items[fileIndex].file)
        .then(response => {
            setLoadedFile(response.data)
        })
        .catch(error => console.error(error));
    }
    const activateVideo = (index) => { 
        if(items[index]?.videoTutorial){
            setActiveVideo(true)
            setVideoId(items[index].videoTutorial)
        } }
    useEffect(() => {
        loadFile(0);
    }, []);
    useEffect(() => {
        loadFile(selectedIndex);
        setActiveVideo(false);
    }, [selectedIndex]);

    const handleSelectChange = (event) => {
        setActiveVideo(false);
        setSelectedIndex(event.target.value);
    };

    return (
        <>
        <Dialog
            open={showQuickStart}
            onClose={() => setShowQuickStart(false)}
            aria-labelledby="terms-of-service-modal-title"
            aria-describedby="terms-of-service-modal-description"
            sx={{
                // maxWidth: "700px",
                // maxHeight: "700px",
                height: '900px',
             
            }}
            maxWidth="md"
        >
            <div style={{
                // overflowY: 'scroll',
                backgroundColor: theme.palette.background.paper,
                bgcolor: 'background.paper',
                // marginTop:"20px",
                // border: '2px solid #000',
                height: '900px',
                overflow: 'hidden', 
                boxShadow: 24,
                // p: 4
            }}>
                <div style={{
                    position: isMobile? 'relative': 'sticky', top:isMobile?null: '0',
                    height:isMobile? null:"10%",
                    display: "flex", flexDirection: "column", padding: "0px", width: "100%", maxWidth: "800px"
                }}>
                    <div>
                        <div style={{
                            display: 'flex', flexDirection: "row", backgroundColor: theme.palette.background.paper,
                        }}>
                            <div style={{
                                paddingTop: "10px", paddingLeft: '10px'
                            }}>
                                <Logo color="#e6e6e6" format="policy" />
                            </div>
                            <Box sx={{ color: 'primary.children' }} style={{ flexGrow:1 }}>
                                {isMobile
                                    ? <Select
                                        value={value}
                                        variant={'filled'}
                                        onChange={(e) => setValue(parseInt(e.target.value))}
                                        style={{ width: "100%", marginTop: isMobile ? "0px" : null }}>
                                        <MenuItem key={0} value={0}>Quickstart Guide</MenuItem>
                                    </Select>
                                    : <Tabs
                                        textColor="inherit"
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            height: 'calc(100% + 3px)',
                                            '& div': {
                                                height: '100%',
                                            },
                                            '& .MuiTab-root': {
                                                height: '100%',
                                                padding: '0px 16px',
                                                paddingTop: '10px'
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: theme.palette.backgroundSecondary.paper, // "#2f3636",
                                                color: theme.palette.backgroundSecondary.contrastText,
                                                // clipPath: "polygon(0 0, 100%  0, calc(100% - 15px) 100%, 0px 100%)",
                                            },
                                        }}>
                                        <Tab label="Quickstart Guide" {...a11yProps(0)} />
                                        {/* <Tab icon={<SettingsIcon />}{...a11yProps(3)} /> */}
                                    </Tabs>}

                            </Box>
                            
                    {isMobile ?                      <IconButton
                            onClick={() => setShowQuickStart(false)}
                            color="primary"
                            style={{ marginLeft: "10px" }}
                        >
                            <CloseIcon />
                        </IconButton> : null}
                        </div>
                        {/* {isMobile ? <Button
                            style={{ marginLeft: "0px", marginTop: "10px" }}
                            onClick={() => setShowQuickStart(false)}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            Close
                        </Button> : null} */}
                    </div>
                </div>
                {/* <div style={{ display: 'flex', padding: "20px", flexDirection: 'column', backgroundColor: theme.palette.background.default, height: '80vh', maxWidth: "1000px", overflowY: "scroll" }}> */}
                <div style={{
            display: 'flex',
            flexDirection: isMobile ?'column':'row',
            width: '100%',
            // marginTop: "10%",
            height: '90%',
            maxWidth: '1200px',
        }}>
            {value === 0? <div style={{width: '35%', padding:isMobile? '20px':null,flexGrow: isMobile? null:1, height:  isMobile?null:'90%'}}>
                {isMobile ? (
                <Select
                    value={selectedIndex}
                    onChange={handleSelectChange}
                    variant={'filled'}
                    sx={{ marginBottom: '1rem', width: '250px'}}
                >
                    {items.map((item, index) => (
                        <MenuItem key={index} value={index}>
                            {item.title}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <List sx={{ bgcolor: 'background.paper', width: '200px' }}>
                    {items.map((item, index) => (
                        <ListItem
                            button
                            key={index}
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}
                            sx={{
                                '&.Mui-selected': {
                                    backgroundColor: theme.palette.backgroundSecondary.paper,
                                    color: theme.palette.backgroundSecondary.contrastText,
                                },
                                '&:hover': {
                                    backgroundColor: theme.palette.backgroundSecondary.paper,
                                    color: theme.palette.backgroundSecondary.contrastText,
                                },
                            }}
                        >
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))}
                </List>
            )}
            </div>:null}
                <div style={{padding:"20px", width: isMobile? '100%':'65%', height:isMobile?'80%':'100%', overflowY:'scroll'}}>
                    
                    <ReactMarkdown components={{
                        p({ node, inline, className, children, ...props }) {
                            return (<p {...props} style={{ marginBlockStart: "0", paddingTop: "10px",  marginBlockEnd: "0", }}>
                                {children}
                            </p>)
                        },
                        h1({ node, inline, className, children, ...props }) {
                            return (<h1 {...props} style={{ marginBlockStart: "0", fontSize: "2.2em", marginBlockEnd: "0", }}>
                                {children}
                            </h1>)
                        },
                        h2({ node, inline, className, children, ...props }) {
                            if(items[selectedIndex]?.videoTutorial){
                                return (<div style={{display:'flex',flexDirection:'row'}}><h2 {...props} style={{ marginBlockStart: "3px", paddingTop: "10px", paddingBottom: "10px", marginBlockEnd: "0", }}>
                                {children}
                            </h2> <h2 onClick={()=>activateVideo(selectedIndex)}{...props} style={{ marginBlockStart: "3px", cursor:'pointer', color:theme.palette.backgroundSecondary.paper, paddingLeft:'10px',paddingTop: "10px", paddingBottom: "10px", marginBlockEnd: "0", }}>
                                (Video Available)
                            </h2>
                            </div>)
                            }
                            else{
                            return (<h2 {...props} style={{ marginBlockStart: "3px", paddingTop: "10px", paddingBottom: "10px", marginBlockEnd: "0", }}>
                                {children}
                            </h2>)}
                        },
                        h3({ node, inline, className, children, ...props }) {
                            return (<h3 {...props} style={{ marginBlockStart: "3px", paddingTop: "10px", paddingBottom: "10px", fontSize: "1.2em", marginBlockEnd: "0", }}>
                                {children}
                            </h3>)
                        },
                        h4({ node, inline, className, children, ...props }) {
                            return (<h4 {...props} style={{ marginBlockStart: "3px", marginBlockEnd: "0", }}>
                                {children}
                            </h4>)
                        },
                        strong({ node, inline, className, children, ...props }) {
                            return (<strong {...props} style={{ marginBlockStart: "3px", paddingTop: "4px", paddingBottom: "4px", marginBlockEnd: "0", }}>
                                {children}
                            </strong>)
                        },
                        a({ node, inline, className, children, ...props }) {
                            return (<a {...props} style={{ marginBlockStart: "3px", marginBlockEnd: "0", color: "green" }}>
                                {children}
                            </a>)
                        },
                        code({ node, inline, className, children, ...props }) {
                            return (<div {...props} style={{ padding:"20px",fontSize: ".9em" }}>
                                <code>{children}</code>
                            </div>)
                        },
                        img({ node, inline, className, children, ...props }) {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection:'column',marginTop:"30px", marginBottom:"30px", padding:"20px", borderRadius:"20px", width:"400px", border:'solid 1px rgb(0,0,0,.1)', }}>
                            <img src={'./quickstart/'+props.src+'.png'}>
                                {children}
                            </img>
                            <div style={{color:'rgb(0,0,0,.6)',paddingTop:"20px",fontSize:'.8em'}}>Figure: {props.alt}</div>
                            </div>
                            </div>
                            )
                        },
                    }}>
                        {loadedFile}
                        </ReactMarkdown>
                </div>
  
                </div>

            </div>

        </Dialog >
                      <LexVideoDialog
                      open={activeVideo}
                      onClose={()=>setActiveVideo(false)}
                      videoId={videoId} // Replace with your actual video ID
                  />
                  </>
    );
};

export default QuickStartModal;