import React from 'react';
import { Container, Box, Typography, Grid, Paper, Button, AppBar, Toolbar } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import GavelIcon from '@mui/icons-material/Gavel';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DescriptionIcon from '@mui/icons-material/Description';
import SecurityIcon from '@mui/icons-material/Security';

const features = [
    {
        icon: <AccountBalanceIcon fontSize="large" color="primary" />,
        title: 'Compliance Management',
        description: 'Ensure your business adheres to legal regulations with our comprehensive compliance tools.',
    },
    {
        icon: <GavelIcon fontSize="large" color="primary" />,
        title: 'Legal Consultations',
        description: 'Get expert legal advice from our team of experienced lawyers.',
    },
    {
        icon: <SupportAgentIcon fontSize="large" color="primary" />,
        title: '24/7 Support',
        description: 'Access round-the-clock support to help you with any legal queries or issues.',
    },
    {
        icon: <DescriptionIcon fontSize="large" color="primary" />,
        title: 'Document Management',
        description: 'Easily manage and store all your important legal documents in one place.',
    },
    {
        icon: <SecurityIcon fontSize="large" color="primary" />,
        title: 'Data Security',
        description: 'Protect your sensitive legal data with our top-notch security measures.',
    },
];

const GetStarted = () => (
    <div>
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Typography variant="h3" component="h1" gutterBottom>
                    Automate Your Legal Processes
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                    Get Started with Our Comprehensive Legal Solutions
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Legal SaaS provides you with the tools and resources you need to manage your legal needs efficiently and effectively.
                </Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }}>
                    Get Started
                </Button>
            </Box>
            {/* <Grid container spacing={4} sx={{ mt: 4 }}>
                {features.map((feature, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper elevation={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {feature.icon}
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {feature.title}
                                </Typography>
                                <Typography variant="body1">
                                    {feature.description}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid> */}
        </Container>
    </div>
);

export default GetStarted;
