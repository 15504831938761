import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { MarkdownTable } from '../../../components/Lex/LexMarkdownTable';
import ContextCollector from '../../../components/Automata/ContextCollector';
import GenerativeRAGCollector from '../../../components/Automata/GenerativeRAGCollector';
import TroubleshootSharpIcon from '@mui/icons-material/TroubleshootSharp';
import LexTypography from '../../../components/Lex/LexTypography';

export const ragStep = {
    overview: {
        name: 'rag',
        label: 'Retrieval Augmented Generation',
        sublabel: 'Use RAG to semantically search and incorporate your static files into textual generation',
        icon: <TroubleshootSharpIcon />,
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'ragStep',
                labelDisplay: "Search",
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    outputDisplay: {
        enabled: true,
        description: "",
        collectorComponent: ({ configureStep, stepData, errorDisplay, testState,stepId }) => {
            let searchResults = testState?.[stepId]?.['__search__underlying'] ? testState?.[stepId]?.['__search__underlying'] : []
            return <div>
                {searchResults.map((result, index) => {
                    return(<div>
                    <LexTypography variant="body1" sx={{
                                            fontWeight: 'normal',
                                        }}>
                                            File Name: {result['file']}
                    </LexTypography>
                    <LexTypography variant="body1" sx={{
                                            fontWeight: 'normal',
                                        }}>
                                            Page Number: {result['page']?.['page_number']}
                    </LexTypography>
                    <LexTypography variant="body1" sx={{
                                            fontWeight: 'normal',
                                        }}>
                                            Raw Text (may be unformatted):
                    </LexTypography>
                    <div style={{height:'300px',overflowY:'scroll',margin:"30px",padding:"10px",borderRadius:'5px', border:"solid 1px black"}}>
                        <MarkdownTable
                            markdown={result['text']} />
                    </div>
                    </div>)
                })}
            </div>
        }

    },
    primaryOptions: {
        header: 'RAG Step',
        description: 'A generative step creates a conservation with the LLM. You may either create a fresh conversation or continue an existing one.  \n\n Under certain cirmcumstances, continuing an existing conversation allows the LLM to utilize the existing context to generate more accurate responses.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: false,
                    display: 'writePrompt'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        header: 'Continue Conversation?',
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollector nextStep={'writePrompt'} {...props} />,
    },

    writePrompt: {
        header: 'LLM Prompt',
        description: '',
        collectorComponent: (props) => <GenerativeRAGCollector {...props} />,
    },
    default: {

    }


};