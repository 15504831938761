import React, { useState, useEffect, useContext } from 'react';
import APIContext from './context';
import { axiosWrapper } from '../../auth';
import { useErrorModal } from '../error';
import { Modal, Box, CircularProgress } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const APIContextProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const [apiForcedRefresh, setApiForcedRefresh] = useState(1);
    const [responseLoading, setResponseLoading] = useState(false);
    const [temporaryRequestStorage, setTemporaryRequestStorage] = useState({modalOpen:false});
    const [dialogResponseActions, setDialogueResponseActons] = useState({});
    const showError = useErrorModal();

    const completeDialogueResponse = () => {
        setDialogueResponseActons({});
    }
    const APIAxiosWrapper = async (url,
        data = {},
        options = { headers: {} }) => {
        try {
            const response = await axiosWrapper(url, data, options);
            return response;
        } catch (e) {
            if(e.response?.data?.errorFormatted){
                showError(e.response.data.message,e.response.data.code)
            }
            setError(e);
            throw e
        }
    }

    const APIAxiosWrapperWithCatch = async (url,
        data = {},
        options = { headers: {} }) => {
        try {
            const response = await axiosWrapper(url, data, options);
            return response;
        } catch (e) {
            throw e;
        }
    }

    const APIAxiosWrapperWithLoading = async (url,
        data = {},
        options = { headers: {} }) => {
        setResponseLoading(true);
        try {
            const response = await axiosWrapper(url, data, options);
            setResponseLoading(false);
            return response;
        } catch (e) {
            setResponseLoading(false);
            if(e.response?.data?.errorFormatted){
                showError(e.response.data.message,e.response.data.code)
            }
            setError(e);
        }
    }

    const APIAxiosWrapperWithDialog = async (confirmationText,title,followUp={}, url,
        data = {},
        options = { headers: {} }) => {
        setTemporaryRequestStorage({modalOpen:true,
            confirmationText:confirmationText,
            title:title,
            url:url,
            data:data,
            followUp:followUp,
            options:options});
        return null

    }
    useEffect(() => {
        setTimeout(() => {
            setTemporaryRequestStorage({modalOpen:false});
        },80);
    }, [apiForcedRefresh]);

    const executeDialogRequest = async () => {
        try {
            if(temporaryRequestStorage.followUp.useLoading){
                setResponseLoading(true);
            }
            let response = null;
            if(temporaryRequestStorage.followUp.mock){
                response = null
            }else{
                response = await axiosWrapper(temporaryRequestStorage.url, temporaryRequestStorage.data, temporaryRequestStorage.options);
            }
            
            if(temporaryRequestStorage.followUp.useLoading){
                setResponseLoading(false);
            }
            setDialogueResponseActons({...temporaryRequestStorage.followUp,response:response});
            setTemporaryRequestStorage(old=>({...old,modalOpen:false}));
            setApiForcedRefresh(v=>v + 1);
            return response;
        } catch (e) {
            setTemporaryRequestStorage(old=>({...old,modalOpen:false}))
            if(e.response?.data?.errorFormatted){
                showError(e.response.data.message,e.response.data.code)
            }
            setError(e);
        }   
    }
    const handleClose = () => {
        setTemporaryRequestStorage({modalOpen:false});
    }

    const APIAxiosWrapperFiles= async (url,
        data = {},
        options = { headers: {} }) => {
        try {
            const response = await axiosWrapper(url, data, options);
            return response;
        } catch (e) {
            setError(e);
        }
    }
    return (
        <APIContext.Provider value={{
            api: APIAxiosWrapper,
            apiWithCatch: APIAxiosWrapperWithCatch,
            apiFileUpload: APIAxiosWrapperFiles,
            apiWithLoading: APIAxiosWrapperWithLoading,
            apiWithDialog: APIAxiosWrapperWithDialog,
            apiForcedRefresh,completeDialogueResponse,dialogResponseActions
        }}>
            {temporaryRequestStorage.modalOpen?<Dialog
                open={temporaryRequestStorage.modalOpen}
                onClose={handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{temporaryRequestStorage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {temporaryRequestStorage.confirmationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                     <Button variant="contained" onClick={executeDialogRequest} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>:null}
        <Modal
            open={responseLoading}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        </Modal>
            {children}
        </APIContext.Provider>
    );
}

export default APIContextProvider;