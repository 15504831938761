import React from "react";
import { SideCard } from "../../../cards";

const SidebarInternalWrapper = ({children, ...props}) => {
    return (
    <SideCard style={{flex:1}}>
        {children}
    </SideCard>

    )
}

export default SidebarInternalWrapper;