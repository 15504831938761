import React, { useState, useEffect } from 'react';
import Subcollector from '../../../components/Automata/Subcollector';
import { Accordion, AccordionSummary, AccordionDetails, Alert, Typography, IconButton } from '@mui/material';
import LexTypography from '../../Lex/LexTypography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material';
import { subcollectorQuickInput } from '../utilities';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const conf = (configureStep, stepData, index, param) => (e) => {
    let newCollectedFields = [...stepData.collectedFields]
    newCollectedFields[index][param] = e.target.value
    configureStep.set({
        collectedFields: newCollectedFields,
    })
}

const selectorFunction = (configureStep, stepData, index, fieldData) => (e) => {
    conf(configureStep, stepData, index, "type")(e)
    if (e.target.value === "Select") {
        conf(configureStep, stepData, index, "sample")({ target: { value: fieldData.selectOptions.split(",")[0] } })
    }
    if (e.target.value === "Checkbox") {
        conf(configureStep, stepData, index, "sample")({ target: { value: false } })
    }
    if (e.target.value === "Text Input") {
        conf(configureStep, stepData, index, "sample")({ target: { value: "" } })
    }

}

const PolygonLabel = ({
    className,
    children,
    bgColor,
    height,
    width,
    style,
    clip = "polygon(0 0, calc(100% - 15px)  0, 100% 100%, 0 100%)"
}) => {

    /* How we transfer visual "feel" from the polygon clip-path headers at
     * frontend/web/src/components/Automata/AutomataProcessDesign/StylizedSummary/index.js
     * 
     * Visually, static horizontal negative space is best approximated by a constant function, so we just transfer the left padding over directly.
     * 
     * In the original headers, horizontal negative space on the right changed with respect to the width of the content.
     * To approximate this, we need a way to calculate the _width_ that accounts for the already constant used-up left space.
     * 
     * Regarding inner width, the left space is simply padding. There was a very small difference between largest and smallest content lengths in the 
     * original left headers, and the same remains true of these new labels. So, we use a function of the largest content length.
     * This will best approximate the original visual effect.
     * 
     * And since left space is a constant, this function acts on the remaining space after removing the original left padding.
     * Afterwards, we simply add the padding back to the new inner width.
     * 
     * inner width ratio = {{old inner width} - {inner left padding}} / {old inner largest content length}
     * new outer width = {{inner width ratio} * {new inner largest content length}} + {inner left padding}
     * 
     * Since there was much more deviation between the largest & smallest content lengths in the right headers and this remains true of the new labels,
     * we use a function of the smallest content length. This has a similar effect to the above function. The constant padding here changes, though,
     * since the rightmost content length has different left padding and since we have the inner width on the left. So we keep the outer left padding
     * constant, but we remove the old inner width when finding the ratio, then add back the new inner width when finding the new outer width.
     * 
     * outer width ratio = {{old outer width} - {old inner width} - {outer padding left}} / {old outer smallest content length}
     * new outer width = {{outer width ratio} * {new outer smallest content length}} + {outer padding left} + {new inner width} 
     * 
     * -J */

    return (
        <div className={className}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: height,
                width: width,
                backgroundColor: bgColor,
                clipPath: clip,

                ...style
            }}>
            {children}
        </div>
    )
}

const FormDataCollector = ({ configureStep, stepData, errorDisplay }) => {

    const innerTheme = {
        width: 'calc(120px - 0.5em)',
        width: '122px',
        paddingLeft: '12px',
    }

    const outerTheme = {
        width: '284px',
        paddingLeft: '0px',
    }

    const theme = useTheme();
    const [expanded, setExpanded] = useState(false)

    const handleChange = (step) => (event, isExpanded) => {
        setExpanded(isExpanded ? step : false)
    };

    const deleteFormField = (index) => {
        let newCollectedFields = structuredClone(stepData.collectedFields)
        newCollectedFields.splice(index, 1)
        configureStep.set({ collectedFields: newCollectedFields })
    }

    useEffect(() => {
        // setExpanded(`step${stepData.collectedFields.length - 1}`)
    }, [stepData?.collectedFields?.length])

    return (
        <div style={{ margin: '10px 0 10px' }}>
            {stepData.collectedFields.map((fieldData, index) =>
                <Accordion key={`step${index}`}
                    disableGutters
                    elevation={0}
                    square
                    sx={{ '&.Mui-expanded:before': { opacity: 1 } }}
                    expanded={expanded === `step${index}`}
                    onChange={handleChange(`step${index}`)}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        sx={{
                            ".MuiAccordionSummary-content": {
                                margin: '5px 0px',
                                paddingRight: '0px'
                            }
                        }}>

                        <div style={{
                            '--left-poly-width': '6em',
                            '--right-poly-width': 'calc(130px + var(--left-poly-width))',

                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px 10px 10px 10px',
                        }}>
                            <div style={{
                                flex: '1 1 auto',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}>
                                <PolygonLabel
                                    clip="polygon(0 0, 100% 0, calc(100% - 15px)  100%, 0 100%)"
                                    width='var(--right-poly-width)'
                                    bgColor='rgba(0, 0, 0, 0.1)'
                                    style={{
                                        ...outerTheme,
                                    }}>

                                    <PolygonLabel
                                        clip="polygon(0 0, 100% 0, calc(100% - 15px)  100%, 0 100%)"
                                        height='60px'
                                        width='var(--left-poly-width)'
                                        bgColor={'rgba(0, 0, 0, 0.3)'}
                                        className="formFieldPolygonLabel"
                                        style={{
                                            paddingLeft: "12px",
                                            ...innerTheme,
                                        }}>

                                        <div className="deleteFormFieldSlider"
                                            style={{ color: theme.palette.backgroundSecondary.contrastText }}>
                                            <div className='trashContainer'>
                                                <PolygonLabel className='deleteFormFieldButton'
                                                    clip="polygon(0 0, 100% 0, calc(100% - 15px)  100%, 0 100%)"
                                                    height='60px'
                                                    width='var(--left-poly-width)'
                                                    bgColor={theme.palette.backgroundSecondary.default}
                                                    style={{
                                                        ...innerTheme,
                                                        width: '70px',
                                                        paddingLeft: '14px',
                                                    }}>
                                                    <IconButton
                                                        onClick={deleteFormField.bind(null, index)}
                                                        size="small"
                                                        aria-controls="delete"
                                                        sx={{
                                                            color: theme.palette.backgroundSecondary.contrastText,
                                                            '&:hover': { color: theme.palette.error.light }
                                                        }}>
                                                        <DeleteOutlinedIcon />
                                                    </IconButton>
                                                </PolygonLabel>
                                            </div>

                                            <div class="formFieldIndex">
                                                <LexTypography variant="h6" sx={{
                                                    fontWeight: 'normal',
                                                    fontSize: '1.2em',
                                                    color: theme.palette.backgroundSecondary.contrastText
                                                }}>{index + 1}</LexTypography>
                                            </div>
                                        </div>

                                        <LexTypography variant="h6" sx={{
                                            fontWeight: 'normal',
                                            fontSize: '1.2em',
                                            color: theme.palette.backgroundSecondary.contrastText,
                                        }}>Field</LexTypography>
                                    </PolygonLabel>

                                    <LexTypography variant="h6"
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: '1.2em',
                                            paddingLeft: '20px',
                                        }}>
                                        {fieldData.type === "text" ? '' : `${fieldData.type} `}
                                    </LexTypography>

                                </PolygonLabel>

                                <LexTypography variant="h6"
                                    style={{
                                        fontWeight: 'normal',
                                        fontSize: '1.2em',
                                        padding: '0px 15px',
                                        flex: '1 0 auto',
                                        width: '1em',
                                        overflow: 'hidden',
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}>
                                    {fieldData.label ? `${fieldData.label}` : ''}
                                </LexTypography>
                            </div>

                            {fieldData.label && fieldData.type !== "text" && fieldData.varName && (fieldData.type !== "Select" || fieldData.selectOptions.length > 0)
                                ? ''
                                : <Alert severity="warning"
                                    sx={{ padding: '0px', background: 'none' }}>
                                    Requires Actions
                                </Alert>
                            }
                        </div>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        borderRadius: '0px',
                        border: '1px solid rgba(0,0,0,.1)',
                        margin: '0px 1em',
                        marginBottom: '30px',
                    }}>
                        <Subcollector
                            titleText={"Form Input"}
                            descriptionText={"Please provide information about the data you wish to collect."}
                            fieldData={fieldData}
                            selector={{
                                onChange: selectorFunction(configureStep, stepData, index, fieldData),
                                menuText: "Select an option",
                                inputLabel: "Type",
                                selectValue: fieldData.type,
                                selectionOptions: [["Text Input", 0], ["Checkbox", 1], ["Select", 2]],
                                formatMenuItem: ([opt, index]) => opt
                            }}
                            /* selectionFields is declared by an IIFE that takes muQuickInput, a version of the subcollectorQuickInput function that 
                               binds the redundant arguments. This is better than currying and declaring a new function every time. */
                            selectionFields={((muQuickInput) => [
                                muQuickInput("Variable Name", "varName",
                                    "This is the variable name that will be used to store the input data."),
                                muQuickInput("Label", "label",
                                    "This is the label of the field that will be displayed to the end-user."),
                                muQuickInput("Selection Options", "selectOptions",
                                    "Please seperate the selection options by a comma."),
                                muQuickInput("Checked Output", "checkedOutputText",
                                    "This text will be stored in the variable if the user checks this box."),
                                muQuickInput("Unchecked Output", "uncheckedOutputText",
                                    "This text will be stored in the variable if the user leaves the box unchecked.")
                            ])(subcollectorQuickInput.bind(
                                null,
                                stepData,
                                configureStep,
                                conf,
                                index,
                                fieldData))} />
                    </AccordionDetails>
                </Accordion>
            )}
        </div >
    )
};

export default FormDataCollector;
