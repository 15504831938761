import React from "react";
import { useTheme } from "@mui/material";
import { Paper, Box } from "@mui/material";

const fauxCard = {
    background: 'transparent',
    boxShadow: 'none',
    padding: '0px',
}

export const Canvas = (props) =>
    <Box sx={{
        bgcolor: `${props.color}.children`,
        color: `${props.color}.childText`
    }}>
        {props.children}
    </Box>

export const BasicCard = (props) => {
    let bgColor, txtColor;

    if (props.parentColor) {
        bgColor = props.parentColor + '.children'
        txtColor = props.parentColor + '.childText'
    } else if (props.color) {
        bgColor = props.color + '.main'
        txtColor = props.color + '.contrastText'
    }
    // const color = props.color || props.parentColor

    // console.log(`================== BasicCard: ${props.parentColor || props.color} ==================`)
    return <Paper
        color={props.color}
        sx={{
            // background: `${props.color}.main`,
            bgcolor: bgColor,
            color: txtColor,
            // borderRadius: 4,
            // p: 3,
        }}
        style={{
            width: props.slim ? 'fit-content' : 'auto',
            ...props.style
        }}>
        {props.children}
    </Paper>
}

export const FieldCard = (props) =>
    <BasicCard
        color={props.color}
        style={{
            borderRadius: 0,
            padding: '12px',
            boxShadow: 'none',
            ...props.style
        }}>
        {props.children}
    </BasicCard>

export const SideCard = (props) =>
    <BasicCard
        color={props.color}
        style={{
            overflowY: 'scroll',
            scrollbarGutter: 'stable both-edges',
            // borderRadius: 8,
            padding: '0.3cm 0px',
            ...props.style
        }}>
        {props.children}
    </BasicCard>