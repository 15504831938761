import Collector from '../Collector';
import { quickCheck, quickInput,quickTestInput } from '../utilities';

const GenerativeStepVerificationCollector = ({ configureStep, stepData, errorDisplay,stepId,testState,...other}) => {
    return <Collector
        inputFields={[
            quickInput(stepData, configureStep, "Validation Criteria", "verification_critera", "This is the prompt/command that will be used to evaluate the results of multiparse.",true,8,true,testState)
        ]}
    />
};

export default GenerativeStepVerificationCollector;