import React, { useState } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
// import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
// import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import Team from "../components/Sections/Team";
import DocumentGen from "../components/Sections/DocumentGen";
import Autoamte from "../components/Sections/Automate";
import Freedom from "../components/Sections/Freedom";
import Security from "../components/Sections/Security";
import Patents from "../components/Sections/Patents";
import USA from "../components/Sections/USA";

export default function Landing() {
  const [teamPage, setOnTeamPage] = useState(false);
  return (
    <>
      {teamPage ? 
      <>
        <TopNavbar teamPage={teamPage} setOnTeamPage={setOnTeamPage} />
        <Team/>

        <Footer />
      </> : <>
        <TopNavbar teamPage={teamPage} setOnTeamPage={setOnTeamPage}/>
        <Header teamPage={teamPage} setOnTeamPage={setOnTeamPage}/> 
        <DocumentGen/> {/* do */}
        <Freedom/> {/* do */}
        {/* <Services /> */}
        <Patents/>
        <Autoamte/> {/* do */}
        <Security/> {/* do */}
        <USA/>
        {/* <Contact /> */}
        <Footer teamPage={teamPage} setOnTeamPage={setOnTeamPage}  /></>
      }</>
  );
}


