import React, { useState, useEffect, useContext } from "react";
import { TextField, Button } from "@mui/material";
import { SiteGate, lastButtonStyle } from "../sitegate";
import APIContext from "../../contexts/api/context";

export const EmailVerification = () => {
    const {api} = useContext(APIContext)
    const [storedEmail, setStoredEmail] = useState(false)
    const [email, setEmail] = useState(null)
    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const tok = new URLSearchParams(window.location.search).get('token')
        setToken(tok)

        const storedEmail = localStorage.getItem('emailForSignIn')
        if (storedEmail) {
            setStoredEmail(true)
            setEmail(localStorage.getItem('emailForSignIn'))
        }
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) {
            setError('Please enter your email address.');
            return;
        }
        setError('');
        api('/ptntst/verifyEmail', { email: email, token: token })
            .then(apiResponse => {
                if (apiResponse.data.type === 'success') {
                    localStorage.removeItem('emailForSignIn')
                    window.location = '/auth'
                }
                else if (apiResponse.data.type === 'error') {
                    setError(apiResponse.data.message)
                }
            })
    };

    return (
        <SiteGate
            title="Email Verification"
            error={error}
            buttons={
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={lastButtonStyle}>
                    Verify Email
                </Button>
            }>
            {false && storedEmail
                ? null
                : <TextField
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    style={{ width: 'calc(100% - 0.3cm)' }}
                    value={email}
                    onChange={handleChange} />}
        </SiteGate>
    )
}
