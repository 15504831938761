import React from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import { useTheme } from "@mui/material";

export default function PricingTable({ icon, price, title, text, offers, greyed, action }) {
  let getIcon;
  const theme = useTheme()
  switch (icon) {
    case "roller":
      getIcon = <RollerIcon />;
      break;
    case "monitor":
      getIcon = <MonitorIcon />;
      break;
    case "browser":
      getIcon = <BrowserIcon />;
      break;
    case "printer":
      getIcon = <PrinterIcon />;
      break;
    default:
      getIcon = <RollerIcon />;
      break;
  }

  return (
    <Wrapper className="whiteBg radius8 shadow" style={{ opacity: greyed ? .3 : 1 }}>
      <div style={{ fontColor: theme.palette.text.primary, height: "40px" }} className="flexSpaceCenter">
        {getIcon}
        <p className="font20 extraBold">{price}</p>
      </div>
      <div style={{ margin: "30px 0", marginBottom: "45px", marginTop: "30px" }}>
        <h4 className="font20 extraBold">{title}</h4>
        <p className="font13" style={{ marginTop: "10px" }}>{text}</p>
      </div>
      <div>
        {offers
          ? offers.map((item, index) => (
            <div className="flexNullCenter" style={{ border: "1px solid #ddd6d6", borderRadius: "5px", padding: "5px", margin: "15px 0" }} key={index}>
              <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                {item.cheked ? (
                  <div style={{ minWidth: "20px" }}>
                    <CheckMark />
                  </div>
                ) : (
                  <div style={{ minWidth: "20px" }}></div>
                )}
              </div>
              <p className="font15 extraBold">{item.name}</p>
            </div>
          ))
          : null}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  border: solid 1px #204146;
  padding: 20px 30px;
  height: 100%;
  /*margin-top: 30px;*/
`;
