import LexFormSelect from '../../Lex/LexFormSelect';
import LexButton from '../../Lex/LexButton';

const ContextCollector = ({ nextStep, configureStep, stepData, errorDisplay }) => {
    let testOpts = configureStep.priorConversations()
    return (
        <div>
            <LexFormSelect
                onChange={(e) => {configureStep.set({contextToContinue: e.target.value})}}
                selectValue={stepData?.contextToContinue ? stepData?.contextToContinue : null}
                inputLabel={"Existing Conversation"}
                menuText={"Select An Existing Conversation"}
                selectionOptions={testOpts}
                formatMenuItem={([selStr, selIdx]) => "Step " + (1+selIdx)}

            />
            <div style={{ paddingTop: "10px" }}>
                <LexButton variant="outlined" onClick={(e) => {
                    configureStep.set({display: nextStep})
                }}>Continue?</LexButton>
            </div>
        </div>)
};


export default ContextCollector;