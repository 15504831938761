import LexButton from "../../Lex/LexButton";
import Collector from "../Collector";
import DesContext from "../../../contexts/singleFlow/context";
import { useContext } from "react";

const MetadataCollector = ({ stepData, stepIndex, stepId}) => {
    const { manager } = useContext(DesContext);
    const stepOperations = manager.createStepOperations({ stepData, stepIndex, stepId });
    const changeStepName = (e) => {
        stepOperations.configureStep.set({ suppliedName: e.target.value })
    }
    const changeStepDescription = (e) => {
        stepOperations.configureStep.set({ suppliedDescription: e.target.value })
    }
    return <><Collector
        titleText={"Step Overview"}
        descriptionText={"This section is optional."}
        inputFields={[
            {
                inputLabel: "Step Name",
                value: stepData.suppliedName,
                onChange: changeStepName,
                helperText: "(Optional) Please provide a name for your step."
            },
            {
                inputLabel: "Description",
                value: stepData.suppliedDescription,
                onChange: changeStepDescription,
                helperText: "(Optional) Please provide a description for your step."
            }
        ]}
    />
        <div style={{ paddingTop: "10px" }}>
            <LexButton
                variant="outlined"
                onClick={(e) => {
                    manager.removeStep(stepIndex)
                }}>
                Delete Step
            </LexButton>
        </div></>
};

export default MetadataCollector;
