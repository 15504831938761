import { List, ListItemButton, Avatar, ListItemText, ListItemAvatar } from "@mui/material"
import { useTheme } from "@mui/material";

const LexLargeButtonList = ({ options, clickHandler }) => {
    const theme = useTheme()
    return (
        <List dense={true}>
            {options.map((option, index) => {
                return (
                    <ListItemButton key={index} onClick={() => { clickHandler(option) }}>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: theme.palette.backgroundSecondary.default }}>
                                {option.overview.icon}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={option.overview.label}
                            secondary={option.overview.sublabel}
                        />
                    </ListItemButton>
                );
            })}
        </List>)
};

export default LexLargeButtonList;