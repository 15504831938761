import { useState } from 'react';

export function useFileHandlers(setNodes, setEdges,promptData, setPromptData) {
    function downloadObjectAsJson(obj, filename) {
        const jsonString = JSON.stringify(obj, null, 2); // Indented JSON string for readability
        const blob = new Blob([jsonString], { type: "application/json" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // Required for this to work in FireFox
        a.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    function loadJsonFile(callback) {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.json';

        input.onchange = (e) => {
            const file = e.target.files[0];

            if (!file) {
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const obj = JSON.parse(e.target.result);
                    callback(obj);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };
            reader.readAsText(file);
        };

        input.click();
    }

    function patchEdges(edgesOther) {
        return edgesOther
    }

    function patchNodes(nodesOther) {
        return nodesOther
    }

    function handleFileLoad() {
        loadJsonFile((loadedObject) => {
            let newNodes = loadedObject.nodes
            let newEdges = loadedObject.edges
            newNodes = newNodes.map((node) => {
                return {
                    ...node,
                    data: {
                        ...node.data
                    },
                };
            })
            setNodes(patchNodes(newNodes))
            setEdges(patchEdges(newEdges))
            setPromptData(loadedObject.promptData)
            // document.getElementById('jsonContent').textContent = JSON.stringify(loadedObject, null, 2);
        });
    }

    function handleFileLoadWithState() {
        loadJsonFile((loadedObject) => {
            let newNodes = loadedObject.workflow.nodes
            let newEdges = loadedObject.workflow.edges
            newNodes = newNodes.map((node) => {
                return {
                    ...node,
                    data: {
                        ...node.data
                    },
                };
            })
            setNodes(patchNodes(newNodes))
            setEdges(patchEdges(newEdges))
            // document.getElementById('jsonContent').textContent = JSON.stringify(loadedObject, null, 2);
        });
    }
    return { handleFileLoad, handleFileLoadWithState, downloadObjectAsJson }
}