import {useContext } from 'react';
import LexFileDropzone from '../../Lex/LexFileDropzone';
import DesContext from '../../../contexts/singleFlow/context';

const DynamicFileCollector = ({ configureStep, stepData, errorDisplay, stepIndex, stepId,testState }) => {
    let { manager} = useContext(DesContext);
    let fileId = '__'+stepId+'__uploadFile'
    const mode = 'singleFile'
    const files = testState?.[fileId] ? [testState[fileId]]:[]
    const delFn = manager.deleteFile(mode)
    return <div>
        <LexFileDropzone 
        files={files}
        uploadFuncton={manager.uploadFiles({ configureStep, stepData, stepIndex, stepId,mode })}
        dependencies={[configureStep, stepData]}
        deleteFunction={(index)=>()=>delFn( {configureStep, stepData, files, stepIndex, stepId })} 
        />
    </div>
}

export default DynamicFileCollector;