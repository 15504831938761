import {Typography} from "@mui/material";
import React from "react";

const DescriptionBlock = (props) => {
    return <div style={{
        marginTop: "10px",
        display: "flex",
        borderRadius: "15px",
        flexDirection: "row",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#1a1f22",
        // marginLeft: "-20px",
        // marginRight: "-80px",
        padding: "30px",
    }}>
        <Typography variant="h6" gutterBottom
                    style={{width: "60%", marginBottom: "20px"}}>
            {props.title}
        </Typography>

        <Typography
            variant="body2"
            style={{width: "80%", paddingTop: "5px", paddingBottom: "5px", whiteSpace: 'pre-line'}}
            sx={{whiteSpace: 'pre-line'}}>
            {props.children}
        </Typography>

    </div>
}

export default DescriptionBlock