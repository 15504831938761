import React from 'react';
import Button from "@mui/material/Button";
import { IconButton, Typography } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

export function Toolbar({ nodes, edges, evalState, evalIdx, previewMode, notInFlight, downloadObjectAsJson, handleFileLoad, handleFileLoadWithState, setPreviewMode, setEvalState, changeEvalIdx, addNewNode, executeSequence }) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            position: "fixed",
            height: "30px",
            width: previewMode ? "100vw" : "65vw",
            top: "0px",
            left: "0px",
            backgroundColor: "#2e2632",
            zIndex: 20000000000000,
        }}>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={() => {
                    downloadObjectAsJson({ nodes: nodes, edges: edges }, "stateData")
                }}>
                Save
            </Button>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={handleFileLoad}>
                Load
            </Button>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={() => {
                    downloadObjectAsJson({
                        workflow: { nodes: nodes, edges: edges },
                        stateData: { evalState: evalState, evalIdx: evalIdx }
                    }, "stateData")
                }}>
                Save With State
            </Button>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={handleFileLoadWithState}>
                Load With State
            </Button>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={(e) => {
                    setPreviewMode((pre) => !pre)
                }}>
                Toggle Preview
            </Button>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={(e) => {
                    setEvalState({ context: {}, variables: {} })
                    changeEvalIdx('start')
                }}>
                Clear State
            </Button>
            <Button
                style={{
                    marginRight: "5px",
                    color: "#adadad",
                    borderRight: "1px solid #604353",
                    textTransform: 'none'
                }}
                onClick={addNewNode(nodes.length)}>
                Add Node
            </Button>
            {notInFlight ? <IconButton
                style={{ marginRight: "5px", textTransform: 'none' }}
                variant="outlined"
                color="info"
                onClick={executeSequence}>
                <PlayArrowIcon style={{ color: "#008400" }} />
            </IconButton> : null}
        </div>)
}