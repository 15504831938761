import { useState } from 'react';
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
    getMultiFactorResolver,
    RecaptchaVerifier,

    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
} from "firebase/auth";
import { auth } from '../../../auth/auth';
import { Button, Container, TextField, Typography, Grid } from '@mui/material';

const ProfilePage = () => {
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [verificationCode, setVerificationCode] = useState(null)
    const [verificationId, setVerificationId] = useState(null)
    const [resolverObj, setResolverObj] = useState(null)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const updatePasswordHelper = (user, newPassword) => {
        updatePassword(user, newPassword).then(() => {
            // Update successful.
            console.log("[+] Password updated successfully")
            setSuccess(true)
        }).catch((error) => {
            // An error ocurred
            // ...
            console.log("[-] Error: ")
            console.log(error)
        });
    }

    const submitNewPassword = (e) => {
        if (newPassword !== confirmPassword) {
            return
        }

        const user = auth.currentUser;

        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            password
        )
        console.log("[+] Reauthenticating user: " + auth.currentUser.email)

        reauthenticateWithCredential(user, credential).then(
            () => {
                // User re-authenticated.
                // heartandsoul.operations@gmail.com
                updatePasswordHelper(user, newPassword)
            }).catch((error) => {

                if (error.code === 'auth/multi-factor-auth-required') {
                    // The user is a multi-factor user. Second factor challenge is required.
                    const resolver = getMultiFactorResolver(auth, error);

                    console.log(resolver.hints[0].factorId) // phone - TODO: add menu to select MFA method.


                    if (!window.loginRecaptchaVerifier) {
                        window.loginRecaptchaVerifier = new RecaptchaVerifier(
                            auth,
                            "recaptcha-container-login",
                            // Optional reCAPTCHA parameters.
                            {
                                "size": "normal",
                                "callback": function (response) {
                                    // reCAPTCHA solved, you can proceed with
                                    // phoneAuthProvider.verifyPhoneNumber(...).
                                },
                                "expired-callback": function () {
                                    // Response expired. Ask user to solve reCAPTCHA again.
                                    // ...
                                }
                            },
                        );
                    }
                    window.loginRecaptchaVerifier.render();

                    const phoneInfoOptions = {
                        multiFactorHint: resolver.hints[0], // TODO: add menu to select MFA method.
                        session: resolver.session
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(auth);
                    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.loginRecaptchaVerifier)
                        .then((verificationId) => {
                            // verificationId will be needed for sign-in completion.
                            setVerificationId(verificationId);
                            setResolverObj(resolver)
                        })
                        .catch((error) => {
                            // Error occurred during multi factor sign up.
                            // ...
                            console.log(error)
                            setError("Please try again.")
                            window.loginRecaptchaVerifier.clear()
                        });
                    // ...
                } else {
                    console.log("[-] ERROR");
                    console.log(error);

                    const errorCode = error.code;
                    const errorMessage = error.message;
                }
            });
    }


    const handleVerificationCode = async (event) => {

        event.preventDefault();
        if (!verificationCode) {
            setError('Please enter the verification code you received over SMS.');
            return;
        }
        setError('');

        // Ask user for the verification code. Then:
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        // Complete sign-in. This will also trigger the Auth state listeners.
        resolverObj.resolveSignIn(multiFactorAssertion)
            .then(function (userCredential) {
                const user = userCredential.user;
                updatePasswordHelper(auth.currentUser, newPassword)
            });
    };

    return (
        <div style={{ width: "100%", overflow: "hidden scroll", height: '100vh' }}>
            <Container>
                <Grid container justifyContent="center">
                    {success ? <Typography variant="h6" style={{ marginTop: '2rem' }}>Your password has been successfully changed.</Typography> : null}
                    {error ? <Typography variant="h6" style={{ marginTop: '2rem' }}>{error}</Typography> : null}
                    {verificationId
                        ? <div style={{ width: '100%', marginTop: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="verificationCode"
                                        label="Verification Code"
                                        type="verificationCode"
                                        id="verificationCode"
                                        autoComplete="verificationCode"
                                        value={verificationCode}
                                        onChange={(e) => {
                                            setVerificationCode(e.target.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: 3, marginBottom: 2 }}
                                onClick={handleVerificationCode}
                            >
                                Submit
                            </Button>
                        </div>
                        : <Grid item xs={12} md={8}>
                            <Typography variant="h6" style={{ marginTop: '2rem' }}>Change Password</Typography>
                            <TextField label="Current Password"
                                variant="filled"
                                type="password"
                                fullWidth
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                margin="normal" />
                            <TextField label="New Password"
                                variant="filled"
                                type="password"
                                fullWidth
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                }}
                                margin="normal" />
                            <TextField label="Confirm New Password"
                                variant="filled"
                                type="password"
                                fullWidth
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                }}
                                margin="normal" />

                            <div id="recaptcha-container-login"></div>

                            <Button variant="contained"
                                color="primary"
                                onClick={submitNewPassword}
                                style={{ marginTop: '1rem' }}>Change Password</Button>
{/* 
                            <Typography variant="h6" style={{ marginTop: '2rem' }}>Stripe Subscriptions</Typography>
                            <Button variant="contained" color="secondary" style={{ marginTop: '1rem' }}>Cancel Subscription</Button> */}
                        </Grid>
                    }
                </Grid>
            </Container>
        </div>
    );
};

export default ProfilePage;