import React, { useState} from 'react';
import PolicyContext from './context';

const PolicyContextProvider = ({ children }) => {
    const [showPolicy,setShowPolicy] = useState(false);
    return (
        <PolicyContext.Provider value={{showPolicy,setShowPolicy
        }}>
            {children}
        </PolicyContext.Provider>
    );
}

export default PolicyContextProvider;