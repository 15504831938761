const correctLabel = (allVariables,str,isShort)=>{
    const hs = (str) => {
        if (str.split("_").length === 3) {
            return str.split("_")[2]
        } else {
            return "1"
        }
    }
    const quickParse = (str) => {
        if (str.startsWith("independentClaimSummary")) {
            return "Claim Summary"
        }
        if (str.startsWith("independentClaim")) {
            return "Statement of Claim"
        }
        if (str.startsWith("indClaim1Dets")) {
            let claimNum = parseInt(allVariables['locationClaim'+hs(str)])
            return "Detailed Description "+claimNum+"."+ (parseInt(str.split("_")[1]) + 1)
        }

        if (str.startsWith("dependentClaims")) {
            return "Dependent Claims"
        }
        if (str.startsWith("prefixIndClaim")) {
            return "Preamble"
        }
        if (str.startsWith("detailDescriptions")) {
            return "Detailed Description " + (parseInt(str.split("_")[1]) + parseInt(allVariables['locationDepMin' + hs(str)]))
        }
        if (str.startsWith("field")) {
            return "Field of Invention"
        }
        if (str.startsWith("bgSection")) {
            return "Background"
        }
        if (str.startsWith("abstract")) {
            return "Abstract"
        }
        return str
    }
    const quickParseNoAbv = (str) => {
        if (str.startsWith("independentClaimSummary")) {
            return "Claim Summary"
        }
        if (str.startsWith("independentClaim")) {
            return "Indepedent Claim " + str.split("independentClaim")[1].split("_")[0]
        }
        if (str.startsWith("indClaim1Dets")) {
            return "Independent Claim 1, Detailed Description for Method Step " + (parseInt(str.split("_")[1]) + 1)
        }

        if (str.startsWith("dependentClaims")) {
            return "Dependent Claims of Indepedent Claim 1"
        }
        if (str.startsWith("detailDescriptions")) {
            return "Detailed Description for Dependent Claim " + (parseInt(str.split("_")[1]) + 2)
        }
        if (str.startsWith("field")) {
            return "Field of Invention"
        }

        if (str.startsWith("bgSection")) {
            return "Background"
        }

        if (str.startsWith("abstract")) {
            return "Abstract"
        }
        return str
    }
    if(isShort){
        return quickParse(str)
    }else{      
        return quickParseNoAbv(str)
    }
}

export default correctLabel;;