import React, { useContext, useEffect } from 'react';
import APIContext from './context';

export const useAPIDialog = (
    text,
    title,
    url,
    data,
    followUp,
    context,
    job,
    useLoading = false
) => {

    const { dialogResponseActions, completeDialogueResponse, apiWithDialog } = useContext(APIContext)

    useEffect(() => {
        if (dialogResponseActions.context === context && dialogResponseActions.job === job) {
            followUp(dialogResponseActions.response)
            completeDialogueResponse()
        }
    }, [dialogResponseActions])

    const fn = () => {
        apiWithDialog(text, title, { context: context, job: job, useLoading: useLoading }, url, data)
    }

    return fn
}


export const useMockDialog = (
    text,
    title,
    followUp,
    context,
    job,
    useLoading = false,
) => {

    const { dialogResponseActions, completeDialogueResponse, apiWithDialog } = useContext(APIContext)

    useEffect(() => {
        if (dialogResponseActions.context === context && dialogResponseActions.job === job) {
            followUp(...dialogResponseActions.fnArgs)
            completeDialogueResponse()
        }
    }, [dialogResponseActions])

    const fn = (...fnArgs) => {
        apiWithDialog(text, title, { context: context, job: job, useLoading: useLoading, mock:true,fnArgs:fnArgs }, "", "")
    }

    return fn
}

