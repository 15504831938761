import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useEffect, useRef } from 'react';
import { Typography } from "@mui/material";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { authFb } from "../../../auth/auth";
import { ALL_COPY } from "../../../constants/copy";
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

import BoringSection from "./BoringSection";

export default function Autoamte() {
  const [user, loading, error] = useAuthState(authFb);
  const navigate = useNavigate();
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  const headerBackgroundColor = '#313338';
  const textFlairColor = '#25A1B0';
  const textContrastColor = '#dddddd';

  if (true) {
    return (
      <ParallaxBanner
      >
                  <ParallaxBannerLayer style={{ zIndex: "-5" }}
          opacity={[1.0, 0.0, 'easeInOut']}
          translateY={["-300px", "0px"]}
          rootMargin={{ top: 700, right: 100, bottom: -600, left: 100 }}
          scale={[1.5, 1.1]}
        >
          {/* <img style={{ backgroundColor:'#fafafa',width: "100%", objectFit:"fill" }} src="./datacenter3.png" /> */}
        </ParallaxBannerLayer>

      <BoringSection
        imageSide="right"
        title="Automate Your Research"
        imgsrc="./research.svg"
        useTextBgColor='#F6F7F5' 
        height="98dvh">

        <p className="font20">
        <b>Upload up to 100 documents,</b> define your research criteria in plain language, and your Automaton will immediately provide a detailed analysis of document content.</p>
        <br />
        <p className="font20">
          <b>Connect to an existing legal research data source.</b> Quickly summarize or analyze research material from external data sources like EDGAR and LexisNexis.</p>
      </BoringSection>
      </ParallaxBanner>
    )
  }
  else {

    return (
      <Wrapper id="Automatons" className="container flexCenter">

        <LeftSide>
          <h2 className="font40 extraBold"
            style={{
              color: "rgb(53 53 53)",
              marginTop: "10px",
              marginBottom: "10px"
            }}>Automate Your Research</h2>
          <p className="font20">
            <b>Upload your documents</b> and lay out your research criteria in plain language, and your Automaton will do the work for you. (As many as 100 documents at a time)
          </p>
          <br />
          <p className="font20">
            <b>Or connect to an existing data source.</b> Utilize external data sources like EDGAR and LexisNexis and summarize your research material.</p>

          <br />
          <p className="font20"><b>Parse, merge, error-correct, and summarize </b> as per your own criteria
          </p>

        </LeftSide>
        <RightSide>
          <ImgWrapper>
            <img className="radius8"
              style={{
                border: "1px solid white",
                opacity: ".5"
              }}
              src={'./gen_opts.png'}
              alt="add" />
          </ImgWrapper>
        </RightSide>
      </Wrapper>
    );
  }
}

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const TitleText = styled.h1`

  @media (max-width: 600px) {
            h2 {
              font-size: 1.5em !important;
            }
          }
`;
const Wrapper = styled.section`
  width: 100%;
  min-height: 98dvh;
  height: max-content;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 40%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
  }
  @media (max-width: 560px) {
    margin: 10px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 60%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: -100px;
  }
  @media (max-width: 560px) {
    margin-top: 0px;
  }
`;
// const HeaderP = styled.div`
//   max-width: 470px;
//   padding: 15px 0 50px 0;
//   line-height: 1.5rem;
//   @media (max-width: 960px) {
//     padding: 15px 0 50px 0;
//     text-align: center;
//     max-width: 100%;
//   }
// `;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 30%;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
// const Img = styled.img`
//   @media (max-width: 560px) {
//     width: 80%;
//     height: auto;
//   }
// `;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
// const DotsWrapper = styled.div`
//   position: absolute;
//   right: -100px;
//   bottom: 100px;
//   z-index: 2;
//   @media (max-width: 960px) {
//     right: 100px;
//   }
//   @media (max-width: 560px) {
//     display: none;
//   }
// `;


