import Collector from '../Collector';
import { quickInput } from '../utilities';

const UploadStepDataCollector = ({ configureStep, stepData, errorDisplay }) => {
    return <Collector
        inputFields={[
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in.")
        ]}
    />
};

export default UploadStepDataCollector;