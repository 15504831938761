import { auth, googleProvider } from "../auth";
import {
    signInWithEmailAndPassword,
    signInWithPopup,
    setPersistence,
    browserLocalPersistence,
    getMultiFactorResolver,
    RecaptchaVerifier,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    // NONE,
} from "firebase/auth";
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { handleAuthError } from "..";
import { useNavigate } from "react-router-dom";
import { SiteGate, buttonStyle, lastButtonStyle } from "../sitegate";
import { useErrorModal } from "../../contexts/error";
import { goToMain } from "..";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Login() {
    const showError = useErrorModal();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState('');
    const [verificationCode, setVerificationCode] = useState(null)
    const [verificationId, setVerificationId] = useState(null)
    const [resolverObj, setResolverObj] = useState(null)
    const [recaptchaDone, setRecaptchaDone] = useState(false)
    const [renderRecaptcha, setRenderRecaptcha] = useState(false)
    // const [userObject, setUserObject] = useState(null)

    const signIn = async () => {
        try {
            // As httpOnly cookies are to be used, do not persist any state client side.
            setPersistence(auth, browserLocalPersistence);

            signInWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    // Signed in 
                    // console.log("[>] SIGN IN");
                    // console.log(userCredential);
                    // userCredential._tokenResponse.idToken
                    goToMain(navigate)
                })
                .catch((error) => {
                    if (error.code === 'auth/multi-factor-auth-required') {
                        // The user is a multi-factor user. Second factor challenge is required.
                        const resolver = getMultiFactorResolver(auth, error);

                        console.log(resolver.hints[0].factorId) // phone - TODO: add menu to select MFA method.


                        if (!window.loginRecaptchaVerifier) {
                            setRenderRecaptcha(true)
                        }

                        setResolverObj(resolver)
                        // ...
                    } else {
                        console.error("[-] SIGN IN ERROR");
                        showError(handleAuthError(error))
                    }
                });
        } catch (err) {
            showError(handleAuthError(err))
        }
    };

    const reSubmit = async () => {
        const phoneInfoOptions = {
            multiFactorHint: resolverObj.hints[0], // TODO: add menu to select MFA method.
            session: resolverObj.session
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.loginRecaptchaVerifier)
            .then((verificationId) => {
                // verificationId will be needed for sign-in completion.
                setVerificationId(verificationId);
            })
            .catch((error) => {
                // Error occurred during multi factor sign up.
                // ...
                setError("Please try again.")
                showError(handleAuthError(error))
            });
    }

    const signInWithGoogle = async () => {
        try {

            // TODO: backend: remove checks for email & phone.

            await signInWithPopup(auth, googleProvider)
                .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    // const credential = googleProvider.credentialFromResult(result);
                    // const token = credential.accessToken;

                    // const user = result.user;
                    goToMain(navigate)
                    // localStorage.setItem('emailForSignIn', email);
                    // return getNewSessionId(user, setError)
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                    // Save the email locally so we don't have to ask the user for it again if they open the link on the same device.
                }).catch((error) => {
                    console.log(error)
                    // Handle Errors here.
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    // The email of the user's account used.
                    // const email = error.customData.email;
                    // The AuthCredential type that was used.
                    // const credential = googleProvider.credentialFromError(error);

                    showError(handleAuthError(error))
                    // ...
                });
        } catch (err) {
            showError(handleAuthError(err))
        }
    };

    const handleVerificationCode = async (event) => {

        event.preventDefault();
        if (!verificationCode) {
            setError('Please enter the verification code you received over SMS.');
            return;
        }
        setError('');

        // Ask user for the verification code. Then:
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        // Complete sign-in. This will also trigger the Auth state listeners.
        resolverObj.resolveSignIn(multiFactorAssertion)
            .then(function (userCredential) {
                // userCredential will also contain the user, additionalUserInfo, optional
                // credential (null for email/password) associated with the first factor sign-in.

                // For example, if the user signed in with Google as a first factor,
                // userCredential.additionalUserInfo will contain data related to Google
                // provider that the user signed in with.
                // - user.credential contains the Google OAuth credential.
                // - user.credential.accessToken contains the Google OAuth access token.
                // - user.credential.idToken contains the Google OAuth ID token.
                // const user = userCredential.user;
                goToMain(navigate)

            }).catch((error) => {
                showError(handleAuthError(error))
            });
    };

    useEffect(() => {
        console.log("renderRecaptcha: " + renderRecaptcha + "; recaptchaDone: " + !recaptchaDone)
        if (renderRecaptcha) {
            window.loginRecaptchaVerifier = new RecaptchaVerifier(
                auth,
                'recaptcha-container-login', {
                size: 'invisible',
                callback: () => { /* reCAPTCHA solved, so: */ setRecaptchaDone(true) }
            })
            window.loginRecaptchaVerifier.render()
        }
        console.log("renderRecaptcha: " + renderRecaptcha + "; recaptchaDone: " + !recaptchaDone)
    }, [renderRecaptcha])

    useEffect(() => {
        console.log("renderRecaptcha: " + renderRecaptcha + "; recaptchaDone: " + !recaptchaDone)
    }, [renderRecaptcha, recaptchaDone])


    return (
        <SiteGate
            title="Login"
            error={error}
            buttons={verificationId
                ? <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={lastButtonStyle}
                    onClick={handleVerificationCode}>
                    Submit
                </Button>
                : <div style={{ width: '100%', padding: '0 2em' }}>
                    <div id="recaptcha-container-login"></div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={(renderRecaptcha && !recaptchaDone && console.log(renderRecaptcha + " " + recaptchaDone))}
                        style={buttonStyle}
                        onClick={renderRecaptcha ? reSubmit : signIn}>
                        Login
                    </Button>
                    {/* <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={buttonStyle}
                        onClick={signInWithGoogle}>
                        Signin With Google
                    </Button> */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={buttonStyle}
                        onClick={() => { navigate('/auth/passwordreset') }}>
                        Forgot Password?
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={lastButtonStyle}
                        onClick={() => { navigate('/') }}>
                        <ArrowBackIcon/>
                    </Button>
                </div>
            }>

            {verificationId
                ? <Grid container spacing={2}
                    style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="verificationCode"
                            label="Verification Code"
                            type="verificationCode"
                            id="verificationCode"
                            autoComplete="verificationCode"
                            value={verificationCode}
                            onChange={(e) => {
                                setVerificationCode(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                : <Grid container spacing={2}
                    style={{
                        width: '100%',
                    }}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </SiteGate>
    );
}

export default Login