import React, { useContext } from 'react';
import DesContext from '../../../../contexts/singleFlow/context';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const LowerRightButton = () => {
    const { manager} = useContext(DesContext);
    return (
                <div style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px'
                }}>
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={() => { manager.addStep(manager.steps.length + 1) }}
                        >
                        <AddIcon />
                    </Fab>
                </div>
    );
};

export default LowerRightButton;