
  // const photos = [
  //   'https://upload.wikimedia.org/wikipedia/commons/b/b7/Wright_First_Flight_1903Dec17_%28full_restore_115%2C_colorized%29.jpg', // colorized wright bros
  //   'https://upload.wikimedia.org/wikipedia/commons/8/8f/Ken_Thompson_%28sitting%29_and_Dennis_Ritchie_at_PDP-11_%282876612463%29.jpg', // Thompson and Ritchie at Bell Labs
  //   'https://en.wikipedia.org/wiki/File:Original_1976_Apple_1_Computer_In_A_Briefcase.JPG', // Original Apple 1
  //   'https://en.wikipedia.org/wiki/File:Samuel_Morse_with_his_Recorder_by_Brady,_1857.png', // Morse with Recorder
  //   'https://commons.wikimedia.org/wiki/File:Ford_1921.jpg', // Henry Ford with Model T
  //   'https://commons.wikimedia.org/wiki/File:Edison_and_phonograph_edit1.jpg', // Edison with Phonograph
  //   'https://commons.wikimedia.org/wiki/File:Twain_in_Tesla%27s_Lab.jpg', // Mark Twain in Tesla's Lab
  //   'https://commons.wikimedia.org/wiki/File:Nikola_Tesla,_with_his_equipment_Wellcome_M0014782_-_restoration2.jpg', // Mad Tesla with Equipment
  //   'https://commons.wikimedia.org/wiki/File:Alexander_Graham_Telephone_in_Newyork.jpg', // Alexander Graham Bell with Telephone
  //   'https://commons.wikimedia.org/wiki/File:Marie_Curie_c._1898.jpg', // Marie Curie
  //   'https://commons.wikimedia.org/wiki/File:Ada_Lovelace_portrait.jpg', // Ada Lovelace
  //   'https://commons.wikimedia.org/wiki/File:Grace_Hopper_and_UNIVAC.jpg', // Grace Hopper and UNIVAC
  //   'https://commons.wikimedia.org/wiki/File:James_Eckford_Lauder_-_James_Watt_and_the_Steam_Engine-_the_Dawn_of_the_Nineteenth_Century_-_Google_Art_Project.jpg', // James Watt Steam Engine

  // ]

  export const photos = [
    'https://upload.wikimedia.org/wikipedia/commons/b/b7/Wright_First_Flight_1903Dec17_%28full_restore_115%2C_colorized%29.jpg', // colorized wright bros
    // 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Ken_Thompson_%28sitting%29_and_Dennis_Ritchie_at_PDP-11_%282876612463%29.jpg', // Thompson and Ritchie at Bell Labs
    'https://upload.wikimedia.org/wikipedia/commons/b/b3/Marie_Curie_c._1898.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/6/68/Original_1976_Apple_1_Computer_In_A_Briefcase.JPG', // Original Apple 1
    // 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cc/Ford_1921.jpg/2560px-Ford_1921.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/3/37/Grace_Hopper_and_UNIVAC.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/0/03/Edison_and_phonograph_edit1.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/9/9e/Twain_in_Tesla%27s_Lab.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/3/36/James_Eckford_Lauder_-_James_Watt_and_the_Steam_Engine-_the_Dawn_of_the_Nineteenth_Century_-_Google_Art_Project.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/a/a4/Ada_Lovelace_portrait.jpg',
    // 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Nikola_Tesla%2C_with_his_equipment_Wellcome_M0014782_-_restoration2.jpg',
    'https://upload.wikimedia.org/wikipedia/commons/1/16/Alexander_Graham_Telephone_in_Newyork.jpg',
  ]
