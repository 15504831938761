import React from "react";
import styled from "styled-components";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import { ALL_COPY } from "../../../constants/copy";
import { Typography, Grid, Divider } from "@mui/material";
// import ClientSlider from "../Elements/ClientSlider";

const RoundedImage = ({ src, alt }) => {
  const containerStyle = {
    width: '250px',  // Adjust the size as needed
    height: '250px', // Adjust the size as needed
    overflow: 'hidden',
    // borderRadius: '5%',
    border: "1px solid black",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const imageStyle = {
    width: '250px',
    height: 'auto'
  };

  return (
    <div style={containerStyle}>
      <img src={src} alt={alt} style={imageStyle} />
    </div>
  );
};

const MemberProfile = ({ data, index }) => {
  const isMobile = window.innerWidth < 600;
  return (
    <Grid item
      sm={6} md={4.0} lg={3.0} xl={3.0} key={index}>

      <div style={{
        backgroundColor: "rgb(240 240 240)",
        width: "274px",
        height: "500px",
        borderRadius: "20px",
        padding: "12px",
        border: "1px solid red",

      }}>
        <RoundedImage src={data.image} alt="Add Image 1" />
        <Typography variant="h5" style={{ paddingTop: "10px" }} >

          <button type="submit" style={{ cursor: "pointer", border: "none", marginRight: "10px" }}>
            <img style={{ height: "20px", width: "20px" }} src="./linkedin.png" alt="linkedin" onClick={() => window.location = data.link} />
          </button>
          {data.name}</Typography>
        <Divider />
        <Typography variant="h6">{data.role}</Typography>
        <Divider />
      </div>

    </Grid>
  )
}


const MemberProfileTwo = ({ data, index }) => {
  const isMobile = window.innerWidth < 600;
  return (
    // <Grid item
    //   sm={6} md={4.0} lg={3.0} xl={3.0} key={index}>

    // for each card, 274px
    // width = (15px + 274px) * max number of cards that can fit on the screen.

    <div style={{
      // display: "flex",
      // flexDirection: "column",
      padding: "0px",
      // paddingLeft: "15px",
    }}>
      <div style={{
        backgroundColor: "rgb(240 240 240)",
        width: "274px",
        height: "600px",
        borderRadius: "20px",
        padding: "12px",
      }}>
        <RoundedImage src={data.image} alt="Add Image 1" />
        <Typography variant="h5" style={{ display: "flex", alignItems: "center", paddingTop: "10px" }} >

          <button type="submit" style={{ cursor: "pointer", border: "none", marginRight: "12px" }}>
            <img style={{ height: "23px", width: "auto" }} src="./linkedin_blue.png" alt="linkedin" onClick={() => window.location = data.link} />
          </button>
          {data.name}</Typography>
        <Divider />
        <Typography variant="h6">{data.role}</Typography>
        <p style={{fontSize:".9em"}}>
        {data.description}
        </p>
        <Divider />
      </div>
    </div>

    // </Grid>
  )
}


export default function Team() {
  const execTeam = [
    { name: "Awab Shamsi", role: "CEO/Founder", image: "./Team/awab2.png", description: "Engineer-turned-serial-entrepreneur. In a past life, Awab left grad-school to launch his first startup, and after a successful exit, spent the next 15 years leading Digital Transformation and Automation initiatives at Fortune 100s like Target Corp. and Accenture. He was doing AI before AI was cool. When life allows, Awab can be found with his lovely wife and three children enjoying the great outdoors under majestic Midwestern skies.", link: "https://www.linkedin.com/in/awab-shamsi-b2487382/" },
    { name: "Alicia Harper", role: "CTO/Co-founder", image: "./Team/alicia.png", description: "Mathematician-turned-entrepreneur. After her PhD in Mathematics from Brown University, Alicia joined Texas A&M for her post-doc fellowship where a series of happy accidents led her to passionately pursuing AI entrepreneurship. Since then Alicia has been trying to make robots serve humanity, when she isn’t dabbling in fine-art or perfecting some new recipe. ", link: "https://www.linkedin.com/in/alicia-harper-2b7715163/" }]
  const team = [
    { name: "Chloe Eggleston", role: "ML/AI Lead", image: "./Team/chloe_work.png", description: "Chloe is a NLP researcher at the University of Colorado. She explores adapting language modelling to specialty domains. Her research has investigated the diminishing returns of general domain approaches to understanding social media English, and the resultant trade-offs.", link: "https://www.linkedin.com/in/chloeeggleston" },
    { name: "Joyce Levine", role: "ML/AI Engineer", image: "./Team/joyce.png", description: "Eclectic programmer, AI optimist. Joyce is a cybersecurity researcher and has been exploring the intersection of AI-led automation and cybersecurity since her teens. Joyce finds zen in dissecting cybersecurity research papers and exploring urban landscape." },
    { name: "Ronald James", role: "Cybersecurity Lead", image: "./Team/ron.jpg", description: "Ron brings a wealth of cybersecurity leadership experience from his work with Defense and Financial Services sectors. He holds 4 patents in the cybersecurity domain for the innovative work he has done. When not defending the nation and its institutions against hackers, you will find Ron conquering terrain on his beloved mountain bike in the backwoods of Southern California.", link: "https://www.linkedin.com/in/rkuhlmeier023/" },

  ]
  return (
    <Wrapper id="team">
      {/* <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
      <div className="whiteBg" style={{ padding: "120px 0" }}>
        <div className="container">
          <HeaderInfo>
            {/* <Typography variant="h4"><span style={{ color: "#25A1B0" }}>O</span>ur Story</Typography> */}
            <br />
          </HeaderInfo>
          <div style={{ maxWidth: "800px" }}>

          </div>
          <div style={{ padding: "40px 0" }} />
          <Divider />
        </div>
        <div className="container" style={{
          "--card-width": "290px",
          marginTop: '120px',
        }}>

          {/* <Grid container alignItems="center"
            justifyContent="center"
            spacing={2}>
            {execTeam.map((data, index) => (
              <MemberProfile data={data} index={index} />
            ))}
          </Grid>

          <br />
          <br />

          <Grid container spacing={2}>
            {team.map((data, index) => (
              <MemberProfile data={data} index={index} />
            ))}
          </Grid> */}


          {/* <div style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <div style={{
              "--round-width": "round(to-zero, 100%, var(--card-width))",
              "--three-width": "calc(var(--card-width) * 3)",
              "--new-width": "min(var(--round-width), var(--three-width))",

              // width: "var(--new-width)",
              // display: "flex",
              // flexWrap: "wrap",
              // justifyContent: "space-between",
              // alignItems: "center",


              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              gap: "50px 20px",
            }}>
              {execTeam.map((data, index) => (
                <MemberProfileTwo data={data} index={index} />
              ))}
            </div>
          </div>

          <br />
          <br />

          <div style={{
            width: "100%",

            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px 21px",
          }}>
            {team.toReversed().map((data, index) => (
              <MemberProfileTwo data={data} index={index} />
            ))}
          </div> */}

          <div style={{
            width: "100%",

            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "50px 21px",
          }}>

            {team.toReversed().map((data, index) => (
              <MemberProfileTwo data={data} index={index} />
            ))}

            {execTeam.toReversed().map((data, index) => (
              <MemberProfileTwo data={data} index={index} />
            ))}

          </div>

        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 33%;
  margin-right: 5%;
  background-color: #f7f7f7;
  border-radius: 20px;  
  margin:5px;
  padding: 80px 20px;
  @media (max-width: 860px) {
    width: 98%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;