import React, { useState} from 'react';
import QuickStartContext from './context';

const QuickStartContextProvider = ({ children }) => {
    const [showQuickStart,setShowQuickStart] = useState(false);
    return (
        <QuickStartContext.Provider value={{showQuickStart,setShowQuickStart
        }}>
            {children}
        </QuickStartContext.Provider>
    );
}

export default QuickStartContextProvider;