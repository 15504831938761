import React from "react";
import {Container, Paper } from "@mui/material";
import Button from "@mui/material/Button";

function Sample() {
    const testAPI = async () => {};

    const testAPILoggedIn = async () => {
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper style={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
                <div style={{ width: '100%', marginTop: 3 }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 3, marginBottom: 2 }}
                        onClick={testAPI}
                    >
                        Test API
                    </Button>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: 3, marginBottom: 2 }}
                        onClick={testAPILoggedIn}
                    >
                        Test API but logged in
                    </Button>
                </div>
            </Paper>
        </Container>
    );
}

export default Sample