import React, { useContext, useState, useRef, useEffect } from 'react';
import {
    useUpdateNodeInternals,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Handle, Position } from 'reactflow';
import NodeEdgeContext from '../../contexts/figureDesign/context';
import './nodes.css';

const cap = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function HandleDisplay(props) {

    const { resizing } = useContext(NodeEdgeContext);
    const data = props.data
    const handles = ["top", "bottom", "left", "right"]
    const hideHandles = resizing
        ? resizing
        : data.hideHandles
            ? data.hideHandles
            : false;
    return (<div style={{ opacity: hideHandles ? 0 : 1 }}>
        {handles.map((handle) => {
            return <>
                {data[handle] ? <>
                    <Handle

                        type={"source"}
                        isConnectable={resizing ? false : true}
                        position={Position[cap(handle)]}
                        style={{ ...props.styleMod, ...props.styleModSpecific[handle], backgroundColor: hideHandles ? "rgb(0,0,0,0)" : (props.nodeIsSelected ? "blue" : "grey") }}
                        id={handle + 'source' + props.nodeId}
                    />
                </> : null}
            </>

        })}
    </div>)
}

function RectangleShape(props) {
    const styleModSpecific = { "top": {}, "bottom": {}, "left": {}, "right": {} }
    const borderBackground = !!props.useDashedLines // TODO: remove borderBackgroundImg
        ? null // `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='26' stroke-linecap='square'/%3e%3c/svg%3e")`
        : null;

    return <div
        className={props?.className}
        style={{
            // backgroundImage: borderBackground,
            backgroundColor: props.backgroundColor,
            borderRadius: "9px",
            zIndex: 1
        }}>
        {/* {props.data.useComponentNumber ? <div style={{ position: "absolute", top: -30, left: -30, color: "black", fontSize: ".8em" }}>{props.data.componentNumber}</div> : null} */}
        <div style={{
            color: "black",
            border: props.borderDisplay,
            borderRadius: "5px",
            padding: "10px"
        }}>
            {props.borderdiv}
            {props.children}
            <HandleDisplay nodeIsSelected={props.nodeIsSelected} styleMod={{}} styleModSpecific={styleModSpecific} nodeId={props.nodeId} data={props.data} />
        </div></div>
}

function CircleShape(props) {
    const styleModSpecific = { "top": {}, "bottom": {}, "left": {}, "right": {} }
    const [borderBackground, setBorderBackground] = useState("")
    useEffect(() => {
        setTimeout(() => {
            setBorderBackground(!!props.useDashedLines // TODO: REMOVE: borderBackgroundImg
                ? null //`url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50%' ry='50%' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='26' stroke-linecap='square'/%3e%3c/svg%3e")`
                : null)
        }, 5)
    }, [props.useDashedLines])
    return <div style={{ color: "black" }}>
        {/* {props.data.useComponentNumber ? <div style={{ position: "absolute", top: -30, left: -30, color: "black", fontSize: ".8em" }}>{props.data.componentNumber}</div> : null} */}
        <div className={props?.className}
            style={{
                display: 'flex',
                /* backgroundImage: borderBackground, */
                justifyContent: 'center',
                alignItems: 'center',
                background: '#fff',
                borderRadius: '50%',
                padding: "10px",
                aspectRatio: 1,
                border: props.borderDisplay
            }}>
            {props.borderdiv}
            {props.children}
            <HandleDisplay nodeIsSelected={props.nodeIsSelected} styleMod={{}} styleModSpecific={styleModSpecific} nodeId={props.nodeId} data={props.data} />
        </div></div>
}



function DiamondShape(props) {
    const styleModSpecific = { "top": { left: -5 }, "bottom": { left: "97%" }, "left": { top: "97%" }, "right": { top: "-3%" } }
    const [borderBackground, setBorderBackground] = useState("")
    useEffect(() => {
        setTimeout(() => {
            setBorderBackground(!!props.useDashedLines // TODO: remove borderBackgroundImg
                ? null // `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='26' stroke-linecap='square'/%3e%3c/svg%3e")`
                : null)
        }, 5)
    }, [props.useDashedLines])
    return <div className={props?.className} style={{ backgroundColor: "0000" }}><div style={{
        // width: '80px',
        // height: '80px',
        aspectRatio: 1 / 1,
        backgroundColor: '#fff',
        border: props.borderDisplay,
        // backgroundImage: borderBackground,
        color: "black",
        transform: 'rotate(45deg)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}>
        {props.borderdiv}
        <HandleDisplay nodeIsSelected={props.nodeIsSelected} styleMod={{ transform: 'rotate(-45deg)' }} styleModSpecific={styleModSpecific} nodeId={props.nodeId} data={props.data} />
        <div style={{ transform: 'rotate(-45deg)' }}>
            {/* Optional: Display node data or labels inside the diamond */}
            {props.children}
        </div>
    </div></div>
}


const FigureShapes = {
    rectangle: RectangleShape,
    diamond: DiamondShape,
    circle: CircleShape,
}
const FigureNode = ({ id, data }) => {
    const { nodes, edges,
        setNodes, setEdges,
        onNodesChange,
        selectedNode,
        onEdgesChange, modifyNodeData,
        ver, setVer,
    } = useContext(NodeEdgeContext);

    const updateNodeInternals = useUpdateNodeInternals();
    const newSize = useRef(null)
    const [resizable, setResizable] = useState(!!data.resizable);
    const [locked, setLocked] = useState(!!data.locked);

    const shape = data.boxStyle ? data.boxStyle : "rectangle"
    // console
    const Container = FigureShapes[shape]
    const handles = ["top", "bottom", "left", "right"]
    const hideHandles = data.hideHandles ? data.hideHandles : false;

    const [size, setSize] = useState({
        x: data?.padding?.x ? data?.padding?.x : 0,
        y: data?.padding?.y ? data?.padding?.y : 0
    });
    const borderDisplay = !!data.useDashedLines
        ? "" // TODO: useDashedLines Problem
        : "1px solid black";
    const backgroundColor = !!data.groupNode ? "" : "white";
    const nodeIsSelected = selectedNode === id
    const handler = (mouseDownEvent) => {
        const startSize = size;
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

        function onMouseMove(mouseMoveEvent) {
            const newSize = {
                x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
                y: startSize.y - startPosition.y + mouseMoveEvent.pageY
            }
            setSize(newSize);
            modifyNodeData(id, { padding: newSize })

            // setSize(currentSize => ({
            //     x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
            //     y: startSize.y - startPosition.y + mouseMoveEvent.pageY
            // }));
        }
        function onMouseUp() {
            document.body.removeEventListener("mousemove", onMouseMove);
            // uncomment the following line if not using `{ once: true }`
            // document.body.removeEventListener("mouseup", onMouseUp);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

    return (
        <Container
            borderdiv={data?.useDashedLines ? <div className='dotted-node-inside' /> : null}
            className={data?.useDashedLines ? "dot-figure-node" : ""}
            useDashedLines={data.useDashedLines} nodeIsSelected={nodeIsSelected} backgroundColor={backgroundColor} borderDisplay={borderDisplay} nodeId={id} data={data}>

            {locked
                ? <div style={{
                    position: 'absolute',
                    bottom: '100%',
                    // top: data.componentNumber ? '-3em' : '-2em',
                    left: 0,
                    right: 0,
                    margin: 'auto',

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <label style={{
                        whiteSpace: 'pre-wrap',
                        textAlign: 'center',
                    }}>
                        {data?.label ? data.label : "place holder"}
                        {data.useComponentNumber
                            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <div style={{
                                    color: "#888",
                                    fontSize: ".8em"
                                }}>{data.componentNumber}</div></div>
                            : null}
                    </label>
                </div>
                : null}

            <div style={{
                width: 'fit-content', height: 'fit-content',
                padding: `${size.y}px ${size.x}px`,
            }}>
                <div style={{
                    whiteSpace: 'pre-wrap',
                    // textAlign: 'center',
                    paddingTop: data.useComponentNumber ? '0.1em' : 'auto',
                }}>
                    <label style={{
                        opacity: locked ? 0 : 1,
                        fontSize: "15px"
                    }}>
                        {data?.label ? data.label : "place holder"}
                    </label>
                    <label style={{
                        opacity: locked ? 0 : 1,
                    }}>
                        {data.useComponentNumber
                            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <div style={{
                                    color: "#888",
                                    fontSize: ".75em"
                                }}>{data.componentNumber}</div></div>
                            : null}
                    </label>
                </div>

                {resizable
                    ? <div style={{
                        position: 'absolute',
                        width: '1em',
                        height: '1em',
                        right: 2,
                        bottom: 2,
                    }}>
                        <div style={{
                            resize: 'both',
                            overflow: 'auto',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                        }} />
                        <button id="draghandle"
                            type="button"
                            onMouseDown={handler}
                            style={{
                                cursor: 'se-resize',
                                position: 'absolute',
                                opacity: 0,
                                width: '100%',
                                height: '100%',
                            }}></button>
                    </div>
                    : null}
            </div>
        </Container>
    )
}

export default FigureNode;