import React, { useState } from 'react';
import { Box, Tab, Tabs } from "@mui/material";
import Markdown from "react-markdown";
import Button from "@mui/material/Button";
import GPTPromptEditor from "./gpt";
import InputEditor from "./inputs";
import GeneralEditor from "./general";
import DescriptionBlock from "./description";
import "../../../App.css"
import ScriptEditor from './script';
//a
const Viewer = ({ value }) => {
    return (<div>
        <DescriptionBlock title={"Raw Output"}>
            This is the raw output of the variables produced by the application. For testing purposes only.
            <br />
        </DescriptionBlock>
        {Object.keys(value).map((key) => {
            return (
                <div style={{ backgroundColor: "#182223", padding: "15px", marginTop: "15px", marginBottom: "15px", borderRadius: "15px" }}>
                    <div style={{ fontSize: "1.2em", marginTop: "10px", marginBottom: "10px" }}>
                        <b>Variable Name: {key}</b>
                    </div>
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {value[key]}
                    </div>
                </div>
            )
        })}
    </div>)

}

const NodeEditor = (props) => {
    const [value, setValue] = useState(1);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="General" {...a11yProps(0)} />
                        <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="Inputs" {...a11yProps(1)} />
                        <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="Prompts" {...a11yProps(2)} />
                        <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="Edit State" {...a11yProps(3)} />
                        {/* <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="Vars" {...a11yProps(4)} /> */}
                        <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="Pre" {...a11yProps(4)} />
                        <Tab style={{ color: "#adadad", fontSize: ".8em" }} label="Post" {...a11yProps(5)} />
                    </Tabs>
                </Box>
                <div style={{
                    marginLeft: "20px",
                    marginTop: "20px",
                    overflowY: "scroll",
                    /* scrollbarGutter: 'stable both-edges', // TODO: scrollbar gutter? */
                    height: "80vh"
                }}>
                    {value === 0 ? <GeneralEditor {...props} /> : null}
                    {value === 1 ? <InputEditor {...props} /> : null}
                    {value === 2 ? <GPTPromptEditor {...props} /> : null}
                    {value === 3 ? <div></div> : null}
                    {/* {value === 4 ? <Viewer value={props.evalState.variables} /> : null} */}
                    {value === 4 ? <ScriptEditor scriptType={"pre"} {...props} /> : null}
                    {value === 5 ? <ScriptEditor scriptType={"post"} {...props} /> : null}
                </div>
            </Box>

        </div>
    )
        ;
}
    ;

export default NodeEditor;
