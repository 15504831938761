import Collector from '../Collector';
import { quickCheck, quickInput } from '../utilities';

const GenerativeStepCollector = ({ configureStep, stepData, errorDisplay,stepId,testState,macrosObject,...other}) => {
    return <Collector
        inputFields={[
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in.",false,1),
            quickInput(stepData, configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM",true,8,true,{...macrosObject,...testState})
        ]}
        checkboxFields={[
            quickCheck(stepData, configureStep, "Use Multiparse?", "useMultiParse", "Add me"),
            quickCheck(stepData, configureStep, "Generate a Table?", "isTable", "Add me")]}
    />
};

export default GenerativeStepCollector;