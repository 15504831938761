import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { handleAuthError } from "..";
import { auth } from '../auth';
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    RecaptchaVerifier,
    multiFactor,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator
} from "firebase/auth";
import { SiteGate, lastButtonStyle } from "../sitegate";
import { useErrorModal } from "../../contexts/error";

function Phone() {
    const showError = useErrorModal();
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState('')
    const [verificationCode, setVerificationCode] = useState(null)
    const [error, setError] = useState(null)
    const [verificationId, setVerificationId] = useState(null)
    const [phoneEnrolled, setPhoneEnrolled] = useState(false)
    const [recaptchaDone, setRecaptchaDone] = useState(false)
    const [phoneVerified, setPhoneVerified] = useState(false)

    const doMultiFactor = () => {

        multiFactor(auth.currentUser).getSession()
            .then((multiFactorSession) => {
                // Specify the phone number and pass the MFA session.
                addPhoneNumber(multiFactorSession)
            }).catch((error) => {
                showError(handleAuthError(error))
            });
    }

    const addPhoneNumber = async (multiFactorSession) => {
        const phoneInfoOptions = {
            phoneNumber: phone,
            session: multiFactorSession
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth)
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
            .then((verificationId) => {
                // verificationId will be needed to complete enrollment.
                setVerificationId(verificationId)
            })
            .catch(async (error) => {
                // Error occurred during multi factor sign up.
                showError(handleAuthError(error))
                setError("Please try again.")
                setRecaptchaDone(false)
            });
    }

    const handleSubmit = async (event) => {

        event.preventDefault();
        if (!password) {
            setError('Please enter your password.');
            return;
        }
        if (!phone) {
            setError('Please enter your phone number.');
            return;
        }
        setError('');

        const credential = EmailAuthProvider
            .credential(auth.currentUser.email, password)

        reauthenticateWithCredential(auth.currentUser, credential).then(
            () => { // User re-authenticated.
                doMultiFactor()
            }).catch((error) => {
                if (error.code === 'auth/multi-factor-auth-required') {
                    // already have phone lol
                } else {
                    showError(handleAuthError(error))
                }
            })
    };

    const handleVerificationCode = async (event) => {
        event.preventDefault();
        if (!verificationCode) {
            setError('Please enter the verification code you received over SMS.');
            return;
        }
        setError('');

        // Ask user for the verification code. Then:
        const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        // Complete enrollment.
        multiFactor(auth.currentUser)
            .enroll(multiFactorAssertion, "Phone Factor")
            .then((r) => {
                setPhoneEnrolled(true)
            }).catch((error) => {
                showError(handleAuthError(error))
            })
    }

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'recaptcha-container', {
            size: 'invisible',
            callback: () => { /* reCAPTCHA solved, so: */ setRecaptchaDone(true) }
        })
        window.recaptchaVerifier.render()
    }, [])

    return (

        <SiteGate title={phoneEnrolled ? "Success!" : "Add Phone Number"} error={error}
            buttons={verificationId
                ? !phoneEnrolled && <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={lastButtonStyle}
                    disabled={(phoneEnrolled && console.log(phoneEnrolled))}
                    onClick={handleVerificationCode}>
                    Submit
                </Button>
                : <div style={{ width: '100%' }}>
                    <div id="recaptcha-container"></div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={(!recaptchaDone && console.log(recaptchaDone))}
                        style={lastButtonStyle}
                        onClick={handleSubmit}>
                        Add Phone
                    </Button>
                </div>
            }>

            {/* {verificationId && phoneEnrolled && <div>success!</div>} */}
            {verificationId
                ? !phoneEnrolled && <Grid container spacing={2}
                    style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="verificationCode"
                            label="Verification Code"
                            type="verificationCode"
                            id="verificationCode"
                            value={verificationCode}
                            onChange={(e) => {
                                setVerificationCode(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
                : <Grid container spacing={2}
                    style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="phone"
                            label="Phone Number"
                            type="phone"
                            id="phone"
                            autoComplete="phone"
                            value={phone}
                            onChange={(e) => {
                                setPhone(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>
            }
        </SiteGate>
    );
}

export default Phone