import React, { useState, useContext } from "react";
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import PolicyContext from "../../../../contexts/policy/context.js";
import FirmSignUpContext from '../../../../contexts/firm/context.js';
import LandingContext from "../../../../contexts/landing/context.js";
import QuickStartContext from "../../../../contexts/quickstart/context.js";

const LandingSidebarMenu = (props) => {
    const { setShowPolicy } = useContext(PolicyContext);
    const { manager } = useContext(LandingContext);
    const {showQuickStart,setShowQuickStart} = useContext(QuickStartContext);
    const { setShowFirmSignUp, firmData, pendingFirmInviteBy, setShowInvitationModal } = useContext(FirmSignUpContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const theme = useTheme();
    const firmInviteHandler = () => { setShowInvitationModal(true); handleClose() }
    const quickStartHandler = () => { setShowQuickStart(true); handleClose() }
    const firmPageHandler = () => { manager.setOpenPage("firm"); handleClose(); }
    const firmSignUpHandler = () => { setShowFirmSignUp(true); handleClose(); }
    const profileHandler = () => { manager.setOpenPage("profile"); handleClose(); }
    const termsOfServiceHandler = () => { setShowPolicy(true); handleClose(); }
    return (
        <div style={{
            flex: '0 1 fit-content', width: '100%', padding: '0.5em',
            // clipPath: "polygon(0 0, 90%  0, 100% 100%, 0 100%)",
            borderTop: "1px solid black",
            backgroundColor: theme.palette.primary.main,
        }}>
            <IconButton style={{ color: 'inherit' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {pendingFirmInviteBy !== "" ?
                    <MenuItem onClick={firmInviteHandler}>Confirm Firm Invitation</MenuItem> : null}
                {/* {firmData ?
                    <MenuItem onClick={firmPageHandler}>Firm</MenuItem> :
                    <MenuItem onClick={firmSignUpHandler}>Firm</MenuItem>} */}
                <MenuItem onClick={profileHandler}>Settings</MenuItem>
                <MenuItem onClick={quickStartHandler}>Quickstart Guide</MenuItem>
                <MenuItem onClick={termsOfServiceHandler}>Terms of Service</MenuItem>
                <MenuItem onClick={manager.logOut}>Logout</MenuItem>
                {/* <MenuItem onClick={() => { props.setOpenPage("checkout"); handleClose(); }}>Checkout</MenuItem> */}
                {/* <MenuItem onClick={() => { setShowFirmSignUp(true); handleClose(); }}>FirmSIGNUP</MenuItem> */}

            </Menu>
            {/* TODO: Fix dark mode */}
            {/* <IconButton style={{ color: 'inherit' }} onClick={props.toggleColorMode}>
                <Brightness4Icon />
            </IconButton> */}
        </div>
    )
}

export default LandingSidebarMenu;