import React, { useContext, useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography, Container, Fade, useTheme, Button } from '@mui/material';
import APIContext from '../../../contexts/api/context';

const Marketplace = () => {
    const {api} = useContext(APIContext)
    const theme = useTheme()
    const [patentLawyers, setPatentLawyers] = useState([])
    useEffect(() => {
        api('/ptntst/getPatentLawyers', {}).then(res => {
            setPatentLawyers(res.data.lawyerList)
        })
    }, [])
    return (
        <div style={{ width: "100%", overflow: "auto", height: "100vh" }}>
            <Container>
                <div>
                    <div style={{ maxWidth: "800px", padding: "25px", backgroundColor: theme.palette.background.default }}>
                        <Fade in={true} timeout={1000}>
                            <Typography variant="h4" style={{ marginTop: '2rem' }}>Connect with a patent attorney...</Typography>

                        </Fade>
                        <Typography variant="body1" style={{ marginTop: '2rem' }}>Now that you've drafted your patent, lets try to connect you with a professional to get it carefully reviewed.</Typography>
                    </div>
                </div>

                <Grid container spacing={2}>
                    {patentLawyers.map((item, index) => (
                        <Fade in={true} timeout={1000 * index} key={item.id}>
                            <Grid item xs={12} key={item.id}>  {/* Full width for each item */}
                                <Card style={{ width: "800px", borderRadius: "5px" }}>
                                    <div style={{ display: "flex", width: "800px", height: "250px" }}>
                                        <div>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={item.imageUrl}
                                                alt={item.title}
                                                style={{ maxWidth: '400px', padding: "20px" }}
                                                sx={{ borderRadius: "5px" }}
                                            />
                                        </div>
                                        <div style={{ width: '100%', margin: "10px", marginTop: "43px", padding: "30px", height: 'fit-content', borderRadius: "5px" }}>
                                            <Typography variant="body2" color="text.secondary">
                                                {item.address.split('\n').map((text, index) => (
                                                    <React.Fragment key={index}>
                                                        {text}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Typography>
                                        </div>
                                    </div>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.title} ({item.firm})
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.description.split('\n').map((text, index) => (
                                                <React.Fragment key={index}>
                                                    {text}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                            <Button variant="contained" color="primary" style={{ marginTop: '1rem' }}>Contact</Button>
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Fade>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default Marketplace;