import React from 'react';
import SequenceHandler from '../../client';

function PreviewModeWrapper({ previewMode, currentlySelectedNode }) {
    if (!previewMode) {
        return null;
    }

    return (
        <div style={{ position: "fixed", top: "10px", zIndex: 2000000 }}>
            <div style={{ transform: 'scale(.9)', border: "solid 1px white" }}>
                <SequenceHandler currentlySelectedNode={currentlySelectedNode} freshSequence={true} directive={"patentCreation"} />
            </div>
        </div>
    );
}

export default PreviewModeWrapper;