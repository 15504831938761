import React, { useEffect, useState ,useContext} from 'react';
import {
    Typography,
    Button,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { ALL_COPY } from '../constants/copy';
import APIContext from '../contexts/api/context';

function EndPage(props) {
    const { api } = useContext(APIContext)
    const [needsReview, setNeedsReview] = useState(false);
    const [confirmationSubmitted, setConfirmationSubmitted] = useState(false);
    const [reviewReq, setReviewReq] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleClose = () => {
        setOpenConfirmation(false);
    };
    useEffect(() => {
        api('/ptntst/patentNeedsReview', { patentId: props.patentId }).then(res => {
            if (!!res.data.needsReview) {
                if (!!!res.data.reviewEmailSent) {
                    setNeedsReview(!!res.data.needsReview)
                }
            }
        })
    }, []);
    return (<div style={{ width: "100%" }}><div
        style={{
            display: 'flex',
            height: "100vh",
            maxWidth: "800px",
            overflowY: "hidden",
            /* scrollbarGutter: 'stable both-edges', // TODO: scrollbar gutter? */
            flexDirection: 'column',
            overflowX: "hidden",
        }}
    >
        <Typography variant="h5" gutterBottom style={{ marginLeft: "20px", marginTop: "50px", marginBottom: "20px" }}>
            {ALL_COPY.endPageHeader}
        </Typography>
        <Typography variant="body1" gutterBottom
            style={{
                margin: '20px 0px 0px',
                marginLeft: "20px",
                marginBottom: "20px",
            }}>{ALL_COPY.endPageDownloadPatent} {needsReview ? ALL_COPY.endPageDownloadOpt1 : ALL_COPY.endPageDownloadOpt2}
        </Typography>
        <div style={{
            width: '100%',
            display: 'flex',
            paddingTop:"30px",
            justifyContent: 'start',
            alignItems: 'center',
        }}>
            <Button
                onClick={props.downloadPatentWord}
                variant="contained"
                color="primary"
                style={{ margin: "0px 20px", paddingLeft: '12px', width: "100px" }}
                type="submit">
                <DownloadIcon /> Patent
            </Button>
            <Button
                onClick={props.downloadFiguresWord}
                variant="contained"
                color="primary"
                style={{ paddingLeft: '12px', width: "108px" }}
                type="submit">
                <DownloadIcon /> Figures
            </Button>
            {needsReview && !!!reviewReq ? <Button
                onClick={() => {
                    setOpenConfirmation(true)
                    setConfirmationSubmitted(false)
                }}
                variant="contained"
                color="primary"
                style={{ marginLeft: "12px", paddingLeft: '12px', width: "210px" }}
                type="submit">
                Request Review.
            </Button> : null}
            <Dialog
                open={openConfirmation}
                onClose={handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{"Confirm Submission"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {confirmationSubmitted ? ALL_COPY.endPageConfirmSubmit : ALL_COPY.endPageAskSubmit}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    {!confirmationSubmitted ? <Button onClick={() => {
                        api('/ptntst/sendReviewEmail', { patentId: props.patentId }).then(res => {
                            setConfirmationSubmitted(true);
                            setReviewReq(true);
                        })
                    }} autoFocus>
                        Confirm
                    </Button> : null}
                </DialogActions>
            </Dialog>

        </div>

    </div></div>)
}

export default EndPage;