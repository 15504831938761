import React, {useContext } from "react";
import DesContext from "../../../../contexts/singleFlow/context";
import SidebarInternalWrapper from "../SidebarInternalWrapper";
import LexFileDropzone from "../../../Lex/LexFileDropzone";
import LexTypography from "../../../Lex/LexTypography";

const Files = ({ stepId }) => {
    const desCtx = useContext(DesContext);
    const desManager = desCtx.manager;
    const files = desManager.staticFiles;
    return (
        <SidebarInternalWrapper>
                 <div style={{paddingBottom:"30px"}}>
            <LexTypography variant="h6" style={{paddingBottom:"15px"}}>
                File Management
            </LexTypography>
            <LexTypography variant="body1">
                Lex Automata allows you to use your files in your automata, so you may query, search, and summary them at will.
            </LexTypography>
            </div>
            <LexFileDropzone
                files={files}
                uploadFuncton={desManager.uploadStaticFiles}
                dependencies={[desManager.staticFiles]}
                deleteFunction={(index) => () => desManager.deleteStaticFile(index, stepId)}
            />
        </SidebarInternalWrapper>
    )
}

export default Files;