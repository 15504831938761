import React, { useLayoutEffect, useState,useContext } from "react";
import styled from "styled-components";
import { useEffect, useRef } from 'react';
import { Typography, useTheme } from "@mui/material";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { authFb } from "../../../auth/auth";
import { ALL_COPY } from "../../../constants/copy";
import NET from 'vanta/dist/vanta.net.min'
import { Logo } from "../../../components/logoComponent";
import ContactContext from "../../../contexts/contact/context";
// Assets
// import HeaderImage from "../../assets/img/header-img.png";
// import QuotesIcon from "../../assets/svg/Quotes";
// import Dots from "../../assets/svg/Dots";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const VideoWithOverlay = () => {
  const videoSource = "patentistVid.mp4";  // Replace with your video path
  const imageSource = "ztablet2.png";  // Replace with your image path
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener('timeupdate', () => {
      if (video.currentTime > 46) {
        video.currentTime = 0;
        video.play();
      }
    });
    return () => {
      video.removeEventListener('timeupdate');
    };
  }, []);
  return (
    <div style={{ position: 'relative', aspectRatio: "16/9", width: "550px" }}>
      <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}  >
        {/* <video playsInline preload="auto" ref={videoRef} autoPlay="autoplay" muted className="Video" style={{ width: '100%', padding: "3%", paddingLeft: "5%" }}> */}
        <img src="video.jpg" ref={videoRef} className="Video" style={{ width: '100%', padding: "3%", paddingLeft: "5%" }} />
        {/* <source src={videoSource} type="video/mp4" /> */}
        Your browser does not support the video tag.
        {/* </video> */}
      </div>
      <div style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}  >
        <img src={imageSource} alt="Overlay" style={{ width: "100%", height: "100%" }} />
      </div>
    </div>
  );
}


export default function Header({teamPage, setOnTeamPage}) {
  const {showModal,setShowModal} = useContext(ContactContext);
  const theme = useTheme();
  const [user, loading, error] = useAuthState(authFb);
  const navigate = useNavigate();
  const [width, height] = useWindowSize();
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  const headerBackgroundColor = '#313338';
  const textFlairColor = '#25A1B0';
  const textContrastColor = '#dddddd';
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(NET({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        minWidth: 200.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0xe5e5bf,
        backgroundColor: headerBackgroundColor,
        points: 20.00,
        showDots: true
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  const showBreak = width > 960;
  return (
    <div ref={myRef}>
      <Wrapper id="home" className="homepageFace container flexCenter">

        <LeftSide className="flexCenter" style={{paddingRight:"100px",paddingTop:"130px"}}>
          <HomepageLogo>
            <Logo color={theme.palette.text.primary} format="main_logo" />
          </HomepageLogo>
        </LeftSide>
        <RightSide>
          {/* calc(100vh - 110px) */}
          {/* <div style={{
            // padding: "50px",
            // border: "1px solid #e5e5bf",
            // borderRadius: "20px",
            // width: 'auto',
            // backgroundColor: headerBackgroundColor
          }} > */}
          <CopyFace headerBackgroundColor={headerBackgroundColor}>
            <TitleText className="extraBold font67" style={{ color: textContrastColor }}>Legal Automation<br /></TitleText>

            <TitleSubtext className="extraBold font55" style={{ color: textFlairColor }}>
              {showBreak
                ? <br />
                : null}
              AI-assisted,
              <br />
              Human-perfected.
            </TitleSubtext>

            {/* <GhostBreak /> */}

            <Typography variant="body1" gutterBottom style={{ paddingTop:"15px", color: textContrastColor }}>
            Lex Automata is your trusted partner in secure legal automation. We put the power of AI in your hands. Feel confident as you focus on your legal craft, while your Automata does the heavy lifting for you. This is intelligent process automation, perfected.
            </Typography>

            <GhostBreak />

            {/* <Typography variant="body2" gutterBottom>
            {ALL_COPY.patentistHeaderNote}</Typography> */}

            <GhostBreak />

            {/* </HeaderP> */}
            {!!!user
              ? (<BtnWrapper>
                <FullButton BGcolor={textFlairColor}
                  title="Contact Us"
                  action={() => { setShowModal(true) }} />
              </BtnWrapper>)
              : (<BtnWrapper>
                <FullButton BGcolor={textFlairColor}
                  title="View Drafts"
                  action={() => { window.location = "/auth/main" }} />
              </BtnWrapper>)
            }

          </CopyFace>
        </RightSide>
      </Wrapper>
    </div>

  );
}

const CopyFace = styled.div`
  padding: 50px;
  border: 1px solid #e5e5bf;
  border-radius: 20px;
  width: auto;
  background-color: ${(props) =>
  (props.headerBackgroundColor
    ? props.headerBackgroundColor
    : "#0005")};

  @media (max-width: 960px) {
    --remaining-height: calc(100vh - 112px);
    --unit-h: calc(var(--remaining-height) / 20);

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px;
    margin: var(--unit-h) 0px;
    /* 110px is 80px + 2 x 15px, the height of the top bar and the height of the side padding. */
    height: calc(var(--unit-h) * 18); 
  }
`

const GhostBreak = styled.br`
  @media (max-width: 960px) {
    display: none;
  }
`

const HomepageLogo = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`
const TitleText = styled.h1`
  @media (max-width: 600px) {
    line-height: 1em;
    font-size: 2rem;
    h2 {
      font-size: 1.5em !important;
    }
  }
`;

const TitleSubtext = styled.h2`
  @media (max-width: 600px) {
    font-size: 1.8rem;
  }
`

const Wrapper = styled.section`
  width: 100%;
  min-height: 98dvh;
  height: max-content;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 40%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    // text-align: center;
  }
  @media (max-width: 560px) {
    margin: 10px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 60%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: -100px;
  }
  @media (max-width: 560px) {
    margin-top: 0px;
  }
`;
// const HeaderP = styled.div`
//   max-width: 470px;
//   padding: 15px 0 50px 0;
//   line-height: 1.5rem;
//   @media (max-width: 960px) {
//     padding: 15px 0 50px 0;
//     text-align: center;
//     max-width: 100%;
//   }
// `;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 30%;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
// const Img = styled.img`
//   @media (max-width: 560px) {
//     width: 80%;
//     height: auto;
//   }
// `;
// const QuoteWrapper = styled.div`
//   position: absolute;
//   left: 0;
//   bottom: 50px;
//   max-width: 330px;
//   padding: 30px;
//   z-index: 99;
//   @media (max-width: 960px) {
//     left: 20px;
//   }
//   @media (max-width: 560px) {
//     bottom: -50px;
//   }
// `;
// const QuotesWrapper = styled.div`
//   position: absolute;
//   left: -20px;
//   top: -10px;
// `;
// const DotsWrapper = styled.div`
//   position: absolute;
//   right: -100px;
//   bottom: 100px;
//   z-index: 2;
//   @media (max-width: 960px) {
//     right: 100px;
//   }
//   @media (max-width: 560px) {
//     display: none;
//   }
// `;


