import app_name from './name';

export const ALL_COPY = {

    paymentPageCopy1: "Our pricing, like our process, is transparent and straightforward. Inventors using our app have two options, both of which include the fully generated downloadable patent draft.",
    paymentPageCopy2: "Patent + Review",
    paymentPageCopyArray1: ['Your patent draft application, which is covered by our 100% money back guarantee.', 'Access to our figure tool for dynamically generating the description paragraphs associated with figures.**', 'A comprehensive review and feedback notes from our team of veteran patent lawyers.', 'You draft your patent application and sync up with one of our hand-picked legal partners for next steps.'],
    paymentPageCopyArray2: ['Select a legal partner from LexConnect, and an AI-assisted draft will be sent to them.', 'Your legal partner will reach out to you and set an appointment.'],
    paymentPageCopyFootnote1: " * Please note that the quality of your patent drafts depends upon the quality of your claim inputs. However, we stand behind our product and guarantee you complete satisfaction. If you are not happy with your draft, contact us and we will either fix it or give you your money back!",
    paymentPageCopyFootnote2: "**Figure generation: limited to 10 figures + 20 regenerations per figure.",
    firmPageCopy1: "This is where you can add users from your organization. Each account comes with 3 seats that you can assign. If you require more seats, please contact us at",
    firmPageCopy2: "and we can customize your plan with add-ons.",
    firmPageFootnoteCopy1: "If you have any technical issues or have questions about your account, please do not hesitate to contact us at",
    firmPageFootnoteCopy2: "and our team will get back to you within a business day.",
    endPageHeader: "Congratulations, you have completed your draft!",
    endPageDownloadPatent: "You may download your patent application draft below. We've pre-generated one figure for you, which you may enable in the settings page of the figure editor.",
    endPageDownloadOpt1: "When you are done editing your patent application draft and preparing your figures, you may submit it for review by our team of legal experts.",
    endPageDownloadOpt2: "We recommend that you seek the services of a patent attorney or law firm to review and revise your application draft for the best possible outcomes.",
    endPageConfirmSubmit: "Excellent. One of our legal experts will send you a review within the next (3) business days.",
    endPageAskSubmit: "Are you ready to submit your patent application draft for review?",
    services1: "You put in your claims, press a button, and we bring together generative AI, NLP and decades of legal experience to draft your patent application for you, within minutes! ",
    services2: "Our platform connects inventors with some of the top-minds in the IP law industry. Your patent draft is just the first step in designing your IP strategy, and you need an experienced legal partner. We specialize in connecting inventors with some of the best legal talent on the planet.",
    services3: "AI-assisted Office Action responses; we offer best-in-class Office Action strategies, including both remarks and recommended amendments (coming by end of April)",
    servicesTextMain: app_name+" is leading the way in blending human legal expertise with multi-pass and few-shot based patent generation.",
    servicesTextSecond: "Your security is paramount. We are an American-owned and operated company headquartered in Minneapolis, MN.  We work closely with our partners to ensure that your data does not get stored outside of our servers or used to train AI models. Our users may delete their submitted data at any point in time.",
    projectsMain: "With the reach of LexConnect, we bring the right people together. Our unique patent analysis algorithm empowers inventors to choose legal partners which will best serve their IP strategy needs. Once chosen, the legal partner will receive the claims set right in their inbox for consultation. Bringing together inventors and IP practitioners doesn’t get easier than this!",
    projectsTagline: "Connecting clients with lawyers",
    projectsHeader: "LexConnect",
    patentistHeader: "Lex Automata is your trusted partner in secure legal automation. We put the power of AI in your hands.nds. Feel confident as you focus on your legal craft, while your Automata do the heavy-lifting for you. This is intelligent process automation, perfected.",
    checkoutReturn1: "We appreciate your business! A confirmation email will be sent to",
    checkoutReturn2: "If you have any questions, please email",
    patentistPrice1: [
        { name: "Your patent draft, which is covered by our 100% money back guarantee.", cheked: true },
        { name: "A comprehensive review and feedback notes from our team of veteran patent lawyers.", cheked: true },
        { name: "You draft your patent application and sync up with one of our hand-picked legal partners for next steps.", cheked: true },
    ],
    patentistPrice2: [
        { name: "Best in class AI-assisted patent draft and figure generation tool", cheked: true },
        { name: "Access to LexConnect where we connect your business with inventors. Inventor information and claims are delivered right to your inbox", cheked: true },
        { name: "Soon-to-launch automated Office Action response suite", cheked: true },
        { name: "No yearly contracts, cancel anytime!", cheked: true },
    ],
    patentistPrice3: [
        { name: "Select a legal partner from LexConnect, and an AI-assisted draft will be sent to them.", cheked: true },
        { name: "Recommended for inventors", cheked: true },
        { name: "Your legal partner will reach out to you and set an appointment.", cheked: true },
    ],
    firmStatus1:"Aside from offering individual and firm-wide subscription plans, LexConnect connects individual inventors with lawyers for draft submissions, office action responses, review, and other aspects of IP management.",
    firmStatus2:"If you or your firm is interested in leaning more, you may click Yes below. Otherwise, you may apply to join LexConnect at any time from the main menu.",
    firmStatusHeader:"Do you have a patent practice?",
}