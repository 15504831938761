import {useContext } from 'react';
import LexFileDropzone from '../../Lex/LexFileDropzone';
import DesContext from '../../../contexts/singleFlow/context';
import { Typography } from '@mui/material';

const DocGenUploadCollector = ({ configureStep, stepData, errorDisplay, stepIndex, stepId }) => {
    let { manager} = useContext(DesContext);
    const files = stepData?.template ? [stepData?.template] : []
    const mode = 'template'
    console.log(manager)
    const delFn = manager.deleteFile(mode)
    return <div>
        {stepData.template? <Typography sx={{fontWeight:"500"}} variant="body1">Currently utilizing {stepData.template?.origName}</Typography>:null}
        <LexFileDropzone 
        files={files}
        uploadFuncton={manager.uploadFiles({ configureStep, stepData, stepIndex, stepId,mode})}
        dependencies={[configureStep, stepData]}
        deleteFunction={(index)=>()=>delFn({ configureStep, stepData, files, stepIndex, stepId })} 
        />
    </div>
}

export default DocGenUploadCollector;