import React, {useContext } from "react";
import { useTheme, Button } from '@mui/material';
import { IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ProfilePage from '../Profile/index.js';
import Marketplace from '../Marketplace/index.js';
import ClientWrapper from '../../../client/clientWrapper.js';
import UserManagement from '../Firm/index.js';
import AutomataProcessDesign from '../../Automata/AutomataProcessDesign/index.js';
import LandingSidebar from '../LandingSidebar/index.js';
import LandingContext from "../../../contexts/landing/context.js";
import PatentProcessContext from "../../../contexts/patent/context.js";
import MobileMenu from "./MobileMenu/index.js";
import WelcomePage from "../WelcomePage/index.js";

const LandingPage = (props) => {
    const theme = useTheme();
    const {manager,openPage} =useContext(LandingContext);
    const {patentManager} = useContext(PatentProcessContext);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            overflow: 'hidden',
        }}>
            <MobileMenu/>
            <div style={{
                flex: '1 1 auto',
                display: "flex",
                flexDirection: "row",
                overflow: 'hidden',
            }}>
                <LandingSidebar
                    refreshSidebar={manager.refreshSidebar}
                    drawerOpen={manager.drawerOpen}
                    toggleColorMode={props.toggleColorMode}
                    editPatent={patentManager.editPatent}
                    toggleDrawer={manager.toggleDrawer}
                    setProfileOpen={manager.setProfileOpen}
                    setOpenPage={manager.setOpenPage}
                />
                {(openPage === "home" || (!!!patentManager.activePatent && openPage === "client")) && <WelcomePage setOpenPage={manager.setOpenPage}/>}
                {openPage === "profile" && <ProfilePage />}
                {openPage === "marketplace" && <Marketplace />}
                {openPage === "automation" && <div>automation goes here!</div>}
                {openPage === "flow" && <AutomataProcessDesign />}
                {openPage === "firm" && <UserManagement />}
                {openPage === "client" && (patentManager.activePatent &&
                    <ClientWrapper
                        key={patentManager.activePatent}
                        setOpenPage={manager.setOpenPage}
                        setSidebarUsable={manager.setSidebarUsable}
                        setRefreshSidebar={manager.setRefreshSidebar}
                        patentId={patentManager.activePatent}
                        drawerOpen={manager.rightDrawerOpen}
                        setDrawerOpen={manager.setRightDrawerOpen}
                        toggleDrawer={manager.toggleRightDrawer} />)}
            </div>
        </div>
    );
}

export default LandingPage;