import React from "react";
import styled from "styled-components";
import { ParallaxBanner, useParallax, Parallax } from "react-scroll-parallax";
const childMapper = (children) => React.Children.map(children, (child) => child)

const SideDecider = ({ side, imageSide, imgsrc, title, children, useTextBgColor, customImgComponent }) => {
  if (side === imageSide) {
    if (customImgComponent) {
      const CustomImageComponent = customImgComponent;
      return (<Sider side={side} order={2}>
        <CustomImageComponent />
      </Sider>)
    }
    return (
      <Sider side={side} order={2}>
        <ImgWrapper>
          {imgsrc === "none"
            ? null
            : <img
              // className="radius8"
              style={{
                backgroundColor: useTextBgColor? useTextBgColor: null,
                borderRadius: useTextBgColor? "20px": null,
                // border: "1px solid white",
                opacity: "1" /* was .5 */
              }}
              src={imgsrc}
              alt="add" />}
        </ImgWrapper>
      </Sider>
    )
  }
  else {
    return (
      <Sider side={side} order={1}>
        <div style={useTextBgColor ? { zIndex: "200", padding: "20px", borderRadius: "30px", backgroundColor: useTextBgColor } : null}>
          <h2 className="font40 extraBold"
            style={{
              color: "rgb(53 53 53)",
              marginTop: "10px",
              marginBottom: "10px"
            }}>{title}</h2>
          {childMapper(children)}
        </div>
      </Sider>
    )
  }
}

export default function BoringSection(props) {
  return (
    <Wrapper height={props.height} id="Automatons" className="container flexCenter">

      <SideDecider
        side="left"
        useTextBgColor={props.useTextBgColor}
        customImgComponent={props.customImgComponent}
        imageSide={props.imageSide}
        imgsrc={props.imgsrc}
        title={props.title}
        children={props.children} />

      <SideDecider
        side="right"
        useTextBgColor={props.useTextBgColor}
        customImgComponent={props.customImgComponent}
        imageSide={props.imageSide}
        imgsrc={props.imgsrc}
        title={props.title}
        children={props.children} />

    </Wrapper>
  )
}



const Wrapper = styled.section`
  width: 100%;
  postition: absolute;
  min-height: ${(props) => props.height ? props.height : "70dvh"};
  height: max-content;
  z-index: 100000;
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 2em 2em 4em;
  }
`;

const Sider = ({ side, children, order }) => side === "left"
  ? <LeftSide order={order} >{childMapper(children)}</LeftSide>
  : <RightSide order={order} >{childMapper(children)}</RightSide>;

const LeftSide = styled.div`
  width: 40%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: ${({ order }) => order}/*2*/;
    margin: 50px 0;
  }
  @media (max-width: 560px) {
    margin: 10px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 60%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: ${({ order }) => order}/*1*/;
    margin-top: -100px;
  }
  @media (max-width: 560px) {
    margin-top: 0px;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-top: 30%;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;




