/**
 * This function checks if there are any loops in the graph.
 * A loop is defined as a path that starts and ends at the same node.
 *
 * @param {Array} edges - An array of edges, each represented as an object with 'source' and 'target' properties.
 * @param {Array} nodes - An array of nodes, each represented as an object with an 'id' property.
 * @returns {boolean} - Returns 'true' if no loops are detected, 'false' otherwise.
 */
export const checkForLoops = (edges, nodes) => {
    let visited = new Set();
    let stack = [{ node: nodes[0], path: [] }];

    while (stack.length > 0) {
        let { node: currentNode, path } = stack.pop();

        if (path.includes(currentNode.id)) {
            // If the current node is already in the path, we have a loop
            return false;
        }

        // Add the current node to the path
        path = [...path, currentNode.id];

        // Mark the current node as visited
        visited.add(currentNode.id);

        // Get all the edges for the current node
        let currentNodeEdges = edges.filter(edge => edge.source === currentNode.id);

        // For each edge
        currentNodeEdges.forEach(edge => {
            // Get the target node for the edge
            let targetNode = nodes.find(node => node.id === edge.target);

            // Add the target node to the stack along with the current path
            stack.push({ node: targetNode, path });
        });
    }

    // If no loops are detected, return true
    return true;
}

//isDirectedAndConnected: This function takes react-flow nodes and edges as arguments, and ensures that every node in a react-flow graph belongs to a single directed and connected component.
/**
 * This function checks if the graph is directed and connected.
 * A graph is directed if all edges have a direction (from a source node to a target node).
 * A graph is connected if there is a path from any node to any other node.
 *
 * @param {Array} nodes - An array of nodes, each represented as an object with an 'id' property.
 * @param {Array} edges - An array of edges, each represented as an object with 'source' and 'target' properties.
 * @returns {boolean} - Returns 'true' if the graph is directed and connected, 'false' otherwise.
 */
export function isDirectedAndConnected(nodes, edges) {
    // Create a Set to store visited nodes
    let visitedNodes = new Set();
    // Create an array to store nodes that need to be visited, starting with the first node
    let nodesToVisit = [nodes[0].id];

    // While there are nodes to visit
    while (nodesToVisit.length > 0) {
        // Pop a node from the nodes to visit
        let currentNode = nodesToVisit.pop();
        // Add the node to the visited nodes
        visitedNodes.add(currentNode);

        // Get all the outgoing edges for the current node
        let outgoingEdges = edges.filter(edge => edge.source === currentNode);
        // For each outgoing edge
        for (let edge of outgoingEdges) {
            // If the target node has not been visited, add it to the nodes to visit
            if (!visitedNodes.has(edge.target)) {
                nodesToVisit.push(edge.target);
            }
        }
    }

    // If the number of visited nodes is equal to the total number of nodes, the graph is connected
    return visitedNodes.size === nodes.length;
}

//correctStartAndEnd: This function takes react-flow nodes and edges as arguments, and ensures that the directed graph starts at a node with id='start' and ends at a node with id='end'.
/**
 * This function checks if the graph has a correct start and end node.
 * A graph has a correct start and end node if there is a path from the start node to the end node.
 *
 * @param {Array} nodes - An array of nodes, each represented as an object with an 'id' property.
 * @param {Array} edges - An array of edges, each represented as an object with 'source' and 'target' properties.
 * @returns {boolean} - Returns 'true' if there is a path from the start node to the end node, 'false' otherwise.
 */
export function correctStartAndEnd(nodes, edges) {
    // Find the start and end nodes
    let startNode = nodes.filter(node => node.id === 'start')[0];
    let endNode = nodes.filter(node => node.id === 'end')[0];

    // If either the start or end node is missing, return false
    if (!startNode || !endNode) {
        return false;
    }

    // Create a Set to store visited nodes
    let visitedNodes = new Set();
    // Create an array to store nodes that need to be visited, starting with the start node
    let nodesToVisit = [startNode.id];

    // While there are nodes to visit
    while (nodesToVisit.length > 0) {
        // Pop a node from the nodes to visit
        let currentNode = nodesToVisit.pop();
        // Add the node to the visited nodes
        visitedNodes.add(currentNode);

        // Get all the outgoing edges for the current node
        let outgoingEdges = edges.filter(edge => edge.source === currentNode);
        // For each outgoing edge
        for (let edge of outgoingEdges) {
            // If the target node has not been visited, add it to the nodes to visit
            if (!visitedNodes.has(edge.target)) {
                nodesToVisit.push(edge.target);
            }
        }
    }

    // If the end node has been visited, there is a path from the start node to the end node
    return visitedNodes.has(endNode.id);
}

//startAndEnd: This function takes react-flow nodes and edges as arguments, and ensures that the graph has at least two nodes, one with id='start' and one with id='end'.
/**
 * This function checks if the graph has a correct start and end node.
 * A graph has a correct start and end node if there is a path from the start node to the end node.
 *
 * @param {Array} nodes - An array of nodes, each represented as an object with an 'id' property.
 * @param {Array} edges - An array of edges, each represented as an object with 'source' and 'target' properties.
 * @returns {boolean} - Returns 'true' if there is a path from the start node to the end node, 'false' otherwise.
 */
export function startAndEnd(nodes, edges) {
    // Find the start and end nodes
    let startNode = nodes.filter(node => node.id === 'start')[0];
    let endNode = nodes.filter(node => node.id === 'end')[0];

    // If either the start or end node is missing, return false
    if (!startNode || !endNode) {
        return false;
    }

    // Create a Set to store visited nodes
    let visitedNodes = new Set();
    // Create an array to store nodes that need to be visited, starting with the start node
    let nodesToVisit = [startNode.id];

    // While there are nodes to visit
    while (nodesToVisit.length > 0) {
        // Pop a node from the nodes to visit
        let currentNode = nodesToVisit.pop();
        // Add the node to the visited nodes
        visitedNodes.add(currentNode);

        // Get all the outgoing edges for the current node
        let outgoingEdges = edges.filter(edge => edge.source === currentNode);
        // For each outgoing edge
        for (let edge of outgoingEdges) {
            // If the target node has not been visited, add it to the nodes to visit
            if (!visitedNodes.has(edge.target)) {
                nodesToVisit.push(edge.target);
            }
        }
    }

    // If the end node has been visited, there is a path from the start node to the end node
    return visitedNodes.has(endNode.id);
}

//deepMergeObjects: This function takes two objects as arguments, and returns a new object that is the result of merging the two objects. If the two objects have the same key, the value of the key in the second object is used.
/**
 * This function deeply merges two objects.
 * If a key exists in both objects:
 *   - and the values are both objects, it recursively merges the two values.
 *   - and at least one value is not an object, it uses the value from the second object.
 * If a key exists in only one object, it uses the value from that object.
 *
 * @param {Object} obj1 - The first object to merge.
 * @param {Object} obj2 - The second object to merge.
 * @returns {Object} - The merged object.
 */
export function deepMergeObjects(obj1, obj2) {
    // Initialize the result as an empty object
    let result = {};

    // Iterate over all keys in the first object
    for (let key in obj1) {
        // If the key does not exist in the second object, or the corresponding value in the second object is not an object, or is an array,
        // use the value from the first object
        if (!(key in obj2) || typeof obj2[key] !== 'object' || Array.isArray(obj2[key])) {
            result[key] = obj1[key];
        }
        // If the corresponding value in the first object is an object and not an array,
        // recursively merge the two values
        else if (typeof obj1[key] === 'object' && !Array.isArray(obj1[key])) {
            result[key] = deepMergeObjects(obj1[key], obj2[key]);
        }
    }

    // Iterate over all keys in the second object
    for (let key in obj2) {
        // If the key does not exist in the first object, or the corresponding value in the first object is not an object, or is an array,
        // use the value from the second object
        if (!(key in obj1) || typeof obj1[key] !== 'object' || Array.isArray(obj1[key])) {
            result[key] = obj2[key];
        }
    }

    // Return the merged object
    return result;
}

/**
 * Function to validate the start node in a graph
 * @param {Array} nodes - An array of node objects, each with an 'id' property
 * @param {Array} edges - An array of edge objects, each with 'source' and 'target' properties
 * @returns {boolean} - Returns true if the start node is valid, false otherwise
 */
export function validateStartNode(nodes, edges) {
    // Find the start node
    const startNode = nodes.find(node => node.id === 'start');

    // If there is no start node, return false
    if (!startNode) {
        return false;
    }

    // Find edges that have the start node as their source
    const startNodeEdges = edges.filter(edge => edge.source === startNode.id);

    // Check if the start node connects to more than one node
    if (startNodeEdges.length > 1) {
        return false;
    }

    // Check if the start node is a target in any of the edges
    const isStartNodeTarget = edges.some(edge => edge.target === startNode.id);

    // If the start node is a target, return false
    if (isStartNodeTarget) {
        return false;
    }

    // If none of the above conditions are met, return true
    return true;
}

/**
 * Function to check if there is a path between two nodes in a graph
 * @param {Array} nodes - An array of node objects, each with an 'id' property
 * @param {Array} edges - An array of edge objects, each with 'source' and 'target' properties
 * @param {string} node1 - The id of the first node
 * @param {string} node2 - The id of the second node
 * @returns {boolean} - Returns true if there is a path from node1 to node2, false otherwise
 */
export function hasPath(nodes, edges, node1, node2) {
    // Create an adjacency list representation of the graph
    const graph = {};
    edges.forEach(edge => {
        // If the source node is not in the graph, add it
        if (!graph[edge.source]) graph[edge.source] = [];
        // Add the target node to the list of neighbors of the source node
        graph[edge.source].push(edge.target);
    });

    // Helper function to perform depth-first search
    const dfs = (node, visited) => {
        // If the current node is node2, we have found a path
        if (node === node2) return true;
        // If the current node has been visited, stop the search
        if (visited.has(node)) return false;
        // Mark the current node as visited
        visited.add(node);

        // Get the neighbors of the current node
        const neighbors = graph[node] || [];
        // If the current node has exactly one source and one target
        if (neighbors.length === 2) {
            // Get the edges that have the current node as their target
            const sources = edges.filter(edge => edge.target === node);
            // If the current node has exactly one source, stop the search
            if (sources.length === 1) return false;
        }

        // Continue the search with the neighbors of the current node
        return neighbors.some(neighbor => dfs(neighbor, visited));
    };

    // Start the search with node1
    return dfs(node1, new Set());
}

/**
 * Function to find all nodes that have a path to a specific node
 * @param {Array} nodes - An array of node objects, each with an 'id' property
 * @param {Array} edges - An array of edge objects, each with 'source' and 'target' properties
 * @param {Object} specificNode - The specific node object
 * @returns {Array} - Returns an array of nodes that have a path to the specific node
 */
export function findNodesWithPathsToNode(nodes, edges, specificNode) {
    // If there are no edges, return an empty array
    if (edges.length === 0) return [];

    // Create an adjacency list representation of the graph
    const graph = createAdjacencyList(edges);

    // Find all nodes that have a path to the specific node
    const result = nodes.filter(node => node.id !== specificNode.id && canReachSpecificNode(node.id, specificNode.id, graph, new Set()));

    return result;
}

function createAdjacencyList(edges) {
    const graph = {};
    edges.forEach(edge => {
        if (!graph[edge.source]) graph[edge.source] = [];
        graph[edge.source].push(edge.target);
    });
    return graph;
}

function canReachSpecificNode(currentNode, specificNode, graph, visited) {
    if (currentNode === specificNode) return true;
    if (visited.has(currentNode)) return false;

    visited.add(currentNode);

    return (graph[currentNode] || []).some(neighbor => canReachSpecificNode(neighbor, specificNode, graph, visited));
}


export function extractIntegerUserInputs(nodes) {
    const result = [];

    nodes.forEach(node => {
        const userInputs = node.data.store.userInputs;
        userInputs.forEach(userInput => {
            if (userInput.dataType === 'integer') {
                result.push({
                    nodeId: node.id,
                    label: userInput.label
                });
            }
        });
    });

    return result;
}


export function exactlyOnePath(nodes, edges, node1, node2) {
    // Create an adjacency list representation of the graph
    const graph = createAdjacencyListOther(edges);
    if (node1.id === node2.id) {
        return false
    }
    // Check that node1 has exactly one target and node2 has exactly one source
    if (!hasExactlyOneTarget(node1.id, edges) || !hasExactlyOneSource(node2.id, edges)) {
        return false;
    }

    // Start from node1 and iterate along the successor nodes until reaching node2
    let currentNode = node1.id;
    while (currentNode !== node2.id) {
        const successors = graph[currentNode];
        if (!successors || successors.length !== 1) {
            return false;
        }
        currentNode = successors[0];
    }

    return true;
}

function createAdjacencyListOther(edges) {
    const graph = {};
    edges.forEach(edge => {
        if (!graph[edge.source]) graph[edge.source] = [];
        graph[edge.source].push(edge.target);
    });
    return graph;
}

function hasExactlyOneTarget(nodeId, edges) {
    return edges.filter(edge => edge.source === nodeId).length === 1;
}

function hasExactlyOneSource(nodeId, edges) {
    return edges.filter(edge => edge.target === nodeId).length === 1;
}


//isGood: This function runs all of the above tests to ensure that the graph is valid and otherwise returns a string describing the error.
export function isGood(nodes, edges) {
    if (!startAndEnd(nodes, edges)) {
        return 'Graph must start at the Start node and end at the End node.';
    } else if (!correctStartAndEnd(nodes, edges)) {
        return 'Graph must start at the Start node and end at the End node.';
    } else if (!isDirectedAndConnected(nodes, edges)) {
        return 'Graph must be directed and connected.';
    } else if (!validateStartNode(nodes, edges)) {
        return 'The initial node may only be connected to a single other node. The initial node may not be the target of any other nodes.';
    } else if (!checkForLoops(edges, nodes)) {
        return 'Graph must not contain a loop.';
    } else {
        return true;
    }
}

/**
 * Function to check if all input variables in the graph are declared before they are used
 * @param {Array} nodes - An array of node objects, each with an 'id' and 'data' property
 * @param {Array} edges - An array of edge objects, each with 'source' and 'target' properties
 * @returns {boolean} - Returns true if all input variables are declared before use, false otherwise
 */
export function checkInputVars(nodes, edges) {
    // Set to store the names of all declared variables
    let declaredVars = new Set();

    // Create a map of node ids to nodes for easy lookup
    let nodeMap = new Map(nodes.map(node => [node.id, node]));

    // Array to store the nodes in the order they are visited
    let sortedNodes = [];
    // Set to store the ids of visited nodes
    let visited = new Set();

    // Visit each edge in the order they are given
    for (let edge of edges) {
        // If the source node of the edge has not been visited, add it to sortedNodes and mark it as visited
        if (!visited.has(edge.source)) {
            sortedNodes.push(nodeMap.get(edge.source));
            visited.add(edge.source);
        }
        // If the target node of the edge has not been visited, add it to sortedNodes and mark it as visited
        if (!visited.has(edge.target)) {
            sortedNodes.push(nodeMap.get(edge.target));
            visited.add(edge.target);
        }
    }

    // Visit each node in the order they were added to sortedNodes
    for (let node of sortedNodes) {
        // If the node has data and a store
        if (node.data && node.data.store) {
            // If the store has userInputs, add each userInput label to declaredVars
            if (node.data.store.userInputs) {
                for (let input of node.data.store.userInputs) {
                    declaredVars.add(input.label);
                }
            }

            // If the store has gptPrompts, check each prompt
            if (node.data.store.gptPrompts) {
                for (let prompt of node.data.store.gptPrompts) {
                    for (let promptSeq of prompt.promptSeq) {
                        // If the promptSeq has an outputVar, add it to declaredVars
                        if (promptSeq.outputVar) {
                            declaredVars.add(promptSeq.outputVar);
                        }

                        // Split the inputVars string into an array of variable names
                        let inputVars = promptSeq.inputVars.split(',');
                        // Check each variable name
                        for (let varName of inputVars) {
                            // If the variable name is not empty and it has not been declared, return false
                            if (varName && !declaredVars.has(varName)) {
                                return false;
                            }
                        }
                    }
                }
            }
        }
    }

    // If all input variables have been declared before they are used, return true
    return true;
}

/**
 * Function to remove the start and end nodes from a graph
 * @param {Array} nodes - An array of node objects, each with an 'id' property
 * @param {Array} edges - An array of edge objects, each with 'source' and 'target' properties
 * @returns {Object} - An object containing the new nodes and edges arrays, with the start and end nodes and their connected edges removed
 */
export function removeStartEndNodes(nodes, edges) {
    // Filter out the nodes with id 'start' or 'end'
    const newNodes = nodes.filter(node => node.id !== 'start' && node.id !== 'end');

    // Filter out the edges connected to nodes with id 'start' or 'end'
    const newEdges = edges.filter(edge => edge.source !== 'start' && edge.source !== 'end' && edge.target !== 'start' && edge.target !== 'end');

    // Return the new nodes and edges arrays
    return { newNodes, newEdges };
}


// Function to create a map of node ids to nodes
function createNodeMap(nodes) {
    return new Map(nodes.map(node => [node.id, node]));
}

// Function to sort the nodes based on the edges
function sortNodes(nodes, edges) {
    let sortedNodes = [];
    let visited = new Set();
    let stack = [edges[0].source]; // Start with the source of the first edge

    while (stack.length > 0) {
        let currentNodeId = stack.pop();

        if (!visited.has(currentNodeId)) {
            sortedNodes.push(nodes.get(currentNodeId));
            visited.add(currentNodeId);

            // Get all the edges for the current node
            let currentNodeEdges = edges.filter(edge => edge.source === currentNodeId);

            // Add the target nodes to the stack
            for (let edge of currentNodeEdges) {
                if (!visited.has(edge.target)) {
                    stack.push(edge.target);
                }
            }
        }
    }

    return sortedNodes;
}

// Function to process a node and update the varMap
function processNode(node, varMap) {
    if (node.data && node.data.store) {
        // Add user input labels to varMap
        if (node.data.store.userInputs) {
            for (let input of node.data.store.userInputs) {
                if (!varMap.has(input.label)) {
                    varMap.set(input.label, { firstDetected: node.id, referencedIn: [node.id] });
                }
            }
        }

        // Check inputVars in gptPrompts
        if (node.data.store.gptPrompts) {
            for (let prompt of node.data.store.gptPrompts) {
                for (let promptSeq of prompt.promptSeq) {
                    // Add outputVar to varMap
                    if (promptSeq.outputVar && !varMap.has(promptSeq.outputVar)) {
                        varMap.set(promptSeq.outputVar, { firstDetected: node.id, referencedIn: [node.id] });
                    }

                    let inputVars = promptSeq.inputVars.split(',');
                    for (let varName of inputVars) {
                        if (varName) {
                            if (!varMap.has(varName)) {
                                varMap.set(varName, { firstDetected: node.id, referencedIn: [node.id] });
                            } else {
                                varMap.get(varName).referencedIn.push(node.id);
                            }
                        }
                    }
                }
            }
        }
    }
}

export function mapVarsToNodes(nodes, edges) {
    let varMap = new Map();

    let nodeMap = createNodeMap(nodes);
    let sortedNodes = sortNodes(nodeMap, edges);

    for (let node of sortedNodes) {
        processNode(node, varMap);
    }

    // Convert the Map to an object
    let varObj = Object.fromEntries(varMap);
    return varObj;
}