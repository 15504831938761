import React from "react";
import { Tab, Tabs, Box } from '@mui/material';

const LexTabMenu = ({ optionList, sidebarType, setSidebarType,changeHandler }) => {
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    return (
        <Box sx={{ color: 'primary.children', borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
                textColor="inherit"
                TabIndicatorProps={{ sx: { bgcolor: "primary.children" } }}
                value={sidebarType}
                onChange={changeHandler}
                aria-label="basic tabs example">
                {optionList.map((option, index) => {
                    return <Tab style={{ color: "white" }} label={option.label}
                        {...a11yProps(index)} />
                })}
            </Tabs>
        </Box>)
};

export default LexTabMenu;