import SegmentIcon from '@mui/icons-material/Segment';
import DocGenUploadCollector from '../../../components/Automata/DocGenUploadCollector';
import DocGenDownload from '../../../components/Automata/DocGenDownload';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';

export const documentGenerateStep = {
    overview: {
        name: 'documentGenerateStep',
        label: 'Generate a Word document',
        sublabel: 'You may insert any generated text into a Word document template of your choosing.',
        icon: <TextSnippetSharpIcon />,
        click: ({ configureStep, stepData, manager, errorDisplay }) => {
            configureStep.set({
                name: 'documentGenerateStep',
                labelDisplay: "Template",
                collectedFields: [],
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    testDataDisplay: {
        enabled: true,
        header: 'Download your completed document',
        description: '',
        collectorComponent: (props) => <DocGenDownload {...props} />

    },
    primaryOptions: {
        header: 'Upload your template',
        description: 'Please upload a document template.',
        collectorComponent: (props) => <DocGenUploadCollector {...props} />,
        buttonOpts: [],
        inputOpts: [],
    },
    default: {

    }


};