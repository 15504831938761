import FolderIcon from '@mui/icons-material/Folder';
import { List, ListItem, IconButton, Avatar, ListItemText, ListItemAvatar,Box, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone'
import { useCallback} from 'react';
import LexTypography from '../LexTypography';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const DeleteButton = ({ deleteFunction }) => {
    return (
        <IconButton edge="end" aria-label="delete" onClick={deleteFunction}>
            <DeleteIcon />
        </IconButton>
    );
}

function LexFileDropzone({ uploadFuncton, deleteFunction, files, dependencies }) {
    const onDrop = useCallback(uploadFuncton, dependencies);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });
    return (
        <div>
            {/* <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div> */}
            <Box
            {...getRootProps()}
            sx={{
                border: '2px dashed #cccccc',
                borderRadius: '4px',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '200px',
                backgroundColor: '#f9f9f9',
                flexDirection: 'column',
                '&:hover': {
                    backgroundColor: '#f1f1f1',
                },
            }}
        >
            <input {...getInputProps()} />
            <InsertDriveFileIcon sx={{ fontSize: 50, color: '#cccccc', marginBottom: '10px' }} />
            <LexTypography variant="body1" sx={{fontWeight:"500"}} color="textSecondary">
                Drag and Drop Your Files Here
            </LexTypography>
        </Box>
            {files.length > 0?<LexTypography variant="body1" sx={{fontWeight:"500",paddingTop:"15px"}} color="textSecondary">Files Uploaded:</LexTypography>:null}
            <List dense={true}>
                {files.map((file, index) => {
                    return (<>
                        <ListItem secondaryAction={<DeleteButton deleteFunction={deleteFunction(index)} />}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={file.origName}/>
                           
                        </ListItem>
                        <Divider />
                        </>
                        )
                })}
            </List>
        </div>
    );
}

export default LexFileDropzone;