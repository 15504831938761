import './App.css';
import React, { useEffect, useState, useContext } from "react";
import {
    CssBaseline,
    CircularProgress,
    ThemeProvider
} from '@mui/material';
import { theme } from './theme';
import { Route, Routes } from "react-router";
import { useLocation } from 'react-router-dom';
import Auth from "./auth";
import { EmailVerification } from './auth/emailVerification';
import { auth } from './auth/auth';
import Login from './auth/login';
import Phone from './auth/phone';
import Registration from './auth/registration/registration';
import ForgotPassword from './auth/forgotPassword';
import { ErrorModal } from './contexts/error';
import Game from './game';
import { CheckoutForm } from './components/Stripe/CheckoutForm/index.js';
import { CheckoutReturn } from './components/Stripe/CheckoutReturn/index.js';
import { authFb } from './auth/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import TermsOfServiceModal from './components/Modals/TermsOfServiceModal/index.js';
import HomePageV2 from './home/App';
import { axiosWrapper } from './auth';
import { useNavigate } from 'react-router-dom';
import NodeEdgeProvider from './contexts/figureDesign/index.js';
import PolicyContextProvider from './contexts/policy/index.js';
import FirmSignUpContextProvider from './contexts/firm/index.js';
import FirmSignUpModal from './components/Modals/FirmSignUpModal/index.js';
import Design from './depriciated/design/design.js';
import ClearProvider from './contexts/figureDesignClearContext/index.js';
import { FirmStatus } from './auth/firmStatus/index.js';
import AutomataProcessDesign from './components/Automata/AutomataProcessDesign/index.js';
import WorkflowContextProvider from './contexts/workflow/index.js';
import app_name from './constants/name.js';
import APIContextProvider from './contexts/api/index.js';
import ContactContextProvider from './contexts/contact/index.js';
import ContactModal from './components/Modals/ContactModal/contact.js';
import LandingPage from './components/Main/LandingPage/index.js';
import LandingContextProvider from './contexts/landing/index.js';
import PatentProcessContextProvider from './contexts/patent/index.js';
import QuickStartContextProvider from './contexts/quickstart/index.js';
import QuickStartModal from './components/Modals/QuickstartModal/index.js';

const AuthWrapper = (props) => {
    return (
        <div style={{
            position: "absolute",
            display: "flex",
            width: "100vw",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        }}>
            {props.children}

            <div style={{ flex: '0 1 20%' }}> {/* spacer */} </div>
        </div>
    )
}

const AuthReq = (props) => {
    const [user, loading, error] = useAuthState(authFb);
    const navigate = useNavigate();
    const [timerExpired, setTimerExpired] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimerExpired(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (timerExpired && !user) {
            navigate("/");
        }
    }, [timerExpired, user, navigate]);
    if (user) {
        return (<div>{props.children}</div>)
    } else {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </div>
        );
    }
}

function TitleWrap({ title, ...rest }) {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <ClearProvider>
        <PolicyContextProvider>
            <ContactContextProvider>
                <QuickStartContextProvider> 
                <LandingContextProvider>
                    <PatentProcessContextProvider>
                        <FirmSignUpContextProvider>
                            <QuickStartModal />
                            <TermsOfServiceModal />
                            <FirmSignUpModal />
                            <ContactModal />
                            {rest.children}
                        </FirmSignUpContextProvider>
                    </PatentProcessContextProvider>
                </LandingContextProvider>
                </QuickStartContextProvider>
            </ContactContextProvider>
        </PolicyContextProvider>
    </ClearProvider>;
}

const NEWrapper = ({ children }) => {

    return <NodeEdgeProvider>
        {children}
    </NodeEdgeProvider>
}
function App() {
    const location = useLocation();
    const [colorMode, setColorMode] = useState((location.pathname !== "/") && localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light');
    const [userIsLoggedIn, setUserIsLoggedIn] = useState(false)
    const toggleColorMode = () => {
        const newThemeColor = colorMode === "light" ? "dark" : "light"
        axiosWrapper('/ptntst/changeUserTheme', { newTheme: newThemeColor }).then(res => { })
        setColorMode(newThemeColor);
        localStorage.setItem('theme', newThemeColor);
    }

    const themeData = theme(colorMode);

    useEffect(() => {
        if (location.pathname === "/") {
            setColorMode("light");
        } else {
            const fetchUserTheme = async () => {
                try {
                    axiosWrapper('/ptntst/getUserTheme', {}).then(res => {
                        setColorMode(res.data.theme);
                    })
                } catch (error) {
                    console.error("Error fetching user theme:", error);
                }
            };
            if (userIsLoggedIn) {
                fetchUserTheme();
            }
        }
    }, [userIsLoggedIn, location.pathname]);

    useEffect(() => {
        setTimeout(() => {
            if (auth.currentUser) {
                setUserIsLoggedIn(true)
            }
        }, 1200)

    }, [])
    return (
        <ThemeProvider theme={themeData}>
            <CssBaseline />
            {/* <TermsOfServiceModal open={true} /> */}
            <ErrorModal>
                <APIContextProvider>
                    <Routes>
                        <Route path="/" element={
                            <TitleWrap title={app_name + ":  AI-assisted, Human-perfected."}>
                                <HomePageV2
                                    userIsLoggedIn={userIsLoggedIn}
                                />
                                {/* <div>Lex Automata - Coming soon</div> */}
                            </TitleWrap>
                        } />
                        <Route path="/auth" element={
                            <TitleWrap title={app_name + ":  Auth"}>
                                <Auth />
                            </TitleWrap>
                        } />
                        <Route path="/design" element={
                            <TitleWrap title={app_name + ":  design"}>
                                <Design />
                            </TitleWrap>
                        } />
                        <Route path="/step" element={
                            <AuthWrapper>
                                <TitleWrap title={"Patentist:  Steps"}>
                                    <WorkflowContextProvider>
                                        <AutomataProcessDesign />
                                    </WorkflowContextProvider>
                                </TitleWrap>
                            </AuthWrapper>
                        } />
                        <Route path="/auth/login" element={
                            <AuthWrapper>
                                <TitleWrap title={app_name + ":  Login"}>
                                    <Login />
                                </TitleWrap>
                            </AuthWrapper>
                        } />
                        <Route path="/auth/firmStatus" element={
                            <AuthWrapper>
                                <TitleWrap title={app_name + ":  Onboarding"}>
                                    <FirmStatus />
                                </TitleWrap>
                            </AuthWrapper>
                        } />
                        < Route path="/auth/signup" element={
                            < AuthWrapper >
                                <TitleWrap title={app_name + ":  Sign up"}>
                                    <Registration />
                                </TitleWrap>
                            </AuthWrapper >
                        } />
                        < Route path="/game" element={
                            < TitleWrap title={app_name + ":  Game (SECRET!)"}>
                                <Game />
                            </TitleWrap >
                        } />
                        < Route path="/auth/finishSignUp" element={
                            < AuthWrapper >
                                <TitleWrap title={app_name + ":  Finish Onboarding"}>
                                    <EmailVerification />
                                </TitleWrap>
                            </AuthWrapper >
                        } />
                        < Route path="/auth/phone" element={
                            < AuthWrapper >
                                <TitleWrap title={app_name + ":  Phone Verification"}>
                                    <Phone />
                                </TitleWrap>
                            </AuthWrapper >
                        } />
                        < Route path="/auth/passwordreset" element={
                            < AuthWrapper >
                                <TitleWrap title={app_name + ":  Reset Password"}>
                                    <ForgotPassword />
                                </TitleWrap>
                            </AuthWrapper >
                        } />
                        < Route path="/checkout" element={
                            < AuthReq >
                                <TitleWrap title={app_name + ":  Checkout"}>
                                    <CheckoutForm />
                                </TitleWrap>
                            </AuthReq >
                        } />
                        < Route path="/return" element={
                            < AuthReq >
                                <TitleWrap title={app_name + ":  Checkout Confirmation"}>
                                    <CheckoutReturn />
                                </TitleWrap>
                            </AuthReq >
                        } />
                        < Route path="/main" element={
                            < AuthReq >

                                <TitleWrap title={app_name + ":  AI-assisted, Human-perfected."}>
                                    <NEWrapper>
                                        <WorkflowContextProvider>
                                            <LandingPage toggleColorMode={toggleColorMode} />
                                        </WorkflowContextProvider>
                                    </NEWrapper>
                                </TitleWrap>
                            </AuthReq >
                        } />
                    </Routes >
                </APIContextProvider>
            </ErrorModal >
        </ThemeProvider >
    );
}

export default App;
