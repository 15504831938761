import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    ListItemButton,Typography,
    ListItemIcon,useTheme

} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import ArticleIcon from '@mui/icons-material/Article';
import LexButton from "../LexButton";


const useStyles = makeStyles((theme) => ({
    list: {
        maxHeight: '80vh',
        height: "75vh",
        overflow: 'auto',
        overflowX: "hidden",
        backgroundColor: theme.palette.backgroundSecondary.sidebar_one,
    },
    boldText: {
        fontWeight: '500',
        color: 'black',
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const LexStandardList = ({ items, onItemClick, addNewItem, newItemText }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <List className={classes.list} component="nav"
            aria-labelledby="nested-list-subheader"
            style={{ height: 'fit-content', maxHeight: 'none', backgroundColor: 'inherit' }}>
                            <ListItem 
                            style={{backgroundColor:'rgba(37, 161, 176, 0.04)',borderBottom: `1px solid rgba(37, 161, 176, 0.92)`,borderTop: `1px solid rgba(37, 161, 176, 0.92)`}}
                            disablePadding button key={"newButton"} onClick={() => null}>
                <ListItemButton   onClick={addNewItem}>
                    <ListItemIcon sx={{ color: theme.palette.primary.main}}>
                        <AddIcon /></ListItemIcon>
                    <ListItemText>      <Typography className={classes.boldText}>
                            {newItemText}
                        </Typography> </ListItemText>
                </ListItemButton>
          
            </ListItem>
            {items.map((item, index) => (
                <ListItem
                    disablePadding
                    button
                    key={index}
                    onClick={() => onItemClick(item)}>
                    <ListItemButton>
                        <ListItemIcon>
                            <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={item.name}
                            primaryTypographyProps={{
                                style: {
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                }
                            }} />
                    </ListItemButton>
                </ListItem>
            ))}

        </List>
    );
};

export default LexStandardList;