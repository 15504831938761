import React, { useCallback, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { useContextMenu } from "react-contexify";
import Button from "@mui/material/Button";
import { IconButton, Typography } from "@mui/material";
import PowerOffIcon from '@mui/icons-material/PowerOff';
import { Tooltip } from "react-tooltip";

const VariableDisplay = ({ text1, text1Color, text2 }) => {
    return <div style={{ fontSize: "0.7em", display: "flex", width: "100%", flexDirection: "row" }}>
        <div style={{ color: text1Color ? text1Color : "#c591e4" }}>{text1}</div>
        <div style={{
            color: "#a3d3f2",
            marginLeft: 'auto',
            marginRight: 0
        }}>{text2}
        </div>
    </div>
}

const NodeHandle = ({ type, position, id, isConnectable, name }) => {
    const [isHovered, setIsHovered] = useState(false);

    const tooltipStyle = {
        position: 'absolute',
        backgroundColor: '#f5f5f5',
        color: '#333',
        padding: '5px',
        borderRadius: '5px',
        fontSize: '12px',
        zIndex: 300000000000,
    };

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Handle type={type} position={position} id={id} isConnectable={isConnectable} />
            {isHovered && <span style={tooltipStyle}>{name}</span>}
        </div>
    );
};


// const NodeHandle = ({ type, position, id, isConnectable, name }) => (
//     <>
//         <div data-tip={name} data-for={`${id}-tip`}>
//             <Handle type={type} position={position} id={id} isConnectable={isConnectable} />
//         </div>
//         <div style={{ zIndex: 300000000000 }}>      <Tooltip id={`${id}-tip`} effect="solid" />
//         </div>

//     </>
// );

function StandardNode({ id, data, isConnectable }) {

    const { show } = useContextMenu({
        id: "StandardNodeMenu"
    });

    function displayMenu(e) {
        // put whatever custom logic you need
        // you can even decide to not display the Menu
        show({
            event: e,
        });
    }

    const onChange = useCallback((evt) => {
        console.log(evt.target.value);
    }, []);



    return (
        <div style={{
            height: 'fit-content',
            width: '200px',
            border: '1px solid #556369',
            fontSize: ".8em",
            background: data.active === id ? "#302d1a" : "#1a1f22",
            padding: '10px',
            paddingTop: '20px',
            paddingBottom: '10px'
        }}
            onClick={() => {
                data.functions.setSelectedNode(data.index)
            }}
            onContextMenu={displayMenu}>
            <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
            <div>
                <IconButton
                    color="primary"
                    onClick={() => {
                        data.functions.deleteAllConnections(data.index)
                    }}><PowerOffIcon /></IconButton>
                <Typography variant="h5"
                    gutterBottom
                    style={{ width: "60%", marginBottom: "3px" }}>
                    {data?.store?.general?.nodeName}
                </Typography>

                <div style={{
                    borderBottom: '1px solid #eee',
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    marginBottom: "5px",
                    marginTop: "5px",
                    fontSize: ".8em",
                }}>Metadata
                </div>
                < VariableDisplay text1Color={'#9dffbf'} text1={'Node Name'} text2={data.store.general.nodeName} />
                < VariableDisplay text1Color={'#9dffbf'} text1={'GPT Sequences'} text2={data.store.gptPrompts.length} />
                {data.store.userInputs.length > 0 ? <>
                    <div style={{
                        borderTop: '1px solid #eee',
                        borderBottom: '1px solid #eee',
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        fontSize: ".8em",
                        marginBottom: "5px",
                        marginTop: "5px"
                    }}>Input
                        Variables
                    </div>
                    {data.store.userInputs.map((input, index) => <VariableDisplay key={'variablesVarDisplay' + index + "onNode" + id} text1={input.label}
                        text2={input.type} />)}</> : null}
                {data.store.gptPrompts.length > 0 ? <>
                    <div style={{
                        borderTop: '1px solid #eee',
                        borderBottom: '1px solid #eee',
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        marginBottom: "5px",
                        fontSize: ".8em",
                        marginTop: "5px"
                    }}>GPT Output Variables:
                    </div>
                    {data.store.gptPrompts.map((promptDataArray, outerIdx) => <div key={'gptPromptsDataOuterVarDisplay' + outerIdx + "onNode" + id}>
                        {promptDataArray.promptSeq.map((promptData, index) => {
                            if (index === 0) {
                                return < VariableDisplay key={'gptPromptsDataVarDisplay' + index + "onNode" + id} text1={promptData.outputVar}
                                    text2={"string"} />
                            }
                        })}</div>)}</> : null}
                {data.store.gptPrompts.length > 0 ? <>
                    <div style={{
                        borderTop: '1px solid #eee',
                        borderBottom: '1px solid #eee',
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        marginBottom: "5px",
                        fontSize: ".8em",
                        marginTop: "5px"
                    }}>Context IDs
                    </div>
                    {data.store.gptPrompts.map((promptDataArray, outerIdx) => <div key={'gptPromptsVarDisplayOuterDiv' + outerIdx + "onNode" + id}>
                        {promptDataArray.promptSeq.map((promptData, index) => {
                            if (index === 0) {
                                return < VariableDisplay key={'gptPromptsVarDisplay' + index + "onNode" + id} text1Color={'#8b8bff'} text1={promptData.contextVar}
                                    text2={"string"} />
                            }
                        })}</div>)}</> : null}

            </div>
            <NodeHandle type="source" position={Position.Bottom} id="a" isConnectable={isConnectable} name="Handle A" />
            <NodeHandle type="source" position={Position.Right} id="loop" isConnectable={isConnectable} name="Loop" />
        </div>
    );
}

export default StandardNode;
