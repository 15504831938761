import React, { useContext } from 'react';
import DesContext from '../../../../contexts/singleFlow/context.js';
import { CircularProgress } from '@mui/material';

const ProgressMonitor= () => {
    const { manager, isRunning } = useContext(DesContext);
    return (
    <div>
        {manager.isRunning ? <div style={{
            position: 'absolute',
            backgroundColor: 'rgb(255.0,255.0,255.0,.8)',
            borderBottomRightRadius: '30px',
            border: "1px solid purple",
            top: '0px',
            left: '0px',

        }}>
            <CircularProgress style={{ padding: "10px", zIndex: 2000, color: 'purple', height: "100px", width: "100px" }} />
        </div> : <div style={{
            position: 'absolute',
            bottom: '20px',
            left: '20px'
        }}></div>}
    </div>
    );
};

export default ProgressMonitor;