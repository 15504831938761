import {
    Typography,
    FormControl,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    Alert,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/material';


const FormDataTestCollector = ({ configureStep, stepData, errorDisplay, activeTestProfileId, testState }) => {
    const theme = useTheme();

    return (
        <div style={{}}>

            <div style={{ paddingBottom: "20px" }}>
                <Typography variant="h6">Test Configuration - {activeTestProfileId}</Typography>
            </div>

            <div style={{ paddingBottom: "20px" }}>
                <Typography variant="body1">Please provide some sample inputs for process testing.</Typography>
            </div>

            <TableContainer>

                <Table sx={{
                    borderCollapse: 'separate',
                    width: '100%',
                    maxWidth: '100%',
                    tableLayout: 'fixed',

                    '& .left': {
                        borderRight: '1px solid rgba(224, 224, 224, 1)',
                        borderLeft: '1px solid rgba(224, 224, 224, 1)',

                        flex: "0 0 10em",
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },

                    '& .right': {
                        flex: '1 0 auto',
                        maxWidth: '100%',
                    }
                }} size="small" aria-label="a dense table">

                    <TableHead>
                        <TableRow sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}>
                            <TableCell className={'left'} sx={{
                                borderTop: '1px solid rgba(224, 224, 224, 1)',
                            }} align="left">Variable</TableCell>
                            <TableCell className={'right'} sx={{
                                paddingLeft: '25px'
                            }}>Content</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {stepData.collectedFields.map((fieldData, index) => {
                            return <TableRow sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                overflow: 'hidden',
                            }}>

                                <TableCell className={'left'} align="left" sx={{
                                    display: 'flex',
                                    alignItems: (
                                        fieldData?.label &&
                                        fieldData?.varName &&
                                        (fieldData?.type === "Checkbox" || (fieldData?.type === "Select" && fieldData?.selectOptions !== '')))
                                        ? 'center'
                                        : 'start',
                                }}>
                                    {!fieldData?.varName
                                        ? <Alert severity="error" sx={{ padding: '0px', background: 'none' }}>
                                        </Alert>
                                        : <Typography variant="body1"
                                            sx={{
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                            }}>
                                            {fieldData?.varName}</Typography>}
                                </TableCell>

                                <TableCell className={'right'} sx={{
                                    borderBottom: '0px',
                                    paddingLeft: '25px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    {!fieldData?.varName
                                        ? null
                                        : <div style={{
                                        }}>
                                            <FieldSwitch
                                                fieldData={fieldData}
                                                testState={testState}
                                                configureStep={configureStep} />
                                        </div>}

                                    {!fieldData?.varName && <Alert severity="error"
                                        sx={{ padding: '0px', background: 'none' }}>
                                        {fieldData?.label ? `${fieldData?.label} ` : `Field ${index + 1} `} is missing a variable name and cannot be rendered.
                                    </Alert>}
                                    {fieldData?.type === "text" && <Alert severity="warning"
                                        sx={{ padding: '0px', background: 'none' }}>
                                        {fieldData?.label ? `${fieldData?.label} ` : `Field ${index + 1} `} is missing an input type.
                                    </Alert>}
                                    {fieldData.type === "Select" && fieldData.selectOptions === '' && <Alert severity="warning"
                                        sx={{ padding: '0px', background: 'none' }}>
                                        {fieldData?.label ? `${fieldData?.label} ` : `Field ${index + 1} `} has no selection options.
                                    </Alert>}
                                    {!fieldData?.label && <Alert severity="warning"
                                        sx={{ padding: '0px', background: 'none' }}>
                                        {fieldData?.label ? `${fieldData?.label} ` : `Field ${index + 1} `} is missing a label.
                                    </Alert>}

                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

        </div >)
};

const FieldSwitch = ({ fieldData, testState, configureStep }) => {
    switch (fieldData.type) {
        case "Select":
            return <FormControl variant="filled" style={{
                flex: '1 0 100%',
                width: '100%'
            }}>
                <InputLabel shrink={true} id="demo-simple-select-filled-label" sx={{ width: '100%' }}>{fieldData?.label}</InputLabel>
                <Select
                    id="demo-simple-select-filled"
                    InputLabelProps={{ shrink: true }}
                    value={testState?.[fieldData.varName]}
                    onChange={(e) => {
                        configureStep.setTest({
                            [fieldData.varName]: e.target.value,
                        })
                    }}
                    displayEmpty
                    labelId="demo-simple-select-filled-label"
                    slotProps={{
                        inputLabel: {
                            shrink: true,
                        },
                    }}>
                    <MenuItem value="" disabled>
                        Select sample input
                    </MenuItem>
                    {fieldData.selectOptions.split(",").map((selStr) => (
                        <MenuItem key={selStr} value={selStr}>
                            {selStr}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        case "Checkbox":
            return <FormControlLabel
                label={fieldData?.label ? fieldData.label : "Sample Input"}
                control={<div style={{ width: 'fit-content' }}><Checkbox
                    checked={testState?.[fieldData.varName]}
                    onChange={(e) => {
                        configureStep.setTest({
                            [fieldData.varName]: e.target.checked,
                        })
                    }}
                    inputProps={{ 'aria-label': 'controlled' }} />
                </div>
                }
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 'calc(100% - 8em)'
                }}
                sx={{
                    '& > .MuiTypography-root': {

                        width: 'calc(100% - 1em)',
                        overflow: 'hidden',
                        textOverflow: "ellipsis",
                        whiteSpace: "break-spaces",
                    }
                }} />
        case "Text Input":
            return <TextField
                id="filled-basic"
                label={fieldData?.label ? fieldData.label : "Sample Input"}
                rows={6}
                multiline
                value={testState?.[fieldData.varName] ? testState?.[fieldData.varName] : ''}
                onChange={(e) => {
                    configureStep.setTest({
                        [fieldData.varName]: e.target.value,
                    })
                }}
                variant="filled"
                helperText=""
                fullWidth
            />
    }
}

export default FormDataTestCollector;