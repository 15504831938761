import React, { useContext,  useState } from 'react';
import ReactFlow, {
    useOnSelectionChange, Background
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomEdge from './customEdge';
import FigureNode from './nodes';
import NodeEdgeContext from '../contexts/figureDesign/context';
import { useTheme } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ScreenLockLandscapeIcon from '@mui/icons-material/ScreenLockLandscape';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import DeleteIcon from '@mui/icons-material/Delete';
import Confirmation from './confirmation';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';

// import { getBezierPath, getMarkerEnd, getEdgeCenter } from 'reactflow';

// const CustomEdge = ({
//   id,
//   sourceX,
//   sourceY,
//   targetX,
//   targetY,
//   sourcePosition,
//   targetPosition,
//   style = {},
//   markerEnd,
// }) => {
//   // Calculate the middle points for the 3 segments
//   const middleX = (sourceX + targetX) / 2;
//   const firstSegmentEndX = (sourceX + middleX) / 2;
//   const secondSegmentStartX = (middleX + targetX) / 2;

//   const edgePath = `M${sourceX},${sourceY} L${firstSegmentEndX},${sourceY} L${middleX},${targetY} L${secondSegmentStartX},${targetY} L${targetX},${targetY}`;

//   const markerEndId = getMarkerEnd(markerEnd, 'arrowclosed');

//   return (
//     <>
//       <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEndId} />
//       {/* You can add text or other elements to the edge here */}
//     </>
//   );
// };


const cap = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const nodeTypes = { figureNode: FigureNode };
const edgeTypes = { custom: CustomEdge };

const SelectionListener = ({
    selectedNode, selectedEdge,
    setSelectedEdge, setSelectedNode,
    setValue, toggleables }) => {
    // Just to clear the selected edge when nothing is selected.
    useOnSelectionChange({
        onChange: ({ nodes, edges }) => {
            if (selectedNode && nodes.length === 0 && !toggleables.includes('resize')) {
                setSelectedNode(null)
            }
            if (selectedEdge && edges.length === 0) {
                setSelectedEdge(null)
                setValue(0)
            }
        },
    });
}

export default function Figures() {
    const theme = useTheme();
    const { nodes,
        edges,
        onNodesChange,
        onEdgesChange,
        ver,
        onEdgeClick,
        screenshotMode,
        onNodeDrag,
        handleAddNode,
        selectedNode,
        setSelectedNode,
        selectedEdge,
        setSelectedEdge,
        deleteSelectedNode,
        displayRef,
        toggleables,
        onConnect,
        setValue,
        landscape,
        setLandscape } = useContext(NodeEdgeContext);

    const [showGrid, setShowGrid] = useState(false)

    console.log(edges)
    return (
        <div style={{ position: "relative", width: '100%', height: '100vh', backgroundColor: theme.palette.background.default }}>
            {/* <div style={{ transform: 'scale(.5)' }}> */}
            <div style={{
                display: "flex",
                margin: "40px",
                padding: "10px",
                border: screenshotMode ? "" : "1px solid black",
                borderRadius: "3px",
                aspectRatio: landscape ? 1.8182 / 1 : 1 / 1.4142,
                flexDirection: "row",
                backgroundColor: "white",
                ...landscape ? { width: "90%" } : { height: '90%' }
            }}>
                <div ref={displayRef} id="my-react-flow" style={{ flexGrow: "2" }}>
                    <ReactFlow
                        key={ver}
                        nodes={nodes}
                        edges={edges}
                        panOnScroll={false}
                        autoPanOnNodeDrag={false}
                        nodesDraggable={!toggleables.includes('resize')}
                        panOnDrag={false}
                        zoomOnScroll={false}
                        zoomOnDoubleClick={false}
                        autoPanOnConnect={false}
                        connectionMode={"loose"} // Essential for handles to act as both source and target.
                        onNodeDrag={onNodeDrag}
                        onEdgeClick={onEdgeClick}
                        onMove={(event, aa) => { }}
                        onNodeClick={(e, node) => { if (!toggleables.includes('resize')) setSelectedNode(node.id) }}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        nodeTypes={nodeTypes}
                        // edgeTypes={edgeTypes}
                        proOptions={{ hideAttribution: true }}>
                        <SelectionListener
                            selectedNode={selectedNode}
                            selectedEdge={selectedEdge}
                            toggleables={toggleables}
                            setSelectedEdge={setSelectedEdge}
                            setSelectedNode={setSelectedNode}
                            setValue={setValue} />
                        <svg style={{ width: 0, height: 0, position: 'absolute' }}>
                            <defs>
                                <marker
                                    id="arrow"
                                    markerWidth="40"
                                    markerHeight="40"
                                    refX="8"
                                    refY="3"
                                    orient="auto"
                                    markerUnits="strokeWidth"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M0,0 L0,6 L9,3 z" fill="#999" />
                                </marker>
                            </defs>
                        </svg>
                        {showGrid ? <Background /> : null}
                    </ReactFlow>

                </div>
            </div>

            <div style={{
                position: 'absolute',
                bottom: 0,
                right: 0

            }}>
                {!screenshotMode && (<>
                    <Fab
                        color="secondary"
                        aria-label="toggle"
                        onClick={() => setLandscape(e => !e)}
                        style={{
                            position: 'absolute',
                            bottom: '50px',
                            right: '120px'
                        }}
                    >
                        {landscape ? <ScreenLockPortraitIcon /> : <ScreenLockLandscapeIcon />}
                    </Fab>
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={handleAddNode}
                        style={{
                            position: 'absolute',
                            bottom: '50px',
                            right: '190px'
                        }}
                    >
                        <AddIcon />
                    </Fab>
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={() => { setShowGrid(e => !e) }}
                        style={{
                            position: 'absolute',
                            bottom: '50px',
                            right: '50px'
                        }}
                    >
                        {showGrid ? <GridOffIcon /> : <GridOnIcon />}
                    </Fab>
                    {selectedNode && <Confirmation onClickFn={() => deleteSelectedNode()} text={'Are you sure you wish to delete this node?'}><Fab
                        color="secondary"
                        aria-label="delete"
                        // onClick={() => deleteSelectedNode()}
                        style={{
                            position: 'absolute',
                            bottom: '50px',
                            backgroundColor: "red",
                            right: '260px'
                        }}
                    >
                        <DeleteIcon />
                    </Fab></Confirmation>}
                </>
                )}</div>
        </div >
    );
}