import React, { useContext } from "react";
import { SideCard } from "../../../cards.js";
import LexStandardList from "../../../Lex/LexStandardList/index.js";
import WorkflowContext from "../../../../contexts/workflow/context.js";
import LexExpandableAccordion from "../../../Lex/LexExpandableAccordion/index.js";
import LexTypography from "../../../Lex/LexTypography/index.js";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiagonalSplitter from "../../../Design/DiagonalSplitter/index.js";

const OASidebar = (props) => {
    const { workflows, manager } = useContext(WorkflowContext)
    return (
        <div style={{
            ...props.docstyle,
            display: 'flex',
            flexDirection: 'column',
        }}>
                <LexExpandableAccordion
                    expanded={props.expanded === 'panel3'} onChange={()=>props.handleChange('panel3')}
                    Summary={   
                        ()=>(<AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <DiagonalSplitter width={25} height={40} outer="rgba(0,0,0,.1)" middle="rgba(0,0,0,0)" inner="rgba(0,0,0,.3)"/>
                            <div style={{
                            paddingLeft:"50px",
                            width:"100%"}}>
                            <LexTypography sx={{ fontWeight: 500 }} >Office Actions</LexTypography>
                            </div>
                        </AccordionSummary>)
                    }
                    details={<div style={{padding:"30px",paddingLeft:"50px"}}> <LexTypography sx={{ fontWeight: 500 }}> Coming soon!</LexTypography></div>
                    } />
        </div>
    );
};

export default OASidebar;