// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

export const firebaseConfig = ['production'].includes(process.env.NODE_ENV)
    ? {
        apiKey: "AIzaSyBvOYHhQSETHbJAhjzLHtBE8YRUkWB0Ojw",
        authDomain: "patentist-b1c33.firebaseapp.com",
        projectId: "patentist-b1c33",
        storageBucket: "patentist-b1c33.appspot.com",
        messagingSenderId: "150380520935",
        appId: "1:150380520935:web:8de75a66bd15783a128942",
    } : {
        apiKey: "AIzaSyBu_Fd89KWzM_NIOINZEWnsEHAgVE0t58w",
        authDomain: "patentiststaging.firebaseapp.com",
        projectId: "patentiststaging",
        storageBucket: "patentiststaging.appspot.com",
        messagingSenderId: "63567367581",
        appId: "1:63567367581:web:fd2f7c7ed1b7c272053374"
      }

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const authFb = auth;
export const googleProvider = new GoogleAuthProvider();
