import React, { useState } from 'react';
import { ListItemButton, Typography, Box, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { stepOptionsObject } from './steps';
import { useContext } from 'react';
import DesContext from '../../contexts/singleFlow/context';
import MetadataCollector from '../../components/Automata/MetadataCollector';
import AutomataStepCreation from '../../components/Automata/AutomataStepCreaton';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StepConfiguration = ({ stepData, stepIndex, stepId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(0);
    const [descriptionExpanded, setDescriptionExpanded] = useState(true);
    const { manager } = useContext(DesContext);
    const stepOperations = manager.createStepOperations({ stepData, stepIndex, stepId });
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const stepProfile = stepOptionsObject[stepData.name];
    const options = stepProfile[stepData.display];
    let splitDescription = options.description.split("\n")
    return (<>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: "20px" }}>
            <Tabs style={{ width: "100%" }} value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Step Configuration" {...a11yProps(0)} />
                <Tab label="Metadata" {...a11yProps(1)} />
                {stepProfile.testDataDisplay ? <Tab label="Test Configuration" {...a11yProps(2)} /> : null}
                {stepProfile.outputDisplay ? <Tab label="Test Output" {...a11yProps(2)} /> : null}
                {stepProfile?.optionalOne?.show(stepOperations) ? <Tab label={stepProfile?.optionalOne?.['label']} {...a11yProps(3)} /> : null}
                {/* <Tab label="Test Output" {...a11yProps(3)} /> */}
            </Tabs>
        </Box>
        {value === 3 && stepProfile?.optionalOne?.show(stepOperations)  ? <>{stepProfile.optionalOne .collectorComponent(stepOperations)}</> : null}
        {value === 2 && stepProfile.outputDisplay ? <>{stepProfile.outputDisplay.collectorComponent(stepOperations)}</> : null}
        {value === 2 && stepProfile.testDataDisplay ? <>{stepProfile.testDataDisplay.collectorComponent(stepOperations)}</> : null}
        {value === 1 ? <MetadataCollector
            stepData={stepData}
            stepId={stepId}
            stepIndex={stepIndex}
        /> : null}
        {value !== 0
            ? null
            : <div className="stepConfigContainer"
                style={{
                    '--total-padding': '20px',
                    '--header-padding': '5px',
                }}>
                <Accordion square disableGutters expanded={descriptionExpanded}
                    elevation={0} onChange={(e) => setDescriptionExpanded((pre) => !pre)}>
                    <AccordionSummary
                        expandIcon={descriptionExpanded ? <DoDisturbOnOutlinedIcon fontSize="small" /> : <HelpOutlineOutlinedIcon fontSize="small" />}
                        sx={{
                            minHeight: 'unset', paddingBottom: 'var(--header-padding)',
                            ".MuiAccordionSummary-expandIconWrapper.Mui-expanded, .MuiAccordionSummary-expandIconWrapper":
                                { transform: 'none', '-webkit-transform': 'none' },
                            ".MuiAccordionSummary-expandIconWrapper":
                                { transition: 'none', '-webkit-transition': 'none' }
                        }}>
                        <Typography variant="h6">{options.header}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 'calc(var(--total-padding) - var(--header-padding)) 100px 10px 0px' }}>
                        <div style={{}}>
                            {splitDescription.map((line, index) => {
                                if (line === "") {
                                    return <br />
                                }
                                else {
                                    return <Typography variant="body1">{line}</Typography>
                                }
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>

                {/* TODO: decide on whether to force the text or not
                <div style={{ paddingBottom: "20px" }}>
                    <Typography variant="h6">{options.header}</Typography>
                </div>
                <div style={{ paddingRight: "100px", paddingBottom: "10px" }}>
                    {splitDescription.map((line, index) => {
                        if (line === "") {
                            return <br />
                        }
                        else {
                            return <Typography variant="body1">{line}</Typography>
                        }
                    })}
                </div>
                    */}

                {options?.collectorComponent ? options.collectorComponent(stepOperations) : null}
                {options?.buttonOpts ? options.buttonOpts.map((buttonData, index) => {
                    return <ListItemButton key={index} onClick={() => { buttonData.click(stepOperations) }} disabled={buttonData?.disabled ? buttonData.disabled(stepOperations) : false}>

                        <ListItemAvatar>
                            <Avatar>
                                {buttonData.icon}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={buttonData.label}
                            secondary={buttonData.sublabel}
                        />
                    </ListItemButton>
                }) : null}
            </div >}</>
    );
};

const Step = ({ stepData, stepIndex, stepId }) => {
    return <>
        {stepData.initialized ? <StepConfiguration
            stepData={stepData}
            stepId={stepId}
            stepIndex={stepIndex} /> : <AutomataStepCreation
            stepData={stepData}
            stepId={stepId}
            stepIndex={stepIndex}
        />}
    </>
};

export default Step;