const makePromptDescriptionOfGraph = (nodes, edges, promptData) => {
    let graphDescription = "Below, we provide a textual description of all the nodes appearing in the patent diagram. These nodes appear in a flow chart, and for each node, we provide a list of the other nodes it outputs to. Here is the list of nodes : \n\n";
    graphDescription += "--Figure " + promptData.figureNumber + " --\n\n";
    nodes.forEach(node => {
        //graphDescription += "Node Name: " + node.data.label + "\n" + "Description: " + (node.data.description ? node.data.description : "None provided.") + "\n";
        //graphDescription += "Node Name: " + node.data.label + "\n";
        if (!!node.data.groupNode) {
            graphDescription += "(The following node is a group, and it may represent a box contaning other nodes)\n "
        }
        if (node?.data?.groupId && node?.data?.groupId !== "") {
            let groupNode = nodes.filter((e) => e.id === node.data.groupId)[0]
            graphDescription += "(The following node belongs to the group/box labeled " + groupNode.data.label + ")\n";
        }
        if (node.data.useComponentNumber && node.data.componentNumber) {
            graphDescription += "Node name: " + node.data.label + "\nReference Sign/Component Number: " + node.data.componentNumber + "\n";
            graphDescription += node.data.description ? ("Description: " + (node.data.description ? node.data.description : "None provided.") + "\n") : "";
        } else {
            graphDescription += "Node name: " + node.data.label + "\n";
            graphDescription += node.data.description ? ("Description: " + (node.data.description ? node.data.description : "None provided.") + "\n") : "";
        }
        let hasInput = false;
        let hasOutput = false;
        let hasRelations = false;
        let inputs = [];
        let outputs = [];
        let relations = [];
        edges.forEach((edge) => {
            if (!!!edge.directionless) {
                if (edge.source === node.id || !!edge.bothWays) {
                    hasOutput = true;
                    outputs.push([edge.target,edge.label])
                }
                if (edge.target == node.id || !!edge.bothWays) {
                    inputs.push([edge.source,edge.label])
                    hasInput = true;
                }
            } else {
                if (edge.source === node.id) {
                    relations.push([edge.target,edge.label])
                    hasRelations = true;
                }
                if (edge.target == node.id) {
                    relations.push([edge.source,edge.label])
                    hasRelations = true;
                }
            }
        })

        if (hasInput) {
            graphDescription += "Input: "
            inputs.forEach((input) => {
                let input_label = nodes.find((e) => e.id === input[0]).data.label
                let edgeLabel = ""
                if(input[1] && input[1] !== ""){
                    edgeLabel = " (Edge Label: " + input[1] + ")"
                }
                graphDescription += "" + input_label +edgeLabel+ ""
                graphDescription += ", "
            })
            graphDescription = graphDescription.substring(0, graphDescription.length - 2)
            graphDescription += '\n';
        } else {
            graphDescription += "Input: None\n";
        }
        if (hasOutput) {
            graphDescription += "Output: "
            outputs.forEach((input) => {
                let output_label = nodes.find((e) => e.id === input[0]).data.label
                let edgeLabel = ""
                if(input[1] && input[1] !== ""){
                    edgeLabel = " (Edge Label: " + input[1] + ")"
                }
                graphDescription += "" + output_label +edgeLabel+ ""
                graphDescription += ", "
            })
            graphDescription = graphDescription.substring(0, graphDescription.length - 2)
            graphDescription += '\n';
        }
        else {
            graphDescription += "Output: None\n";
        }
        if (hasRelations) {
            graphDescription += "Bidrectionally Related To: "
            relations.forEach((input) => {
                let relation_label = nodes.find((e) => e.id === input[0]).data.label
                let edgeLabel = ""
                if(input[1] && input[1] !== ""){
                    edgeLabel = " (Edge Label: " + input[1] + ")"
                }
                graphDescription += "" + relation_label +edgeLabel+ ""
                graphDescription += ", "
            })
            graphDescription = graphDescription.substring(0, graphDescription.length - 2)
            graphDescription += '\n';
        }
        else {
            graphDescription += "Bidrectionally Related To: None\n";
        }


        graphDescription += "\n";
    });
    return graphDescription;

}

export default makePromptDescriptionOfGraph