import { createTheme } from '@mui/material';
// import darkScrollbar from '@mui/material/darkScrollbar';

const scrollbar = (theme) => ({
    "scrollbarColor": "#6b6b6b #2b2b2b",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        "backgroundColor": "rgba(255,255,255,0)"
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        "borderRadius": 8,
        "backgroundColor": "#6b6b6b",
        "minHeight": 24,
        // "border": "3px solid #2b2b2b"
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        "backgroundColor": "#959595"
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
        "backgroundColor": "#959595"
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        "backgroundColor": "#959595"
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        "backgroundColor": "#2b2b2b"
    }
})

const scrollbarLight = (theme) => ({
    "scrollbarColor": "#9BCBD1",
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        "backgroundColor": "rgba(255,255,255,0)"
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        "borderRadius": 8,
        "backgroundColor": "#9BCBD1",
        "minHeight": 24,
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
        "backgroundColor": "#76A0A6",
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
        "backgroundColor": "#76A0A6",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
        "backgroundColor": "#76A0A6",
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
        "backgroundColor": "#204146"
    }
})

const palettes = {
    light: {
        mode: 'light',

        background: {
            default: "#F6F7F5", // "#F3F7F3",
            paper: /*"#E8FCF4",*/ "#fdfdfd",
        },

        bg: { main: "#F6F7F5" /* "#F3F7F3" */, paper: /* "#E8FCF4" */ "#E5F8F8" },

        // our navy: #17235F

        primary: {

            main: '#25A1B0',
            light: '#C680B0',
            dark: '#2D1425',
            contrastText: '#FFFFFF',
            accents: '#0E1B1B',
            children: '#0E1B1B',
            childText: '#FFFFFF',
        },

        primary_old: {
            main: '#6b9e9e',
            light: '#9BCBD1',
            //light: '#c7e4e8',
            dark: '#517B7B',
            contrastText: '#FFFFFF',
            accents: '#0E1B1B',
            children: '#0E1B1B',
            childText: '#FFFFFF',
        },

        secondary: {
            main: "#81A880",
            light: "#a8cfa2",
            dark: "#4e7d54",
            accents: "#101810",
            contrastText: "#F3F7F3",
            childText: "#101810",
            children: "#FEF9EB",
        },

        field: {
            main: "#F3F7F3",
            light: "#EBF2F2",
            dark: "#6B9E9E",
            accents: "#283E3E",
            contrastText: "#EBF2F2",
            childText: "#283E3E",
            children: "#EBF2F2",
        },


        tertiary: {
            main: "#F9CF59",
            light: "#fde49f",
            dark: "#b78e2e",
            contrastText: "#271D02",
            accents: "#FEF9EB",
            childText: "#271D02",
            children: "#FEF9EB",
        },

        complement: {
            main: "#FF5959",
            light: "#ff8c8c",
            dark: "#c92c2c",
            contrastText: "#FFEBEB",
            accents: "#290000",
            childText: "#290000",
            children: "#FFEBEB",
        },

        backgroundSecondary:
        {
            default: "#1d1d1d",//"#233041",
            flair: "#a25a8a",//"#233041",
            paper: '#25A1B0',
            sidebar_one: "#EEFCF6",
            sidebar_two: "#EEFCF6",

            contrastText: '#F2F4F8',
        },
        warning: {
            contrastText: "#fff",
            dark: "#e65100",
            light: "#ff9800",
            main: "#d7d75b",
        }
    },

    light_old: {
        mode: 'light',
        backgroundSecondary:
        {
            default: "#9ecdc3",
            paper: "#00FF00",
            sidebar_one: "#caddda",
            sidebar_two: "#caddda",
        }
    },

    dark: {
        mode: 'dark',
        backgroundSecondary: {
            // default: "#282d35",
            default: "#282d35",
            paper: "#282d35",
            sidebar_one: "#212125",
            sidebar_two: "#212125",

            item_one: "#F9CF59",
            item_two: "#81A880",
            item_three: "#FF5959",

            contrastText: '#F2F4F8',
        }
    },
}

export const theme = (mode) => {
    let palette = {}
    if (mode === 'dark') {
        palette = palettes.dark
    } else {
        palette = palettes.light
    }
    const themeData = createTheme({
        palette: palette,
        components: {
            MuiCssBaseline: {
                styleOverrides: (themeParam) => {
                    return {
                        body: themeParam.palette.mode === 'dark' ? scrollbar(themeParam) : scrollbarLight(themeParam),
                    }
                },
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => {
                        return {
                            borderRadius: "200px"
                        }
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => {
                        return {
                            padding: '0px',
                            paddingRight: '10px',
                            margin: '0px',
                        }
                    },
                    "expanded": ({ ownerState, theme }) => {
                        return {
                            padding: '0px',
                            paddingRight: '10px',
                            margin: '0px',
                            // borderRadius:'5px',
                        }
                    },
                    "content": ({ ownerState, theme }) => {
                        return {
                            padding: '0px',
                            paddingRight: '10px',
                            margin: '0px',
                            borderRadius: '5px',

                            "&.Mui-expanded": {
                                margin: '0px',
                                padding: '0px',
                                borderRadius: '5px',
                            },
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis',
                            // display: '-webkit-box',
                            // WebkitLineClamp: '1',
                            // WebkitBoxOrient: 'vertical',
                        }
                    }
                }
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => {
                        return {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                        }
                    }
                }
            },

            MuiFilledInput: {
                styleOverrides: {
                    root: ({ ownerState, theme }) => {
                        return {
                            "backgroundColor": "inherit"
                        }
                    },
                    formInput: ({ ownerState, theme }) => {
                        return {
                            "backgroundColor": "blue"
                        }
                    }
                },
            },
        },
    })
    return themeData
};
