
import LexTextField from '../../Lex/LexTextField';
import LexTypography from '../../Lex/LexTypography';
import { InputAdornment, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useState, useRef, useEffect, useMemo } from 'react';
import HighlightWithinTextarea from 'react-highlight-within-textarea';
import React, { useCallback } from 'react';
import { TextField, styled, GlobalStyles } from '@mui/material';
import { throttle, debounce } from 'lodash';
import LexHighlightTextField from '../../Lex/LexHighlightTextField';

const outerStyle = {
    // marginTop: "5px", 
    // backgroundColor: "rgb(0,0,0,.1)", 
    borderRadius: "8px",
    // paddingTop: "15px", 
    paddingBottom: "15px",
    // paddingLeft: "15px" 
}

const sampleInputField = {
    multiline: true,
    rows: 6,
    inputLabel: "Sample Input",
    value: "",
    onChange: (e) => { },
    helperText: ""
}

const Collector = ({ titleText, descriptionText, inputFields, checkboxFields = [] }) => {
    return <div style={outerStyle}>
        {titleText ? <div style={{ paddingBottom: "20px" }}>
            <LexTypography variant="h6">{titleText}</LexTypography>
        </div> : null}
        {descriptionText ? <div style={{ paddingBottom: "20px" }}>
            <LexTypography variant="body1">{descriptionText}</LexTypography>
        </div> : null}

        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }}>
            <div>
                {inputFields.map(inputField => {
                    if (inputField.useHighlights) {
                        return <div><LexHighlightTextField
                            id="filled-basic"
                            variabes={{ 'file': 'file' }}
                            multiline={inputField.multiline}
                            rows={inputField.rows}
                            keysObject={inputField.keyObject}
                            label={inputField.inputLabel}
                            value={inputField.value}
                            onChange={inputField.onChange}
                            variant="filled"
                            InputProps={
                                (inputField.targetName === "varName"
                                    || inputField.targetName === "fileVarName")
                                    ? { startAdornment: <InputAdornment position="start" sx={{ marginRight: '0' }}>$</InputAdornment> }
                                    : {}}
                            helperText={inputField.helperText}
                        /> </div>
                    } else {
                        return < div > <TextField
                            id="filled-basic"
                            variabes={{ 'file': 'file' }}
                            multiline={inputField.multiline}
                            rows={inputField.rows}
                            keysObject={{ 'file': 'test' }}
                            label={inputField.inputLabel}
                            value={inputField.value}
                            onChange={inputField.onChange}
                            variant="filled"
                            InputProps={
                                (inputField.targetName === "varName"
                                    || inputField.targetName === "fileVarName")
                                    ? { startAdornment: <InputAdornment position="start" sx={{ marginRight: '0' }}>$</InputAdornment> }
                                    : {}}
                            helperText={inputField.helperText}
                        /> </div>
                    }
        })
        }
        </div>
        <div>
            <FormGroup>
                {checkboxFields.map(checkboxField => {
                    return <FormControlLabel
                        control={
                            <Checkbox checked={checkboxField.value} onChange={checkboxField.onChange} name={checkboxField.label} />
                        }
                        label={checkboxField.label}
                    />
                })
                }
            </FormGroup>
        </div>
    </div>

    </div >
};

export default Collector;