import React, {useContext } from "react";
import WorkflowContext from "../../../../contexts/workflow/context";
import DesContext from "../../../../contexts/singleFlow/context";
import LexButton from "../../../Lex/LexButton";
import SidebarInternalWrapper from "../SidebarInternalWrapper";
import LexTextField from "../../../Lex/LexTextField";
import LexSimpleSelect from "../../../Lex/LexSimpleSelect";

const Settings = (props) => {
    const { manager, activeWorkflowName } = useContext(WorkflowContext);
    const desCtx = useContext(DesContext);
    const desManager = desCtx.manager;
    return (
        <SidebarInternalWrapper>
        <LexTextField
            id="workflowName"
            label="Workflow Name"
            name="workflowName"
            InputLabelProps={{ shrink: true }}
            style={{ width: 'calc(100% - 0.3cm)' }}
            value={activeWorkflowName ? activeWorkflowName : ""}
            onChange={(e) => { manager.setActiveWorkflowName(e.target.value) }} />
            <div>
                <LexSimpleSelect options={desManager.LLMOptions} 
                handleChange={desManager.handleLLMChange} 
                value={desManager.currentLLM} 
                title="Select LLM" />
            </div>
        <div>
            <LexButton onClick={desManager.handleFileLoad}>Load Workflow</LexButton>
        </div>
        <div>
            <LexButton onClick={desManager.exportWorkflow}>Export Workflow</LexButton>
        </div>
        <div>
            <LexButton onClick={manager.deleteWorkflow}>Delete Workflow</LexButton>
        </div>
        <div>
            <LexButton onClick={()=>{desManager.setPreviewMode(v=>!v)}}>Enable Preview Mode (Beta)</LexButton>
        </div>
        <div>
            {/* <LexButton onClick={manager.experiment}>Error Test</LexButton> */}
        </div>
    </SidebarInternalWrapper>

    )
}

export default Settings;