import React, { useState, useEffect, useContext } from 'react';

import DesContext from './context.js';
import WorkflowContext from '../workflow/context.js';
import APIContext from '../api/context.js';
import { useFileHandlers } from './hooks.js';
import { useMockDialog } from '../api/hooks.js';

function convertMacrosToObject(macrosArray) {
    return macrosArray.reduce((acc, macro) => {
        if (macro.name && macro.value) {  // Only include entries with both name and value
            acc[macro.name] = macro.value;
        }
        return acc;
    }, {});
}

const DesContextProvider = ({ children }) => {
    const { api, apiWithLoading } = useContext(APIContext)
    const { activeWorkflow, manager } = useContext(WorkflowContext);
    const [steps, setSteps] = useState([]);
    const [stepData, setStepData] = useState({});
    const [isRunning, setIsRunning] = useState(false)
    const [previewMode, setPreviewMode] = useState(false);
    const [previewModeInit, setPreviewModenit] = useState(false);
    const [previewModeActiveStep, setPreviewModeActiveStep] = useState("");
    const [evaluatonSteps, setEvaluatonSteps] = useState([]);
    const [stepEvaluationData, setStepEvaluationData] = useState({});
    const [testProfiles, setTestProfiles] = useState({});
    const [activeTestProfile, setActiveTestProfile] = useState([]);
    const [activeTestProfileMeta, setActiveTestProfileMeta] = useState({});
    const [activeTestProfileId, setActiveTestProfileId] = useState("Default Profile");
    const [flowName, setFlowName] = useState("");
    const [workflowVersion, setWorkflowVersion] = useState(1)
    const [staticFiles, setStaticFiles] = useState([])
    const [saveWorkflowVer, setSaveWorkflowVer] = useState(1);
    const allStepsData = stepData
    const [loadRefreshWorkflow, setRefreshWorkflow] = useState(1);
    const [model, setModel] = useState("GPT-4o");
    const [remoteMacros, setRemoteMacros] = useState([]);
    const macrosObject = convertMacrosToObject(remoteMacros);
    useEffect(() => {
        if (previewMode) {
            setPreviewModenit(true)
        }
    },
        [previewMode])

    useEffect(() => {
        if (previewModeInit) {
            setPreviewModenit(false)
            if (steps.length > 0) {
                setPreviewModeActiveStep(steps[0])
            }
        }
    },
        [previewModeInit, steps])


    const importWorkflow = (flow) => {
        let flowMetaData = flow.metadata
        setFlowName(flow.name)
        manager.setActiveWorkflowName(flow.name)
        setSteps(flowMetaData?.steps ? flowMetaData.steps : [])
        setStepData(flowMetaData?.stepData ? flowMetaData.stepData : {})
        setTestProfiles(flowMetaData?.testProfiles ? flowMetaData.testProfiles : {})
        setActiveTestProfile(flowMetaData?.activeTestProfile ? flowMetaData.activeTestProfile : [])
        setActiveTestProfileMeta(flowMetaData?.activeTestProfileMeta ? flowMetaData.activeTestProfileMeta : {})
        setActiveTestProfileId(flowMetaData?.activeTestProfileId ? flowMetaData.activeTestProfileId : "Default Profile")
        setWorkflowVersion(flowMetaData?.workflowVersion ? flowMetaData.workflowVersion : 1)
        setModel(flowMetaData?.model ? flowMetaData.model : 'GPT-4o')
        setTimeout(() => {
            setSaveWorkflowVer(v => v + 1)
        }, 1000)
    }

    const { handleFileLoad, downloadObjectAsJson } = useFileHandlers(importWorkflow);

    const saveWorkflow = () => setSaveWorkflowVer(old => old + 1)
    useEffect(() => {
        if (activeWorkflow !== null) {
            manager.updateWorkflow(steps, stepData, testProfiles, activeTestProfileId, activeTestProfile, activeTestProfileMeta, workflowVersion, model)
        }
    }, [saveWorkflowVer])

    const exportWorkflow = () => {
        api('/ptntst/getWorkflow', { flowId: activeWorkflow }).then(res => {
            downloadObjectAsJson({ flow: res.data.flow }, `${res.data.flow.name}.json`)
        })
    }
    const updateMacrosRemote = (macros) => {
        setRemoteMacros(macros)
        api('/ptntst/updateMacros', { macros: macros }).then(res => {})
    }

    const getMacrosRemote = (setMacros) => {
        api('/ptntst/getMacros', {}).then(res => {
            setRemoteMacros(res.data.macros)
            setMacros(res.data.macros)
        })
    }
    useEffect(() => {
        getMacrosRemote(()=>{})
    },[])
    useEffect(() => {
        setPreviewMode(false)
    }, [activeWorkflow])

    useEffect(() => {
        if (activeWorkflow !== null) {
            api('/ptntst/getWorkflow', { flowId: activeWorkflow }).then(res => {
                let flowMetaData = res.data.flow.metadata
                setFlowName(res.data.flow.name)
                manager.setActiveWorkflowName(res.data.flow.name)
                setSteps(flowMetaData?.steps ? flowMetaData.steps : [])
                setStepData(flowMetaData?.stepData ? flowMetaData.stepData : {})
                setTestProfiles(flowMetaData?.testProfiles ? flowMetaData.testProfiles : {})
                setActiveTestProfile(flowMetaData?.activeTestProfile ? flowMetaData.activeTestProfile : [])
                setActiveTestProfileMeta(flowMetaData?.activeTestProfileMeta ? flowMetaData.activeTestProfileMeta : {})
                setActiveTestProfileId(flowMetaData?.activeTestProfileId ? flowMetaData.activeTestProfileId : "Default Profile")
                setWorkflowVersion(flowMetaData?.workflowVersion ? flowMetaData.workflowVersion : 1)
                setStaticFiles(flowMetaData?.staticFiles ? flowMetaData.staticFiles : [])
                setModel(flowMetaData?.model ? flowMetaData.model : 'GPT-4o')
            })
        }
    }, [activeWorkflow, loadRefreshWorkflow])


    const saveProfileAndUpdate = () => { }

    const addStep = (index) => {
        let newId = Math.random().toString(36).substring(7);
        setSteps(prevSteps => {
            setStepData(prevData => {
                let newData = { ...prevData };
                newData[newId] = { initialized: false, display: "overview", title: "", files: [] };
                return newData;
            });
            let newSteps = [...prevSteps];
            if (index > prevSteps.length - 1) {
                newSteps = [...newSteps, newId]
            } else {
                newSteps.splice(index, 0, newId);
            }
            return newSteps
        });
        setActiveTestProfile(prevActiveTestProfile => {
            let stepTestData = { stepId: newId, state: {} }
            let newActiveTestProfile = [...prevActiveTestProfile];
            if (index > prevActiveTestProfile.length - 1) {
                newActiveTestProfile = [...newActiveTestProfile, stepTestData]
            } else {
                newActiveTestProfile.splice(index, 0, stepTestData);
            }
            return newActiveTestProfile
        });
    };

    const removeStep = useMockDialog(
        "Are you sure you wish to delete this step? After you take this action, the step cannot be recovered.",
        'Delete Step',
        (index) => {
            let delKey=steps[index];
            setSteps(prevSteps => {
                let newSteps = [...prevSteps];
                newSteps.splice(index, 1);
                setTimeout(()=>{
                    setStepData(prevData => {
                        let newData = { ...prevData };
                        delete newData[delKey]
                        Object.keys(newData).forEach(key => {
                            if (newData[key]?.  ['useExistingContext'] === true && newData[key]?.['contextToContinue'] === delKey) {
                                // Delete contextToContinue and set useExistingContext to false
                                delete newData[key]['contextToContinue'];
                                newData[key]['useExistingContext'] = false;
                            }
                        });
                        return newData;
                    });
                    setTimeout(()=>{
                        saveWorkflow()},200);
                },200);
                return newSteps;
            });
            setActiveTestProfile(prevActiveTestProfile => {
                let newActiveTestProfile = [...prevActiveTestProfile];
                newActiveTestProfile.splice(index, 1);
                return newActiveTestProfile
            });
        }, 'singleWorkflow', 'deleteStep', true)

    const fastForwardStep = (index) => {
        saveWorkflow()
        // let dummyTestProfile = Object.keys(stepData).map((stepId)=>({ stepId: stepId, state: {} }))
        setIsRunning(true)
        api('/ptntst/testExecution', { steps: steps, stepData: stepData, index: index, stateDataArray: JSON.stringify(activeTestProfile), staticFiles: staticFiles, model: model,macrosObject }).then(res => {
            setIsRunning(false)
            setActiveTestProfile(res.data.history)
            setTimeout(() => {saveWorkflow()},100)
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    const playStep = (index) => {
        saveWorkflow()
        setIsRunning(true)
        api('/ptntst/debugExecution', { stepId:steps[index],steps: steps, stepData: stepData, index: index, stateDataArray: JSON.stringify(activeTestProfile), staticFiles: staticFiles, model: model,macrosObject }).then(res => {
            setIsRunning(false)
            setActiveTestProfile(res.data.history)
            setTimeout(() => {saveWorkflow()},100)
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    const uploadFiles = ({ configureStep, stepData, stepIndex, stepId,mode }) => (acceptedFiles) => {
        acceptedFiles.map((acceptedFile) => {
            const formData = new FormData();
            formData.append('file', acceptedFile);
            formData.append('flowId', activeWorkflow);
            apiWithLoading('/ptntst/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if(mode=="template"){
                    let fileId = 'template'
                    configureStep.set({
                        [fileId]: {
                            blobName: res.data.blobName,
                            origName: res.data.origName,
                            mimeType: res.data.mimeType,
                            flowId: activeWorkflow
                        },
                    })
                }else{
                    let fileId = '__'+stepId+'__uploadFile'
                    configureStep.setTest({
                            [fileId]: {
                                blobName: res.data.blobName,
                                origName: res.data.origName,
                                mimeType: res.data.mimeType,
                                flowId: activeWorkflow
                            },
                        })
                }
                setTimeout(() => {
                    saveWorkflow()
                },100)
                
            })
        })
    }

    const downloadCompletedTemplate = ({stepData, stepIndex, stepId }) => () => {
        apiWithLoading('/ptntst/downloadCompletedTemplate', { flowId:activeWorkflow,stepData, stepIndex, stepId },{ responseType: 'blob', headers: {} }).then(res => {
            // Check if res.data is actually a Blob
            if (!(res.data instanceof Blob)) {
                console.error('Response data is not a Blob:', res.data);
                return;
            }
            // Create file link in browser's memory
            const href = URL.createObjectURL(res.data);
            // Create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'figures.docx'); // or any other extension
            document.body.appendChild(link);
            link.click();

            // Clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
    }

    const uploadStaticFiles = async (acceptedFiles) => {
        for (var i=0; i < acceptedFiles.length; i++) {
            let acceptedFile = acceptedFiles[i];
            let formData = new FormData();
            formData.append('file', acceptedFile);
            formData.append('flowId', activeWorkflow);
            await apiWithLoading('/ptntst/uploadStaticFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setRefreshWorkflow(v => v + 1)

        }
        
    }

    const deleteFile = (mode)=> async ({configureStep, stepData, files, stepIndex, stepId }) => {
        const formData = new FormData();
        if (files.length > 0) {
            formData.append('flowId', activeWorkflow);
            formData.append('fileData', JSON.stringify(files[0]));
        api('/ptntst/deleteFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            console.log('here!')
            if(mode=="template"){
                let fileId = 'template'
                configureStep.deleteSetKey(fileId)
            }else{
                let fileId = '__'+stepId+'__uploadFile'
                configureStep.deleteSetTestKey(fileId)
            }
            setTimeout(() => {
                saveWorkflow()
            },100)
        });
    }
    };

    const deleteStaticFile = async (fileIndex, stepId) => {
        const formData = new FormData();
        const fileData = staticFiles[fileIndex]
        formData.append('flowId', activeWorkflow);
        formData.append('fileData', JSON.stringify(fileData));
        formData.append('fileIndex', fileIndex);
        api('/ptntst/deleteStaticFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setRefreshWorkflow(v => v + 1)
            //delete staticFiles.splice(fileIndex, 1);
            //saveWorkflow()
        });
    };

    const executePreviewSequence = (stepId, noAdvance) => () => {
        let index = steps.indexOf(stepId)
        setIsRunning(true)
        apiWithLoading('/ptntst/debugExecution', { steps: steps, stepData: stepData, index: index, stateDataArray: activeTestProfile, staticFiles: staticFiles }).then(res => {
            if (index + 1 < steps.length && !noAdvance) {
                setPreviewModeActiveStep(steps[index + 1])
            }
            setIsRunning(false)
            setActiveTestProfile(res.data.history)
        }).catch((e) => { })

    }

    const simpleAdvanceSeq = (stepId) => () => {
        let index = steps.indexOf(stepId)
        if (index + 1 < steps.length) {
            setPreviewModeActiveStep(steps[index + 1])
        }
    }

    const fastForwardStepStatus = (index) => { }
    const playStepStatus = (index) => { }
    const validateStepSequence = () => { }
    const createStepOperations = ({ stepData, stepIndex, stepId }) => {
        return {
            stepData,
            stepIndex,
            stepId,
            activeTestProfileId,
            testState: activeTestProfile?.[stepIndex]?.state,
            macrosObject,
            configureStep: {
                set: (data) => {
                    setStepData(prevData => {
                        let newData = { ...prevData };
                        newData[stepId] = { ...newData[stepId], ...data };
                        return newData;
                    });
                },
                deleteSetKey: (key) => {
                    setStepData(prevData => {
                        let newData = { ...prevData };
                        delete newData[stepId][key];
                        return newData;
                    });
                },
                deleteSetTestKey: (key) => {
                    setActiveTestProfile(prevActiveTestProfile => {
                        let newActiveTestProfile = [...prevActiveTestProfile];
                        delete newActiveTestProfile[stepIndex].state[key]
                        return newActiveTestProfile;
                    });
                },
                setTest: (data) => {
                    setActiveTestProfile(prevActiveTestProfile => {
                        let newActiveTestProfile = [...prevActiveTestProfile];
                        newActiveTestProfile[stepIndex].state = { ...newActiveTestProfile[stepIndex].state, ...data };
                        return newActiveTestProfile;
                    });
                },
                priorConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && allStepsData?.[stepWrap[0]]?.name === "generativeStep"
                    })
                    return validPriorConversations;
                },
                priorSeqConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && allStepsData?.[stepWrap[0]]?.name === "generativeSequence"
                    })
                    return validPriorConversations;
                },
                priorSeqStepConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && (allStepsData?.[stepWrap[0]]?.name === "generativeSequence" || allStepsData?.[stepWrap[0]]?.name === "generativeStep")
                    })
                    return validPriorConversations;
                },
                
            },

            errorDisplay: ({ blah }) => {

            }
        }
    }
    const createStepOperationsById = (stepId) => {
        let stepIndex = steps.indexOf(stepId)
        return createStepOperations({ stepData: stepData[stepId], stepIndex, stepId })
    }
    const previewStepIndex = steps.indexOf(previewModeActiveStep)

    const LLMOptions = ["Gemini", "GPT-4o"]
    const handleLLMChange = (e) => {
        setModel(e.target.value)
        setTimeout(() => {
            setSaveWorkflowVer(v => v + 1)
        }, 100)
    }

    return (
        <DesContext.Provider value={{
            manager: {
                uploadFiles, deleteFile, deleteStaticFile,
                steps, stepData, evaluatonSteps, stepEvaluationData, previewMode, previewModeActiveStep,
                addStep, removeStep, fastForwardStep, fastForwardStepStatus, isRunning, downloadCompletedTemplate,
                staticFiles, uploadStaticFiles, LLMOptions, currentLLM:model, handleLLMChange,
                createStepOperations, setPreviewMode, playStep, playStepStatus, exportWorkflow, handleFileLoad,
                createStepOperationsById, executePreviewSequence, simpleAdvanceSeq, previewStepIndex, model,
                updateMacrosRemote, getMacrosRemote, remoteMacros
            }
        }}>
            {children}
        </DesContext.Provider>
    );
}

export default DesContextProvider;