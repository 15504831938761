import React from "react";

// export const SvgLogoComponent = (props) => (
//     <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
//         version="1.1"
//         width="100%"
//         height="100%"
//         viewBox={props.noSpace ? "500 100 280 400" : "0 0 1280 800"}
//         xmlSpace="preserve">
//         <desc>Created with Fabric.js 4.4.0</desc>
//         <defs></defs>
//         <g transform="matrix(1.9211538461538462 0 0 1.9211538461538462 640 300.32193119700145)" id="logo-logo">
//             <g style={{ paintOrder: "stroke" }}>
//                 <g transform="matrix(0.13280491513055043 0 0 -0.13280491513055043 0 0)">
//                     <path style={{
//                         stroke: "none",
//                         strokeWidth: 1,
//                         strokeDasharray: "none",
//                         strokeLinecap: "butt",
//                         strokeDashoffset: 0,
//                         strokeLinejoin: "miter",
//                         strokeMiterlimit: 4,
//                         fill: props.color ? props.color : "rgb(255,255,255)",
//                         fillRule: "nonzero",
//                         opacity: 0
//                     }}
//                         paintOrder="stroke"
//                         transform=" translate(-1632.975, -1309.25)"
//                         d="M 1632.98 2059.25 C 1341.4 2059.25 1104.19 1822.04 1104.19 1530.46 L 1104.19 1243.23 L 1345.75 1243.23 L 1345.75 726.523 L 1696.12 559.25 L 1696.12 1005.4200000000001 C 1958.04 1036.74 2161.7599999999998 1260.26 2161.7599999999998 1530.46 C 2161.7599999999998 1822.04 1924.5499999999997 2059.25 1632.9799999999998 2059.25 z M 1632.98 1127.97 L 1569.83 1127.97 L 1569.83 759.5 L 1472.04 806.184 L 1472.04 1233.27 L 1472.04 1243.23 L 1472.04 1369.52 L 1472.04 1530.46 C 1472.04 1619.23 1544.23 1691.43 1632.98 1691.43 C 1721.71 1691.43 1793.92 1619.23 1793.92 1530.46 C 1793.92 1441.73 1721.71 1369.52 1632.98 1369.52 L 1546.28 1369.52 L 1546.28 1243.23 L 1632.98 1243.23 C 1791.35 1243.23 1920.21 1372.0900000000001 1920.21 1530.46 C 1920.21 1688.8600000000001 1791.35 1817.71 1632.98 1817.71 C 1474.6 1817.71 1345.75 1688.8600000000001 1345.75 1530.46 L 1345.75 1369.52 L 1230.48 1369.52 L 1230.48 1530.46 C 1230.48 1752.41 1411.03 1932.96 1632.98 1932.96 C 1854.92 1932.96 2035.47 1752.41 2035.47 1530.46 C 2035.47 1308.52 1854.92 1127.97 1632.98 1127.97"
//                         strokeLinecap="round" />
//                 </g>
//             </g>
//         </g>
//     </svg>
// );

export const SvgLogoComponent = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="71.65000915527344 55 431.6999816894531 463.9400005340576"
        width="100%"
        height="100%"
    >
        <rect height="100%" width="100%" x="71.65000915527344" y="55" fill="rgba(255, 255, 255, 0.0)" />
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlnsSerif="http://www.serif.com/"
            viewBox="401.427001953125 401.427001953125 1197.1429443359375 1197.1429443359375"
            version="1.1"
            xmlSpace="preserve"
            height="230"
            width="230"
            y="125"
            x="172.5"
            preserveAspectRatio="xMinYMin"
            fill-rule="evenodd"
            clip-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            style={{ overflow: "visible" }}>
            <path
                d="M1135.64,1433.43L1218.21,1598.57L401.427,1598.57L1000,401.427L1102.86,607.148L689.721,1433.43L1135.64,1433.43Z"
                style={{ "fill": "#000000", fillOpacity: "1" }}>
            </path>
            <path
                d="M1190.27,781.974L1095.26,971.995L1408.55,1598.57L1598.57,1598.57L1190.27,781.974Z"
                style={{ fill: "#25A1B0", fillOpacity: "1" }}>
            </path>
        </svg>
        <svg
            y="432"
            viewBox="0.04000000283122063 3.9800000190734863 291.6999816894531 16.940000534057617"
            x="141.65000915527344"
            height="16.940000534057617"
            width="291.6999816894531"
            style={{ "overflow": "visible" }}>
        </svg>
    </svg>)